import React, { useContext } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { QueryContext } from "../map/QueryContext";
import Collapse from "@material-ui/core/Collapse";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Filter1Icon from "@material-ui/icons/Filter1";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  checkboxLabel: {
    fontSize: "0.75rem",
  },
  colselectColumn2: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  filteredIcon: {
    marginLeft: "1vw",
  },
  selectAllButton: {
    paddingLeft: 15,
    alignItems: "center",
  },
}));

const YearSelection = (props) => {
  const { filters, dispatchType, menuTitle } = props;

  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  const [open, setOpen] = React.useState(false);
  const [filtered, setFiltered] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [value, setValue] = React.useState(query.yearSelected);

  const handleChange = (event) => {
    setValue(event.target.value);

    dispatch({
      type: dispatchType,
      selection: event.target.value,
    });
  };

  return (
    <div>
      <MenuItem onClick={handleClick}>
        <Grid
          container
          alignItems="flex-start"
          className={classes.selectAllButton}
          spacing={1}
        >
          <Grid item xs={3}>
            {menuTitle}: {query.yearSelected}
            {open ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </Grid>
      </MenuItem>
      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <FormGroup className={classes.colselectColumn2}>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {filters.map((col, index) => (
                <FormControlLabel
                  value={col.value}
                  control={<Radio />}
                  label={col.label}
                  key={`check-year-col-fgl-${index}`}
                />
              ))}
            </RadioGroup>
          </FormGroup>
        </Collapse>
      )}
    </div>
  );
};

export default YearSelection;
