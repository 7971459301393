import React, { useState, useEffect, useContext } from "react";
import L from "leaflet";

import { DynamicMapLayer } from "react-esri-leaflet";

import Icon from "@material-ui/core/Icon";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { makeStyles } from "@material-ui/core/styles";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

/**
Layer reference:
Maryland Land Use Land Cover - Land Use Land Cover 2010
https://geodata.md.gov/imap/rest/services/PlanningCadastre/MD_LandUseLandCover/MapServer
Clicking on "Open in ArcGIS Online" (bottom right) will open the ArcGIS resource that has the MapServer link (bottom right)
**/

export default function LandUse2010(props) {
  // const [query, dispatch] = useContext(QueryContext);

  const { layerRef, luDescription } = props;

  if (layerRef.current) {
    // console.log("layerRef.current-check", layerRef.current);
    layerRef.current.bindPopup(function (err, featureCollection, response) {
      // console.log(
      //   " featureCollection.features-check",
      //   featureCollection.features
      // );
      var count = featureCollection.features.length;
      let popupText = `
          <div style="overflow-wrap: break-word;">
            <div style="text-align: center;border-bottom: 2.0px solid black;">
              <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">2010 Land Use</h3>
            </div>
          </div>
          `;
      if (count) {
        popupText = `
          <div style="overflow-wrap: break-word;">
            <div style="text-align: center;border-bottom: 2.0px solid black;">
              <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">2010 Land Use</h3>
            </div>
            <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Land Use:</strong> ${featureCollection.features[0].properties["Land Use Description"]}</p>
          </div>
          `;
      }

      return count ? popupText : false;
    });

    // layerRef.current.metadata(function (error, metadata) {
    //   console.log("metadata-check", metadata);
    // });

    // console.log("opacity-check", layerRef.current.getDynamicLayers());
  }

  function luFilter(filter) {
    if (filter) return "Description = '" + luDescription + "'";
    return "LU_CODE >= 0";
  }

  return (
    <DynamicMapLayer
      ref={layerRef}
      // url="https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_WUI_2010_01/MapServer"
      url="https://geodata.md.gov/imap/rest/services/PlanningCadastre/MD_LandUseLandCover/MapServer"
      opacity={0.5}
      layers={[1]}
      // layerDefs={{ 1: "LU_CODE = 13" }}
      // layerDefs={{
      //   1: "Description = '" + luDescription + "'",
      // }}
      // layerDefs={{
      //   1: "Description is not null",
      // }}
      layerDefs={{
        1: luFilter(luDescription),
      }}
      eventHandlers={{
        loading: () => {
          console.log("featurelayer loading");
        },
        load: () => {
          console.log("featurelayer loaded");
          // if (layerRef && layerRef.current) {
          //   layerRef.current.metadata((error, data) => {
          //     console.log("featurelayer metadata:", data);
          //   });
          // }
        },
      }}
    />
  );
}
