import React, { useState, useEffect, useContext } from "react";
import L from "leaflet";

import { DynamicMapLayer } from "react-esri-leaflet";

import Icon from "@material-ui/core/Icon";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { makeStyles } from "@material-ui/core/styles";

/**
Layer reference:
Maryland Coast Smart - Climate Ready Action Boundary (CRAB) Inundated Zones
https://data-maryland.opendata.arcgis.com/maps/maryland::maryland-coast-smart-climate-ready-action-boundary-crab-inundated-zones/about
Clicking on "Open in ArcGIS Online" (bottom right) will open the ArcGIS resource that has the MapServer link (bottom right)
**/

export default function CrabLayer(props) {
  // const [query, dispatch] = useContext(QueryContext);

  const { layerRef } = props;

  return (
    <DynamicMapLayer
      ref={layerRef}
      // url="https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_WUI_2010_01/MapServer"
      url="https://geodata.md.gov/imap/rest/services/Environment/MD_CoastSmartCRAB/MapServer"
      opacity={0.75}
    />
  );
}
