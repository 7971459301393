import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { QueryContext } from "./QueryContext";
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import GeneralFilter from "./GeneralFilter";
import YearSelection from "./YearSelection";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  colselectColumn1: {
    paddingLeft: 5,
    textAlign: "left",
  },
  colselectColumn2: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  customWidth: {
    "& div": {
      width: "21vw",
    },
    height: "70vh",
  },
  filterIcon: {
    background: theme.palette.background.paper,
  },
  selectedFeaturesLabel: {
    paddingLeft: 15,
    fontWeight: 500,
  },
}));

const MapFilter = (props) => {
  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClose}
    >
      <div>
        <IconButton
          color="primary"
          aria-label="filter"
          component="span"
          onMouseEnter={handleClick}
        >
          <FilterListIcon
            color="action"
            fontSize="large"
            className={classes.filterIcon}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.customWidth}
        >
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs>
              <IconButton
                color="secondary"
                aria-label="close filter"
                component="span"
                onClick={handleClose}
                size="small"
                style={{ marginLeft: "18.25vw" }}
              >
                <CloseIcon fontSize="small" size="small" />
              </IconButton>
            </Grid>
            <Grid item xs className={classes.selectedFeaturesLabel}>
              <Typography component="div">
                <Box fontWeight="fontWeightMedium" m={1}>
                  Filtered: {props.featureCount.toLocaleString()} features
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <YearSelection
            filters={props.yearFilters}
            dispatchType={"setYearSelected"}
            menuTitle={"Data Year"}
          />
          {/* {props.layerID === "Top 100 Bottlenecks" && ( */}

          {(query.roadLayerType === "MD100" ||
            query.roadLayerType === "MD100-Directional") && (
            <div>
              <GeneralFilter
                filters={props.top100Filters}
                dispatchType={"setTop100Filters"}
                menuTitle={"Top 100 Bottlenecks Rankings"}
              />
              <GeneralFilter
                filters={props.truckTop100Filters}
                dispatchType={"setTruckTop100Filters"}
                menuTitle={"Truck Top 100 Bottlenecks Rankings"}
              />
            </div>
          )}
          {/* {props.layerID !== "Top 100 Bottlenecks" && ( */}
          {query.roadLayerType !== "MD100" &&
            query.roadLayerType !== "MD100-Directional" && (
              <div>
                <GeneralFilter
                  filters={props.ownershipFilters}
                  dispatchType={"setOwnershipFilters"}
                  menuTitle={"Ownership"}
                />
                <GeneralFilter
                  filters={props.fsystemFilters}
                  dispatchType={"setFsystemFilters"}
                  menuTitle={"Functional System"}
                />
                <GeneralFilter
                  filters={props.urbanCodeFilters}
                  dispatchType={"setUrbanCodeFilters"}
                  menuTitle={"Urban Code"}
                />
                <GeneralFilter
                  filters={props.nhsFilters}
                  dispatchType={"setNhsFilters"}
                  menuTitle={"National Highway System (NHS)"}
                />
                <GeneralFilter
                  filters={props.strahnetFilters}
                  dispatchType={"setStrahnetFilters"}
                  menuTitle={"Strategic Highway Network (STRAHNET)"}
                />
                <GeneralFilter
                  filters={props.truckNetworkFilters}
                  dispatchType={"setTruckNetworkFilters"}
                  menuTitle={"National Truck Network (NN)"}
                />
              </div>
            )}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

MapFilter.propTypes = {
  style: PropTypes.object,
};

export default MapFilter;
