import React, { useState, useEffect, useContext } from "react";
import L from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

// import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { makeStyles } from "@material-ui/core/styles";

// import ConstructionIcon from "@mui/icons-material/Construction";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#FFC300",
    // width: "1.25rem",
    // height: "1.25rem",
    // display: "block",
    left: "-0.25rem",
    top: "-0.25rem",
    position: "relative",
    // borderRadius: "0 0 0",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #000000",
  },
}));

/**
Layer reference:
MDOT SHA Projects
https://data-maryland.opendata.arcgis.com/datasets/maryland::mdot-sha-projects/about
https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_SHA_Projects_Public/FeatureServer
**/

export default function ShaProjectsLayer(props) {
  // const [query, dispatch] = useContext(QueryContext);

  const { layerRef, iconMarkup } = props;

  const classes = useStyles();

  //   const iconMarkup = renderToStaticMarkup(
  //     <span className={classes.icon}>
  //       <ConstructionIcon style={{ fontSize: 20 }} />
  //     </span>
  //   );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
    iconSize: [0, 0],
    iconAnchor: [0, 0],
    // popupAnchor: [-3, -76],
    // shadowUrl: "shadow1.png",
    // shadowSize: [68, 95],
    // shadowAnchor: [22, 94],
    shadowSize: [0, 0],
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
  });

  const options = { day: "numeric", month: "numeric", year: "numeric" };
  function dateToYear(value) {
    if (value) return value.toLocaleString("en-US", options);
    return null;
  }

  const pointToLayer = (feature, latlng) => {
    let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">MDOT SHA Projects</h3>
      </div>
      <p><strong>Project Name:</strong> ${feature.properties.Project_Name}</p>
    </div>
    `;
    let popupText = `
    <div style="overflow-wrap: break-word;">
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">MDOT SHA Projects</h3>
      </div>
      <p><strong>Project Name:</strong> ${feature.properties.Project_Name}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>SHA Project Number:</strong> ${
        feature.properties.SHA_Project_Number
      }</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Location:</strong> ${
        feature.properties.Location
      }</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Road Name:</strong> ${
        feature.properties.Road_Name
      }</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Work Type:</strong> ${
        feature.properties.Work_Type
      }</p>
      
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Estimated Project Start Year:</strong> ${
        feature.properties.Estimated_Project_Start_Year
      }</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Estimated Project Start Year:</strong> ${dateToYear(
        feature.properties.Estimated_Project_Start_Year
      )}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Estimated Project Completion Year :</strong> ${
        feature.properties.Estimated_Project_Completion_Ye
      }</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"> <a href="https://data-maryland.opendata.arcgis.com/datasets/maryland::mdot-sha-projects/about" target="_blank">Layer Information</a></p>

    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      // url="https://services8.arcgis.com/.../2020_Protests_with_Location/FeatureServer/0"
      url="https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_SHA_Projects_Public/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      pointToLayer={pointToLayer}
    />
  );
}
