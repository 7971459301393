/**
 * @fileOverview the main UI component
 */

import React, { useMemo, useState } from "react";

// import AppBar from "@material-ui/core/AppBar";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Stack from "@mui/material/Stack";

import { makeTheme, sidebarStyles } from "./ui/styles";
import {
  ThemeProvider,
  responsiveFontSizes,
  createTheme,
} from "@material-ui/core/styles";
// import {
//   ThemeProvider,
//   responsiveFontSizes,
//   createTheme,
// } from "@mui/material/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./App.scss";

// import { QueryProvider } from "./map/QueryContext";
import { GlobalProvider } from "./common/GlobalContext";

import LeafletMap from "./map/LeafletMap.js";
import LeafletMapState from "./map/LeafletMapState";
import DataTabs from "./table/DataTabs.js";

import { geographic_options } from "./common/constants";

import Sidebar from "./ui/Sidebar";

import MapLayout from "./map/MapLayout";
import Grid from "@mui/material/Grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";

import ShapefileDownloadDialog from "./ui/ShapefileDownloadDialog";
import WhatsNewDialog from "./ui/WhatsNewDialog";
import ContactUsDialog from "./ui/ContactUsDialog";
import DataLayout from "./table/DataLayout";

import Box from "@mui/material/Box";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled, useTheme } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 250;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    marginTop: 50,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 255,
    }),
    overflow: "hidden",
  })
);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  // UI Settings
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // const mode = prefersDarkMode ? "dark" : "light";
  const modePC = prefersDarkMode ? "dark" : "light";
  const [mode, setMode] = useState(modePC);
  useMemo(() => {
    setMode(modePC);
  }, [modePC]);
  // const [mode, setMode] = useState("dark");

  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const [statsLayerParam, setStatsLayer] = useState("Top 100 Bottlenecks");

  // var statsLayerDesc;
  // if (statsLayerParam === "Top 100 Bottlenecks") {
  //   statsLayerDesc = "Maryland Top 100 Bottlenecks";
  // } else if (statsLayerParam === "Statewide") {
  //   statsLayerDesc = "Statewide";
  // } else if (statsLayerParam === "DC Metro Area") {
  //   statsLayerDesc = "DC Metro Area";
  // } else if (statsLayerParam === "Baltimore Area") {
  //   statsLayerDesc = "Baltimore Area";
  // } else if (statsLayerParam === "Western") {
  //   statsLayerDesc = "Western MD";
  // } else if (statsLayerParam === "Central") {
  //   statsLayerDesc = "Central MD";
  // } else if (statsLayerParam === "Southern") {
  //   statsLayerDesc = "Southern MD";
  // } else if (statsLayerParam === "Eastern Shore") {
  //   statsLayerDesc = "Eastern Shore";
  // } else {
  //   statsLayerDesc = statsLayerParam;
  // }

  /* Year of the data for map and summary table */
  // const [year, setYear] = useState(2018);

  /* Set MD region */
  // const [region, setRegion] = useState("dc-metro-area");

  /* Set MD region */
  // const [county, setCounty] = useState("None");

  /* Set route for GeoJSON */
  // const [geoRoute, setGeoRoute] = useState("top100/geojson");

  /* Set data table name, selection for road segment lookup */
  // const [segmentsTable, setSegmentsTable] = useState("roads");
  // const [segmentSelection, setSegmentSelection] = useState("all");

  /* Region boundary geometry in Leaflet's (latitude,longitude) coordinate system */
  // const [bounds] = useState([
  //   [37.99093286, -79.48710834],
  //   [39.72301501, -75.05239796],
  // ]);

  // function RoadSegmentTableSelect(props) {
  //   const layerID = props.layerID;
  //   if (props.layerID === "Statewide") {
  //     return <RoadSegmentTable year={year} layerID={statsLayerParam} />;
  //   }
  // }

  // function MapLayerSelect() {
  //   if (geoRoute === "top100/geojson") {
  //     return (
  //       <LeafletMap
  //         mode={mode}
  //         // bounds={bounds}
  //         // year={year}
  //         // region={region}
  //         // county={county}
  //         // setYear={setYear}
  //         layerID={statsLayerParam}
  //         geoRoute={geoRoute}
  //         // segmentsTable={segmentsTable}
  //         // segmentSelection={segmentSelection}
  //         dataRoute="hpms/data"
  //         Zoom={8}
  //         position={[39.045753, -76.641273]}
  //       />
  //     );
  //   } else {
  //     return (
  //       <LeafletMapState
  //         mode={mode}
  //         // bounds={bounds}
  //         // year={year}
  //         region={region}
  //         county={county}
  //         // setYear={setYear}
  //         layerID={statsLayerParam}
  //         geoRoute={geoRoute}
  //         segmentsTable={segmentsTable}
  //         segmentSelection={segmentSelection}
  //         dataRoute="hpms/data"
  //         Zoom={8}
  //         position={[39.045753, -76.641273]}
  //       />
  //     );
  //   }
  // }

  // const drawer = (
  //   <div className="drawer-wrapper">
  //     <div className={classes.toolbar} />
  //     <Divider />
  //     <div className="current">
  //       <Typography variant="subtitle1">
  //         Current Layer: {statsLayerDesc}
  //       </Typography>
  //     </div>
  //     <Divider />
  //     <List>
  //       {geographic_options.map((item, index) => (
  //         <Link
  //           className={item.active ? "" : "link-disabled"}
  //           key={item.text}
  //           underline="none"
  //           color="inherit"
  //           href="#"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             if (item.active) {
  //               setStatsLayer(item.text);
  //               setRegion(item.text.replace(/\s+/g, "-").toLowerCase());
  //               setGeoRoute(item.geoRoute);
  //               setSegmentsTable(item.segmentsTable);
  //               setSegmentSelection(item.segmentSelection);
  //             }
  //           }}
  //         >
  //           <ListItem button>
  //             <ListItemIcon>
  //               <Icon>{item.icon}</Icon>
  //             </ListItemIcon>
  //             <ListItemText primary={item.text} />
  //           </ListItem>
  //         </Link>
  //       ))}
  //     </List>
  //     <img
  //       className="logo"
  //       src={`logo-${mode}.svg`}
  //       alt="Maryland DOT Logo"
  //       title="Maryland DOT Logo"
  //     />
  //   </div>
  // );

  const [whatsNewOpen, setWhatsNewOpen] = React.useState(false);
  const handleClickWhatsNew = () => {
    setWhatsNewOpen(true);
  };
  const [shapefileDownloadOpen, setShapefileDownloadOpen] =
    React.useState(false);
  const handleClickOpenShapefiles = () => {
    setShapefileDownloadOpen(true);
  };
  const [contactUsOpen, setContactUsOpen] = React.useState(false);
  const handleClickContactUs = () => {
    setContactUsOpen(true);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const [openDrawer, setOpenDrawer] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  document.body.style.overflow = "hidden";

  // const [darkModeSwitch, setDarkModeSwitch] = useState(true);
  // const handleChangeDarkMode = (event) => {
  //   setDarkModeSwitch(event.target.checked);
  //   console.log("event.target-check", event.target);
  //   // if (event.target.checked) setMode("dark");
  //   // else setMode("light");
  // };

  // const [darkModechecked, setDarkModeChecked] = React.useState(true);

  const handleChangeDarkMode = (event) => {
    // setDarkModeChecked(event.target.checked);
    if (event.target.checked) setMode("dark");
    else setMode("light");
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {/* <Box sx={{ display: "flex", overflowY: "hidden" }}> */}
        {whatsNewOpen ? (
          <WhatsNewDialog
            open={whatsNewOpen}
            setOpen={setWhatsNewOpen}
            mode={mode}
          />
        ) : null}
        {shapefileDownloadOpen ? (
          <ShapefileDownloadDialog
            shapefileDownloadOpen={shapefileDownloadOpen}
            setShapefileDownloadOpen={setShapefileDownloadOpen}
          />
        ) : null}
        {contactUsOpen ? (
          <ContactUsDialog
            open={contactUsOpen}
            setOpen={setContactUsOpen}
            mode={mode}
          />
        ) : null}

        <CssBaseline />
        <AppBar
          position="fixed"
          open={openDrawer}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#1E1E1E",
            mb: 0,
            pb: 0,
          }}
          className={classes.appBar}
        >
          <Toolbar sx={{ marginBottom: 0 }} variant="dense">
            {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
            <Grid container spacing={0}>
              {/* <Grid item xs={1}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    // className={classes.menuButton}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon>menu</Icon>
                  </IconButton>
                </Grid> */}
              <Grid item xs={1}>
                <img
                  className="logo"
                  src="logo-light.svg"
                  alt="Maryland DOT Logo"
                  title="Maryland DOT Logo"
                />
              </Grid>
              <Grid item xs={1}>
                <img
                  className="logo-tti"
                  src="TTI-white.svg"
                  alt="TTI Logo"
                  title="TTI Logo"
                />
              </Grid>
              <Grid
                item
                xs={6}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" noWrap>
                  Maryland Roadway Performance Tool
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Show What's New">
                    <Button
                      onClick={() => {
                        handleClickWhatsNew();
                      }}
                      sx={{ fontSize: 12, color: "white" }}
                    >
                      What's New?
                    </Button>
                  </Tooltip>
                  <Tooltip title="User's Guide">
                    <Button
                      // onClick={() => {
                      //   handleClickWhatsNew();
                      // }}
                      sx={{ fontSize: 12, color: "white" }}
                      href={
                        process.env.PUBLIC_URL +
                        "Maryland Roadway Performance Tool - User Guide.pdf"
                      }
                      target="_blank"
                    >
                      User's Guide
                    </Button>
                  </Tooltip>
                  <Tooltip title="Dowload Shapefiles">
                    <Button
                      onClick={() => {
                        handleClickOpenShapefiles();
                      }}
                      sx={{ fontSize: 12, color: "white" }}
                    >
                      Dowload Shapefiles
                    </Button>
                  </Tooltip>
                  <Tooltip title="Contact Us">
                    <Button
                      onClick={() => {
                        handleClickContactUs();
                      }}
                      sx={{ fontSize: 12, color: "white" }}
                    >
                      Contact Us
                    </Button>
                  </Tooltip>
                  <Tooltip title="Open Settings/Filters">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
                    >
                      {/* <MenuIcon /> */}
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <GlobalProvider>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={openDrawer}
          >
            {/* <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader> */}
            {/* <Divider /> */}

            {/* <DrawerHeader
              sx={{
                mt: 0,
                pt: 0,
                mb: 0,
                pb: 0,
              }}
            >
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  marginTop: 0,
                  paddingTop: 0,
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              >
                <Typography
                  noWrap
                  variant="subtitle2"
                  sx={{
                    marginTop: 0,
                    paddingTop: 0,
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                >
                  Hide Settings
                </Typography>
                <CloseIcon
                  sx={{
                    marginBottom: 2,
                    marginLeft: 1,
                    // paddingBottom: 1,
                  }}
                />
              </IconButton>
            </DrawerHeader> */}
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <Box
              sx={{
                marginTop: 0,
                paddingTop: 0,
                marginBottom: 0,
                paddingBottom: 0,
              }}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Stack direction="column" spacing={0}>
                <Tooltip title="Hide Settings">
                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{
                      marginTop: 0,
                      paddingTop: 0,
                      marginBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <Typography
                      noWrap
                      variant="subtitle2"
                      sx={{
                        marginTop: 0,
                        paddingTop: 0,
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      Hide Settings
                    </Typography>
                    <CloseIcon
                      sx={{
                        marginBottom: 0,
                        marginLeft: 1,
                        // paddingBottom: 1,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Toggle Dark Mode">
                  <FormControlLabel
                    control={
                      <Switch
                        // checked={darkModechecked}
                        checked={mode === "dark" ? true : false}
                        onChange={handleChangeDarkMode}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Dark Mode"
                    // setMode(modePC);
                  />
                </Tooltip>
              </Stack>
            </Box>

            <Divider />
            <Sidebar
              // statsLayerDesc={statsLayerParam}
              // setStatsLayer={setStatsLayer}
              // setRegion={setRegion}
              // setCounty={setCounty}
              // geoRoute={geoRoute}
              // setGeoRoute={setGeoRoute}
              // setSegmentsTable={setSegmentsTable}
              // setSegmentSelection={setSegmentSelection}
              mode={mode}
            />
            {/* <Divider /> */}
            {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
          </Drawer>
          <Main
            open={openDrawer}
            sx={{ overflow: "hidden", maxWidth: "100vw", mt: 3 }}
          >
            <Box sx={{ overflow: "hidden", maxWidth: "100vw", mt: 0, pt: 0 }}>
              <MapLayout mode={mode} openDrawer={openDrawer} />
            </Box>
            <Box
              style={{
                maxHeight: "45vh",
                overflow: "auto",
                maxWidth: "100vw",
              }}
            >
              <DataLayout mode={mode} />
            </Box>
          </Main>
        </GlobalProvider>
        {/* </Box> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
