import React, { useState, useContext, useEffect, useMemo } from "react";

import {
  SUMMARY_VARIABLES_HPMS,
  databaseVariablesHPMS,
  SUMMARY_VARIABLES_TOP100,
  SUMMARY_VARIABLES_ROUTE,
  SUMMARY_VARIABLES_ROUTE_100,
  SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL,
} from "../common/constants";
// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import TableColumnSelector from "./TableColumnSelector";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";

import ExportFileNameDialog from "../utils/ExportFileNameDialog";
import SaveAlt from "@material-ui/icons/SaveAlt";

import { getCurrentDate } from "../utils/utils";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { CompareSharp } from "@material-ui/icons";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import RoadSegmentDetailPanel from "./RoadSegmentDetailPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RoadSegmentTable(props) {
  const classes = useStyles();
  const keyField = "label";
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const {
    // roads,
    // setRoads,
    // roadsID,
    // setRoadsID,
    // roadsData,
    // setRoadsData,
    roadsData2,
    setRoadsData2,
    rows,
    setRows,
    mode,
  } = props;

  const updateSelectedRows = (rows) => {
    // dispatch({
    //   type: "setHighlightQuery",
    //   query: isRoadSelected.bind(null, rows),
    // });

    dispatch({
      type: "setUserSelectedRoads",
      selection: rows,
    });
  };

  function getDirection(values, field) {
    let dirValues = [];
    values.map((itm) => dirValues.push(itm[field]));
    return dirValues
      .sort(
        (a, b) =>
          dirValues.filter((v) => v === a).length -
          dirValues.filter((v) => v === b).length
      )
      .pop();
  }

  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer;
    if (query.geoRoute === "hpms/geojson") {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_HPMS;
    } else {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_TOP100;
    }

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (category === SUMMARY_VARIABLES_layer[i].category) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let cols = [];
    let colVars = [];

    if (query.geoRoute === "top100/geojson") {
      setColumns(query.tableColumnsTop100);

      if (query.layerSpecs.layer.split(" ")[0] === "Directional")
        colVars = SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL;
      else colVars = SUMMARY_VARIABLES_ROUTE_100;

      for (var i = colVars.length - 1; i >= 0; i--) {
        cols.push(colVars[i]);
      }

      cols = cols.concat(
        query.tableColumnsTop100.filter((itm) => itm.category !== "route")
      );
    } else {
      colVars = SUMMARY_VARIABLES_ROUTE;

      for (var i = colVars.length - 1; i >= 0; i--) {
        cols.push(colVars[i]);
      }
      cols = cols.concat(
        query.tableColumnsHPMS.filter((itm) => itm.category !== "route")
      );

      // setColumns(query.tableColumnsHPMS)
    }

    setColumns(cols);

    // let columnsTemp = [];
    // if (query.geoRoute === "top100/geojson")
    //   columnsTemp = query.tableColumnsTop100;
    // else columnsTemp = query.tableColumnsHPMS;
    let tableColumnsTemp = [];
    cols.map(
      (headCell) =>
        headCell.checked &&
        tableColumnsTemp.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    // setTableColumns(tableColumnsTemp);
  }, [query.layerSpecs.layer]);

  function checkExists(rows, newRow) {
    for (var i = rows.length - 1; i >= 0; i--) {
      if (rows[i].id === newRow.id) {
        return true;
      }
    }

    return false;
  }

  const sumByName = (data, name) => {
    return data.reduce(function (a, b) {
      return a + b[name];
    }, 0);
  };

  const sumNames = (data, totalNames) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = sumByName(data, name);
    });
    return total;
  };

  const weightedSumByName = (data, name, weightString) => {
    return data.reduce(function (a, b) {
      return a + b[name] * b[weightString];
    }, 0);
  };

  const weightedSumByNames = (data, totalNames, weightString) => {
    let total = {};

    totalNames.forEach((name) => {
      total[name] = weightedSumByName(data, name, weightString);
    });
    return total;
  };

  function buildColsNew(type) {
    let cols = [];

    let columnsTemp = columns;
    if (type === "detailed_panel") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "route_id"
      );
    }

    columnsTemp.map(
      (headCell) =>
        headCell.checked &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) => {
            if (typeof rowData.route_id !== "undefined") {
              rowData.trkvolper = (rowData.trkaadt / rowData.aadt) * 100;
              return rowData[headCell.id].toLocaleString(
                "en-US",
                headCell.formatOptions
              );
            }
          },
        })
    );

    return cols;
  }

  const handleRemoveSelected = (data) => {
    let userSelectedSegmentsTemp = query.userSelectedSegments;
    let roadsDataTemp = roadsData2;

    data.map((itm) => {
      userSelectedSegmentsTemp = userSelectedSegmentsTemp.filter(
        (obj) => obj.mergekey !== itm.mergekey
      );
      roadsDataTemp = roadsDataTemp.filter(
        (obj) => obj.mergekey !== itm.mergekey
      );

      dispatch({
        type: "setUserSelectedSegments",
        selection: userSelectedSegmentsTemp,
      });

      dispatch({
        type: "setUserSelectedRoads",
        selection: [],
      });
    });
    setRoadsData2(roadsDataTemp);
  };

  const handleClick = (data) => {
    if (data.length > 0) {
      if (query.geoRoute === "top100/geojson") {
        let newSelecteds = data.map((n) => n.mergekey);

        updateSelectedRows(newSelecteds);
      } else {
        let segmentIds = [];

        data.map((n) => {
          rows
            .filter((match) => match.mergekey === n.mergekey)
            // .map((r) => segmentIds.push(r.mergekey));
            .map((r) =>
              segmentIds.push(
                r.route_id + "_" + r.beg_point + "_" + r.end_point
              )
            );
        });

        updateSelectedRows(segmentIds);
      }

      return;
    }

    updateSelectedRows([]);
  };

  function buildDirectionalColumns() {
    let tempColumns = buildColsNew("full_table");
    let dirColumns = [];

    tempColumns.map((obj) => {
      dirColumns.push(obj);
      if (obj.id === "end_point")
        dirColumns.push({
          id: "direction",
          numeric: false,
          disablePadding: false,
          label: "Direction",
          checked: true,
          category: "route",
          tooltip: (
            <div style={{ fontSize: 12, maxWidth: 400 }}>
              Direction of Travel
            </div>
          ),
        });
    });

    return dirColumns;
  }
  function buildColsNewDir(type) {
    let cols = [];

    let columnsTemp = columns;
    if (type === "detailed_panel") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "route_id"
      );
    }

    columnsTemp.map((headCell) => {
      headCell.checked &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) => {
            rowData.trkvolper = (rowData.trkaadt / rowData.aadt) * 100;
            return rowData[headCell.id].toLocaleString(
              "en-US",
              headCell.formatOptions
            );
          },
        });
      if (headCell.id === "end_point")
        cols.push({
          title: "Direction",
          field: "direction",
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: (
            <div style={{ fontSize: 12, maxWidth: 400 }}>
              Direction of Travel
            </div>
          ),
          tooltipStyle: {
            fontSize: 12,
          },
        });
    });

    return cols;
  }

  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [csvFileNameTest, setCsvFileNameTest] = useState("myTable");
  const [exportCols, setExportCols] = useState();
  const [exportData, setExportData] = useState();

  const TABLE_ICONS = {
    Export: () => <SaveAlt fontSize="large" className={classes.exportButton} />,
  };

  let filenameTemp = query.layerSpecs.layer
    .replace(/-/g, "")
    .replace(/\s+/g, "")
    .replace(/\(+/g, "-")
    .replace(/\)+/g, "");

  useEffect(() => {
    setRoadsData2(buildRoadsData());
  }, [query.userSelectedSegments]);

  function buildRoadsData() {
    let roadsData2Temp = [...roadsData2];
    if (query.userSelectedSegments.length > 0) {
      if (query.geoRoute === "top100/geojson") {
        query.userSelectedSegments.map((itm) => {
          let addRoad = false;
          if (roadsData2Temp.length === 0) addRoad = true;
          else {
            const existsCheck = roadsData2Temp.indexOf(
              roadsData2Temp.find((el) => el.mergekey === itm.mergekey)
            );

            if (existsCheck === -1) addRoad = true;
          }
          if (addRoad) {
            const selectedRows = query.features.filter(
              (obj) => obj.mergekey === itm.mergekey
            );

            selectedRows.map((sr) => {
              sr.route_id = sr.seg_name;
              sr.beg_point = sr.seg_from;
              sr.end_point = sr.seg_to;
              sr.trkvolper = (sr.trkaadt / sr.aadt) * 100;
              roadsData2Temp.push(sr);
            });
          }
        });
      } else {
        let rowsTemp = rows;
        query.userSelectedSegments.map((itm, index) => {
          // const target = dataDelete.find(
          //   (el) => el.id === oldData.tableData.id
          // );.

          let addRoad = false;
          if (roadsData2Temp.length === 0) addRoad = true;
          else {
            const existsCheck = roadsData2Temp.indexOf(
              roadsData2Temp.find((el) => el.mergekey === itm.mergekey)
            );

            if (existsCheck === -1) addRoad = true;
          }

          if (addRoad) {
            let my_filter = query.features.filter(
              (obj) =>
                obj.route_id === itm.route_id &&
                obj.beg_point >= itm.beg_point &&
                obj.end_point <= itm.end_point
            );

            // Add segments to rows
            my_filter.map((obj) => {
              obj.mergekey = itm.mergekey;
              rowsTemp.push(obj);
            });

            //Calculate sums for corridor
            let totalNames = [];
            databaseVariablesHPMS
              .filter((obj) => obj.condition === "sum")
              .map((slt) => totalNames.push([slt.variable]));
            let statsTotals = sumNames(my_filter, totalNames);

            // Calculate weighted values
            let weightValues = [
              "trkttivmt",
              "trkttivmt1",
              "trkttivmt2",
              "ttivmt",
              "ttivmt1",
              "ttivmt2",
              // "leng_cal",
              "seg_length",
              "lanemiles",
              "ttivmt_iri",
              "lanemiles_iri",
            ];
            let weightValuesTotals = sumNames(my_filter, weightValues);

            for (var jjj = weightValues.length - 1; jjj >= 0; jjj--) {
              totalNames = [];
              databaseVariablesHPMS
                .filter(
                  (obj) =>
                    obj.condition === "normalized" &&
                    obj.normalizedBy === weightValues[jjj]
                )
                .map((slt) => totalNames.push([slt.variable]));

              let statsTotalsTemp = weightedSumByNames(
                my_filter,
                totalNames,
                weightValues[jjj]
              );

              for (var iiii = totalNames.length - 1; iiii >= 0; iiii--) {
                if (weightValuesTotals[weightValues[jjj]] === 0)
                  statsTotals[totalNames[iiii][0]] = 0;
                else
                  statsTotals[totalNames[iiii][0]] =
                    statsTotalsTemp[totalNames[iiii][0]] /
                    weightValuesTotals[weightValues[jjj]];
              }
            }

            roadsData2Temp.push({
              route_id: itm.route_id,
              beg_point: itm.beg_point,
              end_point: itm.end_point,
              id: itm.mergekey,
              mergekey: itm.mergekey,
              direction1: getDirection(my_filter, "direction1"),
              direction2: getDirection(my_filter, "direction2"),
              trkvolper: (statsTotals.trkaadt / statsTotals.aadt) * 100,
              ...statsTotals,
            });
          }
        });
        setRows(rowsTemp);
      }
    }

    return roadsData2Temp;
  }
  // const [tableColumns, setTableColumns] = useState(buildColsNew("full_table"));
  // useEffect(() => {
  //   setTableColumns(buildColsNew("full_table"));
  // }, [columns]);

  return (
    <div className={classes.root}>
      {/* {roadsData.length > 0 && ( */}
      {query.geoRoute === "top100/geojson" && (
        <Paper>
          <MaterialTable
            title="User Selected Segments"
            columns={buildColsNew("full_table")}
            data={buildRoadsData()}
            options={{
              exportButton: true,
              // selection: true,
              exportAllData: true,
              exportMenu: [
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);
                    let newCols = [
                      {
                        title: "Reporting Year",
                        id: "year",
                        numeric: true,
                        disablePadding: false,
                        label: "Reporting Year",
                        tooltip: (
                          <div
                            style={{
                              fontSize: 12,
                              maxWidth: 400,
                            }}
                          >
                            Reporting Year - data year is one year prior.
                          </div>
                        ),
                        checked: true,
                        category: "reporting",
                      },
                    ];
                    let dataOut = data.map((obj) => {
                      return [query.yearSelected].concat(obj);
                    });
                    setExportCols(newCols.concat(cols));
                    setExportData(dataOut);

                    setCsvFileNameTest(
                      filenameTemp + "_segments_" + getCurrentDate()
                    );
                    // setExportCols(cols);
                    // setExportData(data);
                  },
                },
              ],
              selection: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 25, 50, 100],
              // toolbarButtonAlignment:
              //   selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            onRowClick={(event, rowData) => {
              // // Copy row data and set checked state
              // const rowDataCopy = { ...rowData };
              // rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
              // // Copy data so we can modify it
              // const dataCopy = [...roadsData];
              // // Find the row we clicked and update it with `checked` prop
              // dataCopy[rowDataCopy.tableData.id - 1] = rowDataCopy;
              // setRoadsData(dataCopy);
              // // handleClick(rowData);
            }}
            actions={[
              {
                tooltip: "Remove Selected Roads",
                icon: "delete",
                onClick: (evt, data) => handleRemoveSelected(data),
                // isFreeAction: true,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector
                        columns={columns}
                        tableType={"segments"}
                      />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
            ]}
            editable={{
              onRowDelete: (oldData) => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const target = roadsData2.find(
                      (el) => el.id === oldData.tableData.id
                    );

                    handleRemoveSelected([target]);

                    resolve();
                  }, 1000);
                });
              },
            }}
            onSelectionChange={(rows) => handleClick(rows)}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
      {query.geoRoute === "hpms/geojson" && (
        <Paper>
          <MaterialTable
            title="User Selected Segments"
            columns={buildColsNew("full_table")}
            // data={roadsData}
            // data={roadsData2}
            data={buildRoadsData()}
            options={{
              exportButton: true,
              // selection: true,
              exportAllData: true,
              exportMenu: [
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);
                    let newCols = [
                      {
                        title: "Reporting Year",
                        id: "year",
                        numeric: true,
                        disablePadding: false,
                        label: "Reporting Year",
                        tooltip: (
                          <div
                            style={{
                              fontSize: 12,
                              maxWidth: 400,
                            }}
                          >
                            Reporting Year - data year is one year prior.
                          </div>
                        ),
                        checked: true,
                        category: "reporting",
                      },
                    ];
                    let dataOut = data.map((obj) => {
                      return [query.yearSelected].concat(obj);
                    });
                    setExportCols(newCols.concat(cols));
                    setExportData(dataOut);

                    setCsvFileNameTest(
                      filenameTemp + "_segments_" + getCurrentDate()
                    );
                    // setExportCols(cols);
                    // setExportData(data);
                  },
                },
              ],
              selection: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 25, 50, 100],
              // toolbarButtonAlignment:
              //   selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            onRowClick={(event, rowData) => {
              // Copy row data and set checked state
              const rowDataCopy = { ...rowData };
              rowDataCopy.tableData.checked = !rowDataCopy.tableData.checked;
              // Copy data so we can modify it
              const dataCopy = [...roadsData2];

              dataCopy[rowDataCopy.tableData.id] = rowDataCopy;

              setRoadsData2(dataCopy);

              handleClick([rowData]);
            }}
            actions={[
              {
                tooltip: "Remove Selected Roads",
                icon: "delete",
                onClick: (evt, data) => handleRemoveSelected(data),
                // isFreeAction: true,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector
                        columns={columns}
                        tableType={"segments"}
                      />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
            ]}
            editable={{
              onRowDelete: (oldData) => {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const target = roadsData2.find(
                      (el) => el.id === oldData.tableData.id
                    );

                    handleRemoveSelected([oldData]);

                    resolve();
                  }, 1000);
                });
              },
            }}
            onSelectionChange={(rows) => {
              handleClick(rows);
            }}
            detailPanel={({ rowData }) => {
              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#43A047",
                  }}
                >
                  {/* {materialTableDetailPanel(rowData)} */}
                  <RoadSegmentDetailPanel
                    columns={columns}
                    buildColsNew={buildColsNew}
                    data={rowData}
                    setOpenExportDialog={setOpenExportDialog}
                    setExportCols={setExportCols}
                    setExportData={setExportData}
                    setCsvFileNameTest={setCsvFileNameTest}
                    rows={rows}
                    mode={mode}
                  />
                </div>
              );
            }}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
      {/* {roadsData.length === 0 && (
        <Paper className={classes.paper + " table-null"}>
          <Typography variant="subtitle1">
            Please select a segment or multiple segments using the map and click
            the "Add to table" button.
          </Typography>
        </Paper>
      )} */}
    </div>
  );
}
