import React, { useState, useEffect, useContext } from "react";

import RoadSegmentTable from "./RoadSegmentTable";
import SummaryTable from "./SummaryTable";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

export default function DataLayout(props) {
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const { mode } = props;

  const [rows, setRows] = useState([]);
  const [roads, setRoads] = useState([]);
  const [roadsID, setRoadsID] = useState(0);
  const [roadsData, setRoadsData] = useState([]);
  const [roadsData2, setRoadsData2] = useState([]);

  useEffect(() => {
    dispatch({
      type: "setUserSelection",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: NaN,
      },
    });

    dispatch({
      type: "setUserSelectionMD100",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: "",
      },
    });

    setRoads([]);
    setRoadsID(0);
    setRoadsData([]);
    setRows([]);

    dispatch({
      type: "setUserSelectedSegments",
      selection: [],
    });
  }, [query.layerSpecs.layer]);

  // const [dataTable, setDataTable] = useState(
  //   <SummaryTable layerID={query.statsLayerDesc} />
  // );
  // // useEffect(() => {
  // //   dispatch({
  // //     type: "setReportType",
  // //     selection: "User Selected Segments",
  // //   });
  // // }, [query.geography]);
  // useEffect(() => {
  //   if (query.reportType === "Summary")
  //     setDataTable(<SummaryTable layerID={query.statsLayerDesc} />);
  //   if (query.reportType === "User Selected Segments")
  //     setDataTable(
  //       <RoadSegmentTable
  //         rows={rows}
  //         setRows={setRows}
  //         roads={roads}
  //         setRoads={setRoads}
  //         roadsID={roadsID}
  //         setRoadsID={setRoadsID}
  //         roadsData={roadsData}
  //         setRoadsData={setRoadsData}
  //         layerID={query.statsLayerDesc}
  //       />
  //     );
  // }, [query.reportType]);

  // return <div>{dataTable}</div>;
  return (
    <div>
      {query.reportType === "Summary" && (
        <SummaryTable layerID={query.statsLayerDesc} mode={mode} />
      )}
      {query.reportType === "User Selected Segments" && (
        <RoadSegmentTable
          rows={rows}
          setRows={setRows}
          // roads={roads}
          // setRoads={setRoads}
          // roadsID={roadsID}
          // setRoadsID={setRoadsID}
          // roadsData={roadsData}
          // setRoadsData={setRoadsData}
          roadsData2={roadsData}
          setRoadsData2={setRoadsData}
          // layerID={query.statsLayerDesc}
          mode={mode}
        />
      )}
    </div>
  );
}
