/**
 * @fileOverview the global context provider component.
 */

import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import {
  SUMMARY_VARIABLES_HPMS,
  SUMMARY_VARIABLES_TOP100,
  SUMMARY_VARIABLES_ROUTE,
  SUMMARY_VARIABLES_ROUTE_100,
  SUMMARY_VARIABLES_GROUPING,
  SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL,
} from "./constants";

const GlobalStateContext = createContext();
const GlobalUpdaterContext = createContext();

// /**
//  *
//  * @param {*} res
//  * @returns parsed inputs
//  */
// const parseInputs = (res) => {

//     //let parsed = structuredClone(res);
//     let parsed = { ...res };
//     const facility = FACILITY_TYPE;
//     const dayType = DAY_TYPE;
//     const congestionLevel = CONGESTION_LEVEL;
//     const peakingDirection = PEAKING_DIRECTION;
//     parsed.congestionLevel = congestionLevel.find((e) => e.value === res.congestionLevel).label;
//     parsed.dayType = dayType.find((e) => e.value === res.dayType).label;
//     parsed.facilityType = facility.find((e) => e.value === res.facilityType).label;
//     parsed.peakingDirection = peakingDirection.find((e) => e.value === res.peakingDirection).label;
//     parsed.refSpeed = parseInt(res.refSpeed);
//     parsed.speeds = typeof res.speeds === "undefined" ? [] : res.speeds.map((e) => e.speed);
//     parsed.lanes = parseInt(res.lanes);
//     parsed.laneAddition = parseFloat(res.laneAddition);
//     parsed.aadt = parseInt(res.aadt);
//     return parsed;

// }
function filterTableVariables(category, type) {
  let results = [];
  let SUMMARY_VARIABLES_layer;
  if (type === "HPMS") {
    SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_HPMS;
  } else {
    SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_TOP100;
  }

  for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
    if (
      category === SUMMARY_VARIABLES_layer[i].category &&
      SUMMARY_VARIABLES_layer[i].variable !== " commodity_value"
    ) {
      results.push(SUMMARY_VARIABLES_layer[i]);
    }
  }

  return results;
}

function buildCols(type, grouping) {
  let cols = [];
  let colVars = [];

  if (type === "MD100" || type === "MD100-Directional") {
    if (type === "MD100") colVars = SUMMARY_VARIABLES_ROUTE_100;
    if (type === "MD100-Directional")
      colVars = SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL;
  } else if (grouping === "route_id") {
    colVars = SUMMARY_VARIABLES_ROUTE;
  } else {
    colVars.push(
      SUMMARY_VARIABLES_GROUPING.find((value) => {
        return value.id === grouping;
      })
    );
  }

  for (var i = colVars.length - 1; i >= 0; i--) {
    cols.push(colVars[i]);
  }

  colVars = filterTableVariables("all", type);

  for (var ii = colVars.length - 1; ii >= 0; ii--) {
    cols.push({
      id: colVars[ii].variable,
      numeric: true,
      disablePadding: false,
      label: colVars[ii].summaryName,
      tooltip: colVars[ii].tooltip,
      checked: true,
      category: colVars[ii].category,
      normalizedBy:
        typeof colVars[ii].normalizedBy !== "undefined"
          ? colVars[ii].normalizedBy
          : false,
      formatOptions: colVars[ii].formatOptions,
    });
  }

  colVars = filterTableVariables("truck", type);
  for (var iii = colVars.length - 1; iii >= 0; iii--) {
    cols.push({
      id: colVars[iii].variable,
      numeric: true,
      disablePadding: false,
      label: colVars[iii].summaryName,
      tooltip: colVars[iii].tooltip,
      checked: false,
      category: colVars[iii].category,
      normalizedBy:
        typeof colVars[iii].normalizedBy !== "undefined"
          ? colVars[iii].normalizedBy
          : false,
      formatOptions: colVars[iii].formatOptions,
    });
  }

  colVars = filterTableVariables("other", type);
  for (var iiii = colVars.length - 1; iiii >= 0; iiii--) {
    cols.push({
      id: colVars[iiii].variable,
      numeric: true,
      disablePadding: false,
      label: colVars[iiii].summaryName,
      tooltip: colVars[iiii].tooltip,
      checked: false,
      category: colVars[iiii].category,
      normalizedBy:
        typeof colVars[iiii].normalizedBy !== "undefined"
          ? colVars[iiii].normalizedBy
          : false,
      formatOptions: colVars[iiii].formatOptions,
    });
  }

  return cols;
}
const initialState = {
  openDrawer: true,
  layerSpecs: { category: "Top 100", layer: "Top 100 Monitored Roads" },
  geoRoute: "top100/geojson",
  geography: { region: "top-100-monitored-roads", county: "None" },
  // geoRoute: "hpms/geojson",
  // geography: { region: "western", county: "None" },
  segments: { segmentsTable: "roads", segmentSelection: "all" },
  //   statsLayerDesc: "Top 100",
  vehicleType: "all",
  directionality: "crossSectional",
  mapFocus: "None",
  reportType: "Summary",
  popUpEnabled: true,
  highlightQuery: function (feature) {
    return false;
  },
  userSelectedSegments: [],
  userSelection: { route_id: "", beg_point: Number.NaN, end_point: Number.NaN },
  userSelectionMD100: { route_id: "", beg_point: String.NaN, end_point: "" },
  features: [],
  userSelectedRoads: [],
  selectedRowies: [],
  // roadLayerType: "MD100",
  tableColumns: {},
  tableColumnsTop100: buildCols("MD100", "route_id"),
  tableColumnsHPMS: buildCols("HPMS", "route_id"),
  top100Filters: [
    { label: "1 through 5", id: "1-5", checked: true },
    { label: "6 through 10", id: "6-10", checked: true },
    { label: "11 through 25", id: "11-25", checked: true },
    { label: "26 through 50", id: "26-50", checked: true },
    { label: "51 through 100", id: "51-100", checked: true },
    { label: "101 through 150", id: "101-150", checked: true },
    { label: "151 +", id: "151-plus", checked: true },
  ],
  truckTop100Filters: [
    { label: "1 through 5", id: "1-5", checked: true },
    { label: "6 through 10", id: "6-10", checked: true },
    { label: "11 through 25", id: "11-25", checked: true },
    { label: "26 through 50", id: "26-50", checked: true },
    { label: "51 through 100", id: "51-100", checked: true },
    { label: "101 through 150", id: "101-150", checked: true },
    { label: "151 +", id: "151-plus", checked: true },
  ],
  ownershipFilters: [
    { label: "State Highway Agency", id: "1", checked: true },
    { label: "County Highway Agency", id: "2", checked: true },
    { label: "City or Municipal Highway Agency", id: "4", checked: true },
    { label: "Other State Agency", id: "21", checked: true },
    { label: "Other Local Agency", id: "25", checked: true },
    { label: "State Toll Authority", id: "31", checked: true },
    {
      label: "Other Public Instrumentality (e.g., Airport, School, University)",
      id: "40",
      checked: true,
    },
    { label: "Other Federal Agency", id: "60", checked: true },
    { label: "National Park Service", id: "66", checked: true },
    { label: "Corps of Engineers", id: "70", checked: true },
    { label: "Other", id: "80", checked: true },
  ],
  fsystemFilters: [
    { label: "Interstate", id: "1", checked: true },
    {
      label: "Principal Arterial – Other Freeways and Expressways",
      id: "2",
      checked: true,
    },
    { label: "Principal Arterial – Other", id: "3", checked: true },
    { label: "Minor Arterial", id: "4", checked: true },
    { label: "Major Collector", id: "5", checked: true },
    { label: "Minor Collector)", id: "6", checked: true },
    { label: "Local", id: "7", checked: true },
  ],
  urbanCodeFilters: [
    { label: "Rural", id: "99999", checked: true },
    { label: "Small Urban", id: "99998", checked: true },
    {
      label: "Aberdeen--Bel Air South--Bel Air North, MD",
      id: "199",
      checked: true,
    },
    { label: "Baltimore, MD", id: "4843", checked: true },
    { label: "Cumberland, MD--WV--PA", id: "21745", checked: true },
    { label: "Frederick, MD", id: "31519", checked: true },
    { label: "Hagerstown, MD--WV--PA", id: "36190", checked: true },
    {
      label: "Lexington Park--California--Chesapeake Ranch Estates, MD",
      id: "49594",
      checked: true,
    },
    { label: "Philadelphia, PA--NJ--DE--MD", id: "69076", checked: true },
    { label: "Salisbury, MD--DE", id: "78364", checked: true },
    { label: "Waldorf, MD", id: "91261", checked: true },
    { label: "Washington, DC--VA--MD", id: "92242", checked: true },
    { label: "Westminster--Eldersburg, MD", id: "94294", checked: true },
  ],
  nhsFilters: [
    { label: "Not on NHS", id: "0", checked: true },
    { label: "Non-connector NHS", id: "1", checked: true },
    { label: "Major Airport", id: "2", checked: true },
    { label: "Major Port Facility", id: "3", checked: true },
    { label: "Major Amtrak Station", id: "4", checked: true },
    { label: "Major Rail/Truck Terminal", id: "5", checked: true },
    { label: "Major Inner City Bus Terminal", id: "6", checked: true },
    {
      label: "Major Public Transportation or Multi-Modal Passenger Terminal",
      id: "7",
      checked: true,
    },
    { label: "Major Pipeline Terminal", id: "8", checked: true },
    { label: "Major Ferry Terminal", id: "9", checked: true },
  ],
  strahnetFilters: [
    { label: "Not on STRAHNET/connector", id: "0", checked: true },
    { label: "Regular STRAHNET", id: "1", checked: true },
    { label: "Connector", id: "2", checked: true },
  ],
  truckNetworkFilters: [
    { label: "Not on National Truck Network (NN)", id: "0", checked: true },
    { label: "Section is on the NN", id: "1", checked: true },
    { label: "Other state-designated truck route", id: "2", checked: true },
  ],
  countyFilters: [
    { label: "Allegany", id: 1, checked: true },
    { label: "Anne-Arundel", id: 3, checked: true },
    { label: "Baltimore", id: 5, checked: true },
    { label: "Baltimore-City", id: 510, checked: true },
    { label: "Calvert", id: 9, checked: true },
    { label: "Caroline", id: 11, checked: true },
    { label: "Carroll", id: 13, checked: true },
    { label: "Cecil", id: 15, checked: true },
    { label: "Charles", id: 17, checked: true },
    { label: "Dorchester", id: 19, checked: true },
    { label: "Frederick", id: 21, checked: true },
    { label: "Garrett", id: 23, checked: true },
    { label: "Harford", id: 25, checked: true },
    { label: "Howard", id: 27, checked: true },
    { label: "Kent", id: 29, checked: true },
    { label: "Montgomery", id: 31, checked: true },
    { label: "Prince-Georges", id: 33, checked: true },
    { label: "Queen-Annes", id: 35, checked: true },
    { label: "Somerset", id: 39, checked: true },
    { label: "St-Marys", id: 37, checked: true },
    { label: "Talbot", id: 41, checked: true },
    { label: "Washington", id: 43, checked: true },
    { label: "Wicomico", id: 45, checked: true },
    { label: "Worchester", id: 4, checked: true },
  ],
  tollPlazafeatures: {
    type: "FeatureCollection",
    features: [],
  },
  portsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  otherTerminalsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  intermodalFacilityFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  cargoAirportsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  autoTerminalsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  distributionCentersFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  truckWeighInspStationsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  airportsFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  truckParkingFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  countyBoundaryFeatures: {
    type: "FeatureCollection",
    features: [],
  },
  yearSelected: 2020,
};

const GlobalProvider = (props) => {
  // const queryReducer = (state, action) => {
  //     const { name, value, type, valueType } = action;
  //     let parsedValue = null;
  //     if (valueType === "number") parsedValue = parseFloat(value);
  //     switch (type) {
  //         case "setSubmitOutputs":
  //             return {
  //                 ...state,
  //                 submitOutputs: action.selection,
  //             };
  //         case "setBeforeScenarioInputs":
  //             return {
  //                 ...state,
  //                 beforeScenarioInputs: action.selection,
  //             };
  //         case "setAfterScenarioInputs":
  //             return {
  //                 ...state,
  //                 afterScenarioInputs: action.selection,
  //             };
  //         case "setInputs":

  //             return {
  //                 ...state,
  //                 [name]: parsedValue,
  //             }
  //         default:
  //             console.log(action);
  //             throw new Error();
  //     }
  // };

  const queryReducer = (state, action) => {
    switch (action.type) {
      case "setOpenDrawer":
        return {
          ...state,
          openDrawer: action.selection,
        };
      // layerSpecs
      case "setLayerSpecs":
        return {
          ...state,
          layerSpecs: action.selection,
        };
      //   case "setStatsLayerDesc":
      //     return {
      //       ...state,
      //       statsLayerDesc: action.selection,
      //     };
      case "setGeography":
        return {
          ...state,
          geography: action.selection,
        };
      case "setSegments":
        return {
          ...state,
          segments: action.selection,
        };
      case "setGeoRoute":
        return {
          ...state,
          geoRoute: action.selection,
        };
      case "setVehicleType":
        return {
          ...state,
          vehicleType: action.selection,
        };
      case "setDirectionality":
        return {
          ...state,
          directionality: action.selection,
        };
      case "setMapFocus":
        return {
          ...state,
          mapFocus: action.selection,
        };
      case "setReportType":
        return {
          ...state,
          reportType: action.selection,
        };

      case "enablePopUp":
        return {
          ...state,
          popUpEnabled: true,
        };
      case "disablePopUp":
        return {
          ...state,
          popUpEnabled: false,
        };
      case "setHighlightQuery":
        return {
          ...state,
          highlightQuery: action.query,
        };

      case "setUserSelection":
        return {
          ...state,
          userSelection: action.selection,
        };
      case "setUserSelectedSegments":
        return {
          ...state,
          userSelectedSegments: action.selection,
        };
      case "setSelectedRows":
        return {
          ...state,
          selectedRows: action.selected,
        };
      case "setUserSelectionMD100":
        return {
          ...state,
          userSelectionMD100: action.selection,
        };
      case "setFeatures":
        return {
          ...state,
          // features: action.payload.map((x) => x.properties),
          features: action.selection,
        };
      case "setUserSelectedRoads":
        return {
          ...state,
          // features: action.payload.map((x) => x.properties),
          userSelectedRoads: action.selection,
        };
      case "updateFeatures":
        return {
          ...state,
          features: action.payload,
        };
      // case "setRoadLayerType":
      //   return {
      //     ...state,
      //     roadLayerType: action.selection,
      //   };
      case "setTableColumns":
        return {
          ...state,
          tableColumns: action.selection,
        };
      case "setTableColumnsTop100":
        return {
          ...state,
          tableColumnsTop100: action.selection,
        };
      case "setTableColumnsHPMS":
        return {
          ...state,
          tableColumnsHPMS: action.selection,
        };
      case "setTop100Filters":
        return {
          ...state,
          top100Filters: action.selection,
        };
      case "setTruckTop100Filters":
        return {
          ...state,
          truckTop100Filters: action.selection,
        };
      case "setOwnershipFilters":
        return {
          ...state,
          ownershipFilters: action.selection,
        };
      case "setFsystemFilters":
        return {
          ...state,
          fsystemFilters: action.selection,
        };
      case "setUrbanCodeFilters":
        return {
          ...state,
          urbanCodeFilters: action.selection,
        };
      case "setNhsFilters":
        return {
          ...state,
          nhsFilters: action.selection,
        };
      case "setStrahnetFilters":
        return {
          ...state,
          strahnetFilters: action.selection,
        };
      case "setTruckNetworkFilters":
        return {
          ...state,
          truckNetworkFilters: action.selection,
        };
      case "setTollPlazafeatures":
        return {
          ...state,
          tollPlazafeatures: action.selection,
        };
      case "setPortsFeatures":
        return {
          ...state,
          portsFeatures: action.selection,
        };
      case "setOtherTerminalsFeatures":
        return {
          ...state,
          otherTerminalsFeatures: action.selection,
        };
      case "setIntermodalFacilityFeatures":
        return {
          ...state,
          intermodalFacilityFeatures: action.selection,
        };
      case "setCargoAirportsFeatures":
        return {
          ...state,
          cargoAirportsFeatures: action.selection,
        };
      case "setAutoTerminalsFeatures":
        return {
          ...state,
          autoTerminalsFeatures: action.selection,
        };
      case "setDistributionCentersFeatures":
        return {
          ...state,
          distributionCentersFeatures: action.selection,
        };
      case "setTruckWeighInspStationsFeatures":
        return {
          ...state,
          truckWeighInspStationsFeatures: action.selection,
        };
      case "setAirportsFeatures":
        return {
          ...state,
          airportsFeatures: action.selection,
        };
      case "setTruckParkingFeatures":
        return {
          ...state,
          truckParkingFeatures: action.selection,
        };
      case "setCountyBoundaryFeatures":
        return {
          ...state,
          countyBoundaryFeatures: action.selection,
        };
      case "setYearSelected":
        return {
          ...state,
          yearSelected: action.selection,
        };
      default:
        throw new Error();
    }
  };

  //   const [state, dispatch] = useReducer(queryReducer, INITIAL_INPUTS);
  const [state, dispatch] = useReducer(queryReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalUpdaterContext.Provider value={dispatch}>
        {props.children}
      </GlobalUpdaterContext.Provider>
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const globalState = useContext(GlobalStateContext);
  if (typeof globalState === "undefined") {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return globalState;
};

const useGlobalUpdater = () => {
  const dispatch = useContext(GlobalUpdaterContext);
  if (typeof dispatch === "undefined") {
    throw new Error("useGlobalUpdater must be used within a GlobalProvider");
  }
  //const increment = useCallback(() => setCount((c) => c + 1), [setCount])
  const updater = useCallback((props) => dispatch(props), [dispatch]);
  return updater;
};

// const useTruckInputs = () => {
//   const globalState = useContext(GlobalStateContext);
//   if (typeof globalState === "undefined") {
//     throw new Error("useTruckInputs must be used within a GlobalProvider");
//   }
//   const inputs = useMemo(() => {
//     const after = globalState.afterScenarioInputs;
//     const before = globalState.beforeScenarioInputs;
//     console.log(["useTruckInputs-before", before]);
//     let parsedBefore = parseInputs(before);
//     let parsedAfter = parseInputs(after);

//     let inputs = { ...InputDataForTest };

//     inputs.before.Facility.facility_type = parsedBefore.facilityType;
//     inputs.before.Facility.aadt = parsedBefore.aadt;
//     inputs.before.Facility.lane_length = parsedBefore.length;
//     inputs.before.Facility.number_of_lanes = parsedBefore.lanes;
//     inputs.before.Facility.lane_addition = parsedBefore.laneAddition;
//     inputs.before.Facility.treatment_types = parsedBefore.treatmentTypes;
//     inputs.before.Facility.reference_speed = parsedBefore.refSpeed;
//     inputs.before.Car.percent = parsedBefore.dailyTruckPercent / 100;
//     inputs.before.Car.type = "Truck";
//     inputs.before.Car.value_of_time = globalState["value-of-time-truck"];
//     inputs.before.Car.fuel_consumption = globalState["fuel-consumption-truck"];
//     inputs.before.Other.climate_cluster = parsedBefore.climateCluster;
//     inputs.before.Other.congestion_level = parsedBefore.congestionLevel;
//     inputs.before.Other.day_type = parsedBefore.dayType;
//     inputs.before.Other.peak_direction = parsedBefore.peakingDirection;
//     inputs.before.Other.speed_profile = parsedBefore.speeds;
//     inputs.after.Other = inputs.before.Other;
//     inputs.after.Car = inputs.before.Car;
//     inputs.after.Facility.facility_type = parsedAfter.facilityType;
//     inputs.after.Facility.aadt = parsedAfter.aadt;
//     inputs.after.Facility.lane_length = parsedAfter.length;
//     inputs.after.Facility.number_of_lanes =
//       parsedBefore.lanes + parsedAfter.laneAddition;
//     inputs.after.Facility.lane_addition = parsedAfter.laneAddition;
//     inputs.after.Facility.treatment_types = parsedAfter.treatmentTypes;
//     inputs.after.Facility.reference_speed = parsedAfter.refSpeed;
//     return inputs;
//   }, [globalState]);
//   return inputs;
// };

// const usePassengerInputs = () => {
//   const globalState = useContext(GlobalStateContext);
//   if (typeof globalState === "undefined") {
//     throw new Error("usePassengerInputs must be used within a GlobalProvider");
//   }
//   const inputs = useMemo(() => {
//     const after = globalState.afterScenarioInputs;
//     const before = globalState.beforeScenarioInputs;
//     let parsedBefore = parseInputs(before);
//     let parsedAfter = parseInputs(after);

//     let inputs = { ...InputDataForTestCar };

//     inputs.before.Facility.facility_type = parsedBefore.facilityType;
//     inputs.before.Facility.aadt = parsedBefore.aadt;
//     inputs.before.Facility.lane_length = parsedBefore.length;
//     inputs.before.Facility.number_of_lanes = parsedBefore.lanes;
//     inputs.before.Facility.lane_addition = parsedBefore.laneAddition;
//     inputs.before.Facility.treatment_types = parsedBefore.treatmentTypes;
//     inputs.before.Facility.reference_speed = parsedBefore.refSpeed;
//     inputs.before.Car.percent = 1 - parsedBefore.dailyTruckPercent / 100;
//     inputs.before.Car.type = "Passenger Vehicle";
//     inputs.before.Car.value_of_time = globalState["value-of-time-passenger"];
//     inputs.before.Car.fuel_consumption =
//       globalState["fuel-consumption-passenger"];
//     inputs.before.Other.climate_cluster = parsedBefore.climateCluster;
//     inputs.before.Other.congestion_level = parsedBefore.congestionLevel;
//     inputs.before.Other.day_type = parsedBefore.dayType;
//     inputs.before.Other.peak_direction = parsedBefore.peakingDirection;
//     inputs.before.Other.speed_profile = parsedBefore.speeds;
//     inputs.after.Other = inputs.before.Other;
//     inputs.after.Car = inputs.before.Car;
//     inputs.after.Facility.facility_type = parsedAfter.facilityType;
//     inputs.after.Facility.aadt = parsedAfter.aadt;
//     inputs.after.Facility.lane_length = parsedAfter.length;
//     inputs.after.Facility.number_of_lanes =
//       parsedBefore.lanes + parsedAfter.laneAddition;
//     inputs.after.Facility.lane_addition = parsedAfter.laneAddition;
//     inputs.after.Facility.treatment_types = parsedAfter.treatmentTypes;
//     inputs.after.Facility.reference_speed = parsedAfter.refSpeed;
//     return inputs;
//   }, [globalState]);
//   return inputs;
// };

export {
  GlobalProvider,
  useGlobalState,
  useGlobalUpdater,
  //   useTruckInputs,
  //   usePassengerInputs,
};
