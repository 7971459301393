import React, { useState, useContext, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "@material-table/core";

import { SUMMARY_VARIABLES_HPMS } from "../common/constants";

import { getCurrentDate } from "../utils/utils";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, mode) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { color: mode === "dark" ? "white" : "" },
  };
}

export default function RoadSegmentDetailPanel(props) {
  const {
    columns,
    buildColsNew,
    data,
    setOpenExportDialog,
    setExportCols,
    setExportData,
    setCsvFileNameTest,
    rows,
    mode,
  } = props;
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [tableType, setTableType] = React.useState("female");

  const handleChangeTableType = (event) => {
    setTableType(event.target.value);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function buildColsNewDir(type) {
    let cols = [];

    let columnsTemp = columns;
    if (type === "detailed_panel") {
      columnsTemp = columnsTemp.filter(
        (headCell) => headCell.id !== "route_id"
      );
    }

    columnsTemp.map((headCell) => {
      headCell.checked &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) => {
            rowData.trkvolper = (rowData.trkaadt / rowData.aadt) * 100;
            return rowData[headCell.id].toLocaleString(
              "en-US",
              headCell.formatOptions
            );
          },
        });
      if (headCell.id === "end_point")
        cols.push({
          title: "Direction",
          field: "direction",
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: (
            <div style={{ fontSize: 12, maxWidth: 400 }}>
              Direction of Travel
            </div>
          ),
          tooltipStyle: {
            fontSize: 12,
          },
        });
    });

    return cols;
  }

  function buildDirectionalData(fd) {
    let directionalData = [];
    let dir1Data = {};

    let dirColumns = [];

    columns.map((obj) => {
      dirColumns.push(obj);
      if (obj.id === "end_point")
        dirColumns.push({
          id: "direction",
          numeric: false,
          disablePadding: false,
          label: "Direction",
          checked: true,
          category: "route",
          tooltip: (
            <div style={{ fontSize: 12, maxWidth: 400 }}>
              Direction of Travel
            </div>
          ),
        });
    });
    dirColumns.map((obj) => {
      if (
        obj.id === "route_id" ||
        obj.id === "beg_point" ||
        obj.id === "end_point"
      )
        return (dir1Data[obj.id] = fd[obj.id]);
      if (obj.id === "direction") return (dir1Data[obj.id] = fd.direction1);

      let varDir1 = SUMMARY_VARIABLES_HPMS.filter(
        (itm) => itm.variable === obj.id
      )[0].variableDirection1;

      dir1Data[obj.id] = fd[varDir1];

      return;
    });

    directionalData.push(dir1Data);

    let dir2Data = {};

    dirColumns.map((obj) => {
      if (
        obj.id === "route_id" ||
        obj.id === "beg_point" ||
        obj.id === "end_point"
      )
        return (dir2Data[obj.id] = fd[obj.id]);
      if (obj.id === "direction") return (dir2Data[obj.id] = fd.direction2);

      let vardir2 = SUMMARY_VARIABLES_HPMS.filter(
        (itm) => itm.variable === obj.id
      )[0].variableDirection2;

      return (dir2Data[obj.id] = fd[vardir2]);
    });

    directionalData.push(dir2Data);

    return directionalData;
  }

  let routeId = data.route_id;
  let filenameTemp =
    routeId + "_" + data.beg_point + "to" + data.end_point + "_";

  return (
    <div
      style={{
        fontSize: 14,
        textAlign: "center",
        color: "white",
        // backgroundColor: "#E53935",
        backgroundColor: mode === "dark" ? "#1E1E1E" : "white",
        marginLeft: 25,
        marginBottom: 10,
        marginTop: 0,
        borderTopWidth: 0,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom:
              mode === "dark" ? `1px solid rgba(224, 224, 224, 0.5)` : "",
          }}
        >
          <Tab label="Segments" {...a11yProps(0, mode)} />
          <Tab label="Directional" {...a11yProps(1, mode)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MaterialTable
          title="Segments"
          columns={buildColsNew("detailed_panel")}
          data={rows.filter((match) => match.mergekey === data.mergekey)}
          options={{
            search: false,
            paging: false,
            exportButton: true,
            // selection: true,
            exportAllData: true,
            exportMenu: [
              {
                label: "Export Table",
                exportFunc: (cols, data) => {
                  setOpenExportDialog(true);
                  let newCols = [
                    {
                      title: "Reporting Year",
                      id: "year",
                      numeric: true,
                      disablePadding: false,
                      label: "Reporting Year",
                      tooltip: (
                        <div style={{ fontSize: 12, maxWidth: 400 }}>
                          Reporting Year - data year is one year prior.
                        </div>
                      ),
                      checked: true,
                      category: "reporting",
                    },
                    {
                      title: "Route ID",
                      id: "route_id",
                      numeric: false,
                      disablePadding: false,
                      label: "Route ID",
                      tooltip: (
                        <div style={{ fontSize: 12, maxWidth: 400 }}>
                          HPMS route identifier/road name.
                        </div>
                      ),
                      checked: true,
                      category: "route",
                    },
                  ];
                  let dataOut = data.map((obj) => {
                    return [query.yearSelected, routeId].concat(obj);
                  });
                  setExportCols(newCols.concat(cols));
                  setExportData(dataOut);

                  setCsvFileNameTest(
                    filenameTemp + "_segments_" + getCurrentDate()
                  );
                  // setExportCols(cols);
                  // setExportData(data);
                },
              },
            ],
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTable
          title="Directional"
          // columns={buildColsNew("full_table")}
          columns={buildColsNewDir("full_table")}
          data={buildDirectionalData(data)}
          // data={roadsData}
          options={{
            search: false,
            paging: false,
            exportButton: true,
            // selection: true,
            exportAllData: true,
            exportMenu: [
              {
                label: "Export Table",
                exportFunc: (cols, data) => {
                  setOpenExportDialog(true);
                  let newCols = [
                    {
                      title: "Reporting Year",
                      id: "year",
                      numeric: true,
                      disablePadding: false,
                      label: "Reporting Year",
                      tooltip: (
                        <div style={{ fontSize: 12, maxWidth: 400 }}>
                          Reporting Year - data year is one year prior.
                        </div>
                      ),
                      checked: true,
                      category: "reporting",
                    },
                    {
                      title: "Route ID",
                      id: "route_id",
                      numeric: false,
                      disablePadding: false,
                      label: "Route ID",
                      tooltip: (
                        <div style={{ fontSize: 12, maxWidth: 400 }}>
                          HPMS route identifier/road name.
                        </div>
                      ),
                      checked: true,
                      category: "route",
                    },
                  ];
                  let dataOut = data.map((obj) => {
                    return [query.yearSelected, routeId].concat(obj);
                  });
                  setExportCols(newCols.concat(cols));
                  setExportData(dataOut);

                  setCsvFileNameTest(
                    filenameTemp + "_segments_" + getCurrentDate()
                  );
                  // setExportCols(cols);
                  // setExportData(data);
                },
              },
            ],
          }}
        />
      </TabPanel>
    </div>
  );
}
