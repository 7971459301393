import React, { useState, useContext, useEffect } from "react";

import { lighten, withStyles, makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import Icon from '@material-ui/core/Icon';
import Paper from "@material-ui/core/Paper";

//import Image from 'material-ui-image';

import { QueryContext } from "../map/QueryContext";

// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
// } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";

import InfoIcon from "@material-ui/icons/Info";

import { Grid } from "@material-ui/core/";
import Divider from "@material-ui/core/Divider";

import { yearOptions } from "../common/constants";

import YearSelection from "../map/YearSelection";

import GeneralFilter from "../map/GeneralFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: 0,
    // backgroundColor: theme.palette.primary.light,
    // color: theme.palette.primary.contrastText,
    boxShadow: "none",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  cardcontent: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardActions: {
    color: "#5945cc",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    marginTop: 5,
  },
}));

export default function SettingsFiltersTab(props) {
  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper + " table-wrap"}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          // borderColor="red"
        >
          {/* <Grid item xs={2} key={"data-year-selection"}>
            
            <Card classes={{ root: classes.card }}>
              <CardHeader
                title=" "
                subheader=" "
                style={{ marginBottom: "none" }}
              />
              <CardContent className={classes.cardcontent}>
                <YearSelection
                  filters={yearOptions}
                  dispatchType={"setYearSelected"}
                  menuTitle={"Data Year"}
                />
              </CardContent>
            </Card>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{
              // marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          /> */}
          <Grid item xs={4} key={"data-filters"}>
            {/* <Typography variant="h5" component="h6" className={classes.title}>
              Data Year
            </Typography> */}
            <Card classes={{ root: classes.card }}>
              <CardHeader
                title=" "
                subheader=" "
                style={{ marginBottom: "none" }}
              />
              <CardContent className={classes.cardcontent}>
                {(query.roadLayerType === "MD100" ||
                  query.roadLayerType === "MD100-Directional") && (
                  <div>
                    <GeneralFilter
                      filters={query.top100Filters}
                      dispatchType={"setTop100Filters"}
                      menuTitle={"Top 100 Bottlenecks Rankings"}
                    />
                    <GeneralFilter
                      filters={query.truckTop100Filters}
                      dispatchType={"setTruckTop100Filters"}
                      menuTitle={"Truck Top 100 Bottlenecks Rankings"}
                    />
                  </div>
                )}
                {query.roadLayerType !== "MD100" &&
                  query.roadLayerType !== "MD100-Directional" && (
                    <div>
                      <GeneralFilter
                        filters={query.ownershipFilters}
                        dispatchType={"setOwnershipFilters"}
                        menuTitle={"Ownership"}
                      />
                      <GeneralFilter
                        filters={query.fsystemFilters}
                        dispatchType={"setFsystemFilters"}
                        menuTitle={"Functional System"}
                      />
                      <GeneralFilter
                        filters={query.urbanCodeFilters}
                        dispatchType={"setUrbanCodeFilters"}
                        menuTitle={"Urban Code"}
                      />
                      <GeneralFilter
                        filters={query.nhsFilters}
                        dispatchType={"setNhsFilters"}
                        menuTitle={"National Highway System (NHS)"}
                      />
                      <GeneralFilter
                        filters={query.strahnetFilters}
                        dispatchType={"setStrahnetFilters"}
                        menuTitle={"Strategic Highway Network (STRAHNET)"}
                      />
                      <GeneralFilter
                        filters={query.truckNetworkFilters}
                        dispatchType={"setTruckNetworkFilters"}
                        menuTitle={"National Truck Network (NN)"}
                      />
                    </div>
                  )}
              </CardContent>
            </Card>
          </Grid>
          {/* <Divider
            orientation="vertical"
            flexItem
            style={{
              // marginTop: "10px",
              marginLeft: "-10px",
              marginRight: "0px",
            }}
          />{" "} */}
        </Grid>
      </Paper>
    </div>
  );
}
