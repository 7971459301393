import React, { useContext, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
// import Grid from "@mui/material/Grid";
// import ClickAwayListener from "@mui/material/ClickAwayListener";

import { sidebarStyles } from "../ui/styles";
import {
  geographic_options,
  // geographic_options_counties,
  // geographic_options_critical_corridors,
  // geographic_options_other_fway_corridors,
  // geographic_options_other_art_corridors,
  // geographic_options_other_freight_corridors,
  // geographic_options_top100,
  vehicleTypes,
  directionalityOptions,
  mapFocusCategories,
  geographicChoices,
} from "../common/constants";

// import ShapefileDownloadDialog from "./ShapefileDownloadDialog";

import { yearOptions } from "../common/constants";

// import YearSelection from "../map/YearSelection";
import Box from "@mui/material/Box";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import GeneralFilter from "../map/GeneralFilter";

import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Sidebar = (props) => {
  const { mode } = props;

  // "&.MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "red"
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "yellow"
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "green"
  //   }
  // }

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          root: {
            color: mode === "dark" ? "white" : "",
            "&:hover": {
              "&& fieldset": {
                border: "3px solid green",
              },
            },
          },
          notchedOutline: {
            borderColor: mode === "dark" ? "white" : "#1E1E1E",
            color: mode === "dark" ? "white" : "",
          },
          input: {
            color: mode === "dark" ? "white" : "",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          root: { color: mode === "dark" ? "white" : "" },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: { color: mode === "dark" ? "white" : "" },
          label: { color: mode === "dark" ? "white" : "" },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            whiteSpace: "normal",
            fontSize: 14,
            // borderColor: "white",
            color: mode === "dark" ? "white" : "",
            // borderColor: mode === "dark" ? "white" : "",
            // border: `2px solid white`,
          },
          icon: {
            color: mode === "dark" ? "white" : "",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "white" : "",
            backgroundColor: mode === "dark" ? "#b28c27" : "",
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          rounded: {
            color: mode === "dark" ? "white" : "",
            backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
          root: {
            border: mode === "dark" ? `1px solid white` : `1px solid #1E1E1E`,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            color: mode === "dark" ? "white" : "",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "white" : "",
            borderColor: mode === "dark" ? "white" : "",
          },
        },
      },
    },
  });

  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  // console.log("mode-check", mode);

  const classes = sidebarStyles();

  // const [geographicOptions, setGeographicOptions] =
  //   React.useState(geographic_options);
  // const [geoCatSelected, setGeoCatSelected] = React.useState("None");
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [shapefileDownloadOpen, setShapefileDownloadOpen] =
  //   React.useState(false);
  // const handleClickOpenShapefiles = () => {
  //   setShapefileDownloadOpen(true);
  // };

  // function GeographicSelectionDialog(propsGeo) {
  //   return (
  //     <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
  //       <DialogTitle>Available layers:</DialogTitle>
  //       <DialogContent>
  //         {propsGeo.geographicOptions.map((item, index) => (
  //           <Link
  //             className={item.active ? "" : "link-disabled"}
  //             key={item.text}
  //             underline="none"
  //             color="inherit"
  //             href="#"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               if (item.active) {
  //                 // props.setStatsLayer(item.text);
  //                 dispatch({
  //                   type: "setStatsLayerDesc",
  //                   selection: item.text,
  //                 });
  //                 // props.setRegion(
  //                 //   item.text
  //                 //     .replace(/\s+/g, "-")
  //                 //     .replace("'", "")
  //                 //     .replace(".", "")
  //                 //     .toLowerCase()
  //                 // );
  //                 // props.setCounty(
  //                 //   item.text
  //                 //     .replace(/\s+/g, "-")
  //                 //     .replace("'", "")
  //                 //     .replace(".", "")
  //                 // );
  //                 dispatch({
  //                   type: "setGeography",
  //                   selection: {
  //                     region: item.text
  //                       .replace(/\s+/g, "-")
  //                       .replace("'", "")
  //                       .replace(".", "")
  //                       .toLowerCase(),
  //                     county: item.text
  //                       .replace(/\s+/g, "-")
  //                       .replace("'", "")
  //                       .replace(".", ""),
  //                   },
  //                 });

  //                 // props.setGeoRoute(item.geoRoute);
  //                 dispatch({
  //                   type: "setGeoRoute",
  //                   selection: item.geoRoute,
  //                 });
  //                 // props.setSegmentsTable(item.segmentsTable);
  //                 // props.setSegmentSelection(item.segmentSelection);
  //                 dispatch({
  //                   type: "setSegments",
  //                   selection: {
  //                     segmentsTable: item.segmentsTable,
  //                     segmentSelection: item.segmentSelection,
  //                   },
  //                 });

  //                 if (geoCatSelected === "region") {
  //                   // props.setRegion(
  //                   //   item.text
  //                   //     .replace(/\s+/g, "-")
  //                   //     .replace("'", "")
  //                   //     .replace(".", "")
  //                   //     .toLowerCase()
  //                   // );
  //                   // props.setCounty("None");
  //                   dispatch({
  //                     type: "setGeography",
  //                     selection: {
  //                       region: item.text
  //                         .replace(/\s+/g, "-")
  //                         .replace("'", "")
  //                         .replace(".", "")
  //                         .toLowerCase(),
  //                       county: "None",
  //                     },
  //                   });
  //                 }
  //                 if (geoCatSelected === "county") {
  //                   // props.setRegion("None");
  //                   // props.setCounty(
  //                   //   item.text
  //                   //     .replace(/\s+/g, "-")
  //                   //     .replace("'", "")
  //                   //     .replace(".", "")
  //                   // );

  //                   dispatch({
  //                     type: "setGeography",
  //                     selection: {
  //                       region: "None",
  //                       county: item.text
  //                         .replace(/\s+/g, "-")
  //                         .replace("'", "")
  //                         .replace(".", ""),
  //                     },
  //                   });
  //                 }
  //               }
  //             }}
  //           >
  //             <ListItem button onClick={handleClose}>
  //               <ListItemIcon>
  //                 <Icon>{item.icon}</Icon>
  //               </ListItemIcon>
  //               <ListItemText primary={item.text} />
  //             </ListItem>
  //           </Link>
  //         ))}
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={handleClose} color="primary">
  //           Close
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // }

  const handleChange = (event) => {
    // setValue(event.target.value);

    dispatch({
      type: event.target.name,
      selection: event.target.value,
    });

    //TEST CODE - TO BE DELETED
    // dispatch({
    //   type: "setGeography",
    //   selection: { region: "top-100-monitored-roads", county: "None" },
    // });
    // dispatch({
    //   type: "setGeoRoute",
    //   selection: "top100/geojson",
    // });
    // dispatch({
    //   type: "setMapFocus",
    //   selection: "None",
    // });
    //END TEST CODE - TO BE DELETED
  };

  // uniqueValues = [...setOfValue];
  // setOfValue = new Set(valueArray);
  // valueArray = myjson.DATA.map({name} => name)
  let setOfGeoCategories = new Set(
    geographicChoices.map((itm) => itm.category)
  );

  const handleChangeLayerCategory = (event) => {
    // setValue(event.target.value);

    dispatch({
      type: event.target.name,
      selection: {
        category: event.target.value,
        layer: query.layerSpecs.layer,
      },
    });
    setOpenLayerSelect(!openLayerSelect);
  };

  const handleChangeLayerClose = (event) => {
    if (event.target.textContent === "") {
      dispatch({
        type: "setLayerSpecs",
        selection: {
          category: geographicChoices.filter(
            (itm) => itm.text === query.layerSpecs.layer
          )[0].category,
          layer: query.layerSpecs.layer,
        },
      });
    } else {
      dispatch({
        type: "setLayerSpecs",
        selection: {
          category: query.layerSpecs.category,
          layer: event.target.textContent,
        },
      });

      let item = geographicChoices.filter(
        (itm) => itm.text === event.target.textContent
      )[0];
      dispatch({
        type: "setGeoRoute",
        selection: item.geoRoute,
      });

      dispatch({
        type: "setGeography",
        selection: {
          region: item.text
            .replace(/\s+/g, "-")
            .replace("'", "")
            .replace(".", "")
            .toLowerCase(),
          county: item.text
            .replace(/\s+/g, "-")
            .replace("'", "")
            .replace(".", ""),
        },
      });
      if (item.category === "Region")
        dispatch({
          type: "setGeography",
          selection: {
            region: item.text
              .replace(/\s+/g, "-")
              .replace("'", "")
              .replace(".", "")
              .toLowerCase(),
            county: "None",
          },
        });
      if (item.category === "County")
        dispatch({
          type: "setGeography",
          selection: {
            region: "None",
            county: item.text
              .replace(/\s+/g, "-")
              .replace("'", "")
              .replace(".", ""),
          },
        });
      dispatch({
        type: "setSegments",
        selection: {
          segmentsTable: item.segmentsTable,
          segmentSelection: item.segmentSelection,
        },
      });
      dispatch({
        type: "setMapFocus",
        selection: "None",
      });
      if (
        item.text === "Top 100 Truck Bottlenecks" ||
        item.text === "Directional Top 100 Truck Bottlenecks"
      ) {
        dispatch({
          type: "setVehicleType",
          selection: "truck",
        });
      } else {
        if (
          item.text === "Top 100 All-Vehicle Bottlenecks" ||
          item.text === "Directional Top 100 All-Vehicle Bottlenecks"
        ) {
          dispatch({
            type: "setVehicleType",
            selection: "all",
          });
        }
      }
      if (
        item.text === "Directional Top 100 All-Vehicle Bottlenecks" ||
        item.text === "Directional Top 100 Truck Bottlenecks"
      ) {
        dispatch({
          type: "setDirectionality",
          selection: "directional",
        });
      } else {
        if (
          item.text === "Top 100 All-Vehicle Bottlenecks" ||
          item.text === "Top 100 Truck Bottlenecks"
        ) {
          dispatch({
            type: "setDirectionality",
            selection: "crossSectional",
          });
        }
      }
      dispatch({
        type: "setFeatures",
        selection: [],
      });
    }
    setOpenLayerSelect(!openLayerSelect);
  };

  const handleChangeLayerOpen = (event) => {
    setOpenLayerSelect(!openLayerSelect);
  };

  const [openLayerSelect, setOpenLayerSelect] = React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState(false);

  // const [filterBackgroundColor, setFilterBackgroundColor] =
  //   React.useState("none");
  useEffect(() => {
    let filterCheck = false;
    if (
      // query.roadLayerType === "MD100" ||
      // query.roadLayerType === "MD100-Directional"
      query.layerSpecs.category === "Top 100"
    ) {
      query.top100Filters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.truckTop100Filters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
    }
    if (
      // query.roadLayerType !== "MD100" &&
      // query.roadLayerType !== "MD100-Directional"
      query.layerSpecs.category !== "Top 100"
    ) {
      query.ownershipFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.fsystemFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.urbanCodeFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.nhsFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.strahnetFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
      query.truckNetworkFilters.map((itm) => {
        if (!itm.checked) filterCheck = true;
      });
    }

    // if (filterCheck) setFilterBackgroundColor("#F88379");
    // if (!filterCheck && mode === "dark") setFilterBackgroundColor("#ffffff");

    setFilterSelected(filterCheck);
  }, [
    query.top100Filters,
    query.truckTop100Filters,
    query.ownershipFilters,
    query.fsystemFilters,
    query.urbanCodeFilters,
    query.nhsFilters,
    query.strahnetFilters,
    query.truckNetworkFilters,
    // query.roadLayerType,
    query.layerSpecs.category,
  ]);

  const drawer2 = (
    <div className="drawer-wrapper">
      {/* <div className={classes.toolbar} /> */}
      {/* <Divider />
      <div className="current">
        <Typography variant="subtitle1">What's New (Coming Soon)</Typography>
      </div> */}
      {/* <Divider /> */}
      <div className="current">
        <Typography variant="subtitle1">Select Layer:</Typography>
      </div>

      <div className="current">
        <FormControl
          sx={{
            m: 0,
            minWidth: 150,
            borderColor: mode === "dark" ? "#F9F6EE" : "",
          }}
        >
          <InputLabel
            id="demo-simple-select-helper-label"
            // sx={{
            //   color: mode === "dark" ? "#F9F6EE" : "",
            // }}
          >
            <Typography variant="subtitle1">Category:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.layerSpecs.category}
            onChange={handleChangeLayerCategory}
            label="1a. Select geographic category:"
            name="setLayerSpecs"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
                // borderColor: "white",
                color: mode === "dark" ? "white" : "",
                // borderColor: mode === "dark" ? "white" : "",
              },
            }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
            {[...setOfGeoCategories].map((obj, index) => (
              <MenuItem value={obj} key={`sidebar-geo-cat-opt-${index}`}>
                {obj}
              </MenuItem>
            ))}
          </Select>

          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>
      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 150 }}>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Layer:</Typography>
          </InputLabel>

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.layerSpecs.layer}
            // onChange={handleChangeLayer}
            label="2. Select year:"
            name="setLayerSpecs"
            open={openLayerSelect}
            onClose={handleChangeLayerClose}
            onOpen={handleChangeLayerOpen}
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
            {geographicChoices
              .filter((itm) => itm.category === query.layerSpecs.category)
              .map((col, index) => (
                <MenuItem
                  value={col.text}
                  key={`sidebar-year-opt-${index}`}
                  // sx={{
                  //   width: 230,
                  //   "& .MuiMenuItem-root": {
                  //     whiteSpace: "normal",
                  //   },
                  // }}
                >
                  {col.text}
                </MenuItem>
              ))}
          </Select>

          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>

      <div className="current">
        <Typography variant="subtitle1">Select Data Preferences:</Typography>
      </div>

      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Year:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.yearSelected}
            onChange={handleChange}
            label="Year:"
            name="setYearSelected"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem> */}
            {yearOptions.map((col, index) => (
              <MenuItem value={col.value} key={`sidebar-year-opt-${index}`}>
                {col.label}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>
      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 120 }} fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Vehicle Types:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.vehicleType}
            onChange={handleChange}
            label="Vehicle Types:"
            name="setVehicleType"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {vehicleTypes.map((col, index) => (
              <MenuItem value={col.value} key={`sidebar-vtypes-opt-${index}`}>
                {col.text}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>
      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 120 }} fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Directionality:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.directionality}
            onChange={handleChange}
            label="Directionality:"
            name="setDirectionality"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {directionalityOptions.map((col, index) => (
              <MenuItem
                value={col.value}
                key={`sidebar-vtypes-opt-${index}`}
                disabled={col.disabled}
              >
                {col.text}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>

      <div className="current">
        <Typography variant="subtitle1">Select Map Preferences:</Typography>
      </div>

      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 120 }} fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Map Focus:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.mapFocus}
            onChange={handleChange}
            label="Map Focus:"
            name="setMapFocus"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {mapFocusCategories
              .filter((itm) => itm.category === query.geoRoute)
              .map((col, index) => (
                <MenuItem
                  value={col.description}
                  key={`sidebar-vtypes-opt-${index}`}
                >
                  {col.description}
                </MenuItem>
              ))}
          </Select>
          {/* <FormHelperText>With label + helper text</FormHelperText> */}
        </FormControl>
      </div>

      <div className="current">
        <Box
          sx={{
            width: 205,
            whiteSpace: "normal",
            mb: 2,
            mr: 0,
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              // class={classes.filterActive}
              // sx={{ backgroundColor: filterBackgroundColor }}
              // sx={[
              //   {
              //     backgroundColor: "blue",
              //   },
              //   filterSelected && {
              //     backgroundColor: "#F88379",
              //   },
              // ]}
              sx={{
                backgroundColor: filterSelected ? "#F88379" : "",
                borderRadius: filterSelected ? 1 : "",
              }}
              // sx={{ backgroundColor: "red" }}
            >
              <Typography>Select Filters:</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* {(query.roadLayerType === "MD100" ||
                query.roadLayerType === "MD100-Directional") && ( */}
              {query.geography.region === "top-100-monitored-roads" && (
                <div style={{ fontSize: 14, margin: 0, padding: 0 }}>
                  <GeneralFilter
                    filters={query.top100Filters}
                    dispatchType={"setTop100Filters"}
                    menuTitle={"Top 100 Bottlenecks Rankings"}
                  />
                  <GeneralFilter
                    filters={query.truckTop100Filters}
                    dispatchType={"setTruckTop100Filters"}
                    menuTitle={"Truck Top 100 Bottlenecks Rankings"}
                  />
                </div>
              )}
              {(query.geography.region === "top-100-all-vehicle-bottlenecks" ||
                query.geography.region ===
                  "directional-top-100-all-vehicle-bottlenecks") && (
                <div style={{ fontSize: 14, margin: 0, padding: 0 }}>
                  <GeneralFilter
                    filters={query.top100Filters}
                    dispatchType={"setTop100Filters"}
                    menuTitle={"Top 100 Bottlenecks Rankings"}
                  />
                </div>
              )}
              {(query.geography.region === "top-100-truck-bottlenecks" ||
                query.geography.region ===
                  "directional-top-100-truck-bottlenecks") && (
                <div style={{ fontSize: 14, margin: 0, padding: 0 }}>
                  <GeneralFilter
                    filters={query.truckTop100Filters}
                    dispatchType={"setTruckTop100Filters"}
                    menuTitle={"Truck Top 100 Bottlenecks Rankings"}
                  />
                </div>
              )}

              {/* )} */}
              {/* {query.roadLayerType !== "MD100" &&
                query.roadLayerType !== "MD100-Directional" && ( */}
              {query.layerSpecs.category !== "Top 100" && (
                <div>
                  <GeneralFilter
                    filters={query.ownershipFilters}
                    dispatchType={"setOwnershipFilters"}
                    menuTitle={"Ownership"}
                  />
                  <GeneralFilter
                    filters={query.fsystemFilters}
                    dispatchType={"setFsystemFilters"}
                    menuTitle={"Functional System"}
                  />
                  <GeneralFilter
                    filters={query.urbanCodeFilters}
                    dispatchType={"setUrbanCodeFilters"}
                    menuTitle={"Urban Code"}
                  />
                  <GeneralFilter
                    filters={query.nhsFilters}
                    dispatchType={"setNhsFilters"}
                    menuTitle={"National Highway System (NHS)"}
                  />
                  <GeneralFilter
                    filters={query.strahnetFilters}
                    dispatchType={"setStrahnetFilters"}
                    menuTitle={"Strategic Highway Network (STRAHNET)"}
                  />
                  <GeneralFilter
                    filters={query.truckNetworkFilters}
                    dispatchType={"setTruckNetworkFilters"}
                    menuTitle={"National Truck Network (NN)"}
                  />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
      <div className="current">
        <Typography variant="subtitle1">Reporting:</Typography>
      </div>
      <div className="current">
        <FormControl sx={{ m: 0, minWidth: 120 }} fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            <Typography variant="subtitle1">Report:</Typography>
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={query.reportType}
            onChange={handleChange}
            label="Report:"
            name="setReportType"
            sx={{
              // minWidth: 120,
              // maxWidth: 215,
              width: 215,
              "& .MuiSelect-select": {
                whiteSpace: "normal",
                fontSize: 14,
              },
            }}
          >
            {["Summary", "User Selected Segments"].map((col, index) => (
              <MenuItem value={col} key={`sidebar-report-opt-${index}`}>
                {col}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>Sets the table below the map.</FormHelperText> */}
        </FormControl>
      </div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div>{drawer2}</div>
    </ThemeProvider>
  );
};

Sidebar.propTypes = {
  style: PropTypes.object,
};

export default Sidebar;
