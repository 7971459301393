import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(100),
  },
}));

export default function WhatsNewDialog(props) {
  const { mode } = props;
  const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const { open, setOpen } = props;
  const handleClose = () => setOpen(false);

  // const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  //   "& .MuiDialogContent-root": {
  //     padding: theme.spacing(2),
  //   },
  //   "& .MuiDialogActions-root": {
  //     padding: theme.spacing(1),
  //   },
  // }));
  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          root: {
            color: mode === "dark" ? "white" : "",
            // backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
          paper: {
            color: mode === "dark" ? "white" : "",
            backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          //
          root: {
            color: mode === "dark" ? "white" : "",
            // backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          //
          root: {
            // borderTopWidth: 0.5,
            // borderTopColor: mode === "dark" ? "white" : "",
            // borderWidth: 0.1,
            // borderColor: mode === "dark" ? "white" : "",
            borderTop: "thin solid grey",
            borderBottom: "thin solid grey",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "white" : "",
            borderColor: mode === "dark" ? "white" : "",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "white" : "",
            borderColor: mode === "dark" ? "white" : "",
          },
        },
      },
    },
  });

  const updateJan2023 = [
    "User interface layout updates to improve vsibility and functionality.",
    "Ability to collapse/expand sidebar with tool settings and filters.",
    "Toggle switch for dark/light mode.",
  ];

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>What's New - Updated January 2, 2023</DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText id="alert-dialog-description">
            This dialog will contain information on updates to MRPT.
          </DialogContentText> */}
          <List
            component="div"
            aria-labelledby="nested-list-subheader"
            // className={classes.root}
            sx={{ width: "100%" }}
          >
            <ListItem>
              <ListItemIcon>
                <NewReleasesOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Jan 2023 - User Interface Updates" />
            </ListItem>

            {updateJan2023.map((item, index) => (
              <ListItem
                className={classes.nested}
                key={"updateJan2023-" + index}
                sx={{ marginLeft: 2 }}
              >
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
