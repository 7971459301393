import React, { useState, useEffect, useContext } from "react";
import L from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

// import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { makeStyles } from "@material-ui/core/styles";

// import ConstructionIcon from "@mui/icons-material/Construction";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#0047AB",
    // width: "1.25rem",
    // height: "1.25rem",
    // display: "block",
    left: "-0.25rem",
    top: "-0.25rem",
    position: "relative",
    // borderRadius: "0 0 0",
    // borderRadius: "3rem 3rem 0",
    // transform: "rotate(45deg)",
    // border: "1px solid #000000",
  },
}));

/**
Layer reference:
MDOT Consolidated Transportation Program (CTP) FY2022 - FY2027
https://data-maryland.opendata.arcgis.com/datasets/maryland::mdot-consolidated-transportation-program-ctp-fy2022-fy2027/about
https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_CTP_FY2021_to_FY2026_(View)/FeatureServer
**/

export default function CtpLayer(props) {
  // const [query, dispatch] = useContext(QueryContext);

  const { layerRef, iconMarkup } = props;

  const classes = useStyles();

  const customMarkerIcon = divIcon({
    html: iconMarkup,
    iconSize: [0, 0],
    iconAnchor: [0, 0],
    // popupAnchor: [-3, -76],
    // shadowUrl: "shadow1.png",
    // shadowSize: [68, 95],
    // shadowAnchor: [22, 94],
    shadowSize: [0, 0],
    shadowSize: [0, 0],
    shadowAnchor: [0, 0],
  });

  const pointToLayer = (feature, latlng) => {
    let tooltipText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Consolidated Transportation Program (CTP)</h3>
      </div>
      <p><strong>CTP Project Name:</strong> ${feature.properties.MFCE_Name}</p>
    </div>
    `;

    let popupText = `
    <div style="overflow-wrap: break-word;">
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Consolidated Transportation Program (CTP)</h3>
      </div>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>CTP Project Name:</strong> ${feature.properties.MFCE_Name}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>CTP Project ID:</strong> ${feature.properties.MFCE_ID}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>County:</strong> ${feature.properties.County}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>TBU Facility:</strong> ${feature.properties.TBU_Facility}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>CTP Doc URL:</strong> <a href="${feature.properties.CTP_Doc_URL}" target="_blank">${feature.properties.CTP_Doc_URL}</a></p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>Transportation Business Unit:</strong> ${feature.properties.Transportation_Business_Unit}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><strong>District:</strong> ${feature.properties.District}</p>
      <p style="margin-bottom: 0.5rem;margin-top: 0.5rem;"> <a href="https://data-maryland.opendata.arcgis.com/datasets/maryland::mdot-consolidated-transportation-program-ctp-fy2022-fy2027/about" target="_blank">Layer Information</a></p>

    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      // url="https://services8.arcgis.com/.../2020_Protests_with_Location/FeatureServer/0"
      url="https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_CTP_FY2021_to_FY2026_(View)/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      pointToLayer={pointToLayer}
    />
  );
}
