/**
Old deprecated method of downloading data is below return of current version.
**/
import React from "react";
import L, { divIcon } from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

export default function CountyBoundaryLayer(props) {
  const { layerRef, layerIcon } = props;

  const customMarkerIcon = divIcon({
    html: layerIcon,
  });

  const onEachFeature = (feature, layer) => {
    let tooltipText = `
      <div>
        <p><strong>County:</strong> ${feature.properties.COUNTY}</p>
      </div>
      `;
    let popupText = `
      <div>
        <div style="text-align: center;border-bottom: 2.0px solid black;">
          <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
        </div>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
      </div>
      `;
    return layer
      .setStyle({
        color: "#ffa305",
        // fillColor: "#ffa305",
        weight: 2,
        fillOpacity: 0.1,
      })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      url="https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_SHA_County_Boundaries/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      // pointToLayer={pointToLayer}
      onEachFeature={onEachFeature}
    />
  );
}

/**
Old deprecated method is below.
**/
// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { GeoJSON, LayerGroup } from "react-leaflet";
// // import { QueryContext } from "../QueryContext";
// import { useGlobalState, useGlobalUpdater } from "../../common/GlobalContext";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     backgroundColor: "#A9A9A9",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     left: "-0.25rem",
//     top: "-0.25rem",
//     position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #000000",
//   },
// }));

// export default function CountyBoundaryLayer(props) {
//   // const [query, dispatch] = useContext(QueryContext);
//   const query = useGlobalState();
//   const dispatch = useGlobalUpdater();
//   const classes = useStyles();

//   const [featuresData, setFeaturesData] = useState({
//     type: "FeatureCollection",
//     features: [],
//   });

//   useEffect(() => {
//     if (query.countyBoundaryFeatures.features.length === 0) {
//       const fetchMapData = async () => {
//         try {
//           if (featuresData.features.length === 0) {
//             let url =
//               "https://opendata.arcgis.com/datasets/2315ef0b071a4ec59420e3d342dbcfe2_0.geojson";
//             const response = await axios.get(url);

//             dispatch({
//               type: "setCountyBoundaryFeatures",
//               selection: response.data,
//             });

//             setFeaturesData(response.data);
//           }
//         } catch (e) {
//           console.log(e);
//         }
//       };
//       fetchMapData();
//     } else {
//       setFeaturesData(query.countyBoundaryFeatures);
//     }
//   }, []);

//   const onEachFeature = (feature, layer) => {
//     let tooltipText = `
//     <div>
//       <p><strong>County:</strong> ${feature.properties.COUNTY}</p>
//     </div>
//     `;
//     let popupText = `
//     <div>
//       <div style="text-align: center;border-bottom: 2.0px solid black;">
//         <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
//       </div>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
//     </div>
//     `;
//     return layer.bindTooltip(tooltipText).bindPopup(popupText);
//   };

//   return (
//     <LayerGroup>
//       {featuresData.features.length > 0 && (
//         <GeoJSON
//           data={featuresData}
//           onEachFeature={onEachFeature}
//           style={{
//             color: "#ffa305",
//             weight: 2,
//             fillOpacity: 0.1,
//           }}
//         />
//       )}
//     </LayerGroup>
//   );
// }
