import React, { useState, useContext, useEffect } from "react";

import {
  SUMMARY_VARIABLES_HPMS,
  SUMMARY_VARIABLES_TOP100,
  SUMMARY_VARIABLES_ROUTE,
  SUMMARY_VARIABLES_ROUTE_100,
  SUMMARY_VARIABLES_GROUPING,
  SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL,
} from "../common/constants";
// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TableColumnSelector from "./TableColumnSelector";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import MapIcon from "@material-ui/icons/Map";

import ExportFileNameDialog from "../utils/ExportFileNameDialog";
import SaveAlt from "@material-ui/icons/SaveAlt";

import { getCurrentDate } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function SummaryTable(props) {
  const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [grouping, setGrouping] = useState("route_id");
  const [roadLayerType] = useState(query.roadLayerType);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = React.createRef();

  const [tableData, setTableData] = useState(query.features);
  useEffect(() => {
    setTableData(query.features);
  }, [query.features]);

  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  function filterHPMSVariables(category) {
    let results = [];
    let SUMMARY_VARIABLES_layer;
    if (
      // query.roadLayerType === "HPMS"
      query.layerSpecs.category !== "Top 100"
    ) {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_HPMS;
    } else {
      SUMMARY_VARIABLES_layer = SUMMARY_VARIABLES_TOP100;
    }

    for (var i = SUMMARY_VARIABLES_layer.length - 1; i >= 0; i--) {
      if (
        category === SUMMARY_VARIABLES_layer[i].category &&
        SUMMARY_VARIABLES_layer[i].variable !== " commodity_value"
      ) {
        results.push(SUMMARY_VARIABLES_layer[i]);
      }
    }

    return results;
  }

  function buildCols() {
    let cols = [];
    let colVars = [];

    if (
      // query.roadLayerType === "MD100" ||
      // query.roadLayerType === "MD100-Directional"
      query.layerSpecs.category === "Top 100"
    ) {
      if (
        // query.roadLayerType === "MD100"
        query.directionality === "crossSectional"
      )
        colVars = SUMMARY_VARIABLES_ROUTE_100;
      // if (query.roadLayerType === "MD100-Directional")
      else colVars = SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL;
    } else if (grouping === "route_id") {
      colVars = SUMMARY_VARIABLES_ROUTE;
    } else {
      colVars.push(
        SUMMARY_VARIABLES_GROUPING.find((value) => {
          return value.id === grouping;
        })
      );
    }

    for (var i = colVars.length - 1; i >= 0; i--) {
      cols.push(colVars[i]);
    }

    colVars = filterHPMSVariables("all");

    for (var ii = colVars.length - 1; ii >= 0; ii--) {
      cols.push({
        id: colVars[ii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[ii].summaryName,
        tooltip: colVars[ii].tooltip,
        checked: true,
        category: colVars[ii].category,
        normalizedBy:
          typeof colVars[ii].normalizedBy !== "undefined"
            ? colVars[ii].normalizedBy
            : false,
        formatOptions: colVars[ii].formatOptions,
      });
    }

    colVars = filterHPMSVariables("truck");
    for (var iii = colVars.length - 1; iii >= 0; iii--) {
      cols.push({
        id: colVars[iii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[iii].summaryName,
        tooltip: colVars[iii].tooltip,
        checked: true,
        category: colVars[iii].category,
        normalizedBy:
          typeof colVars[iii].normalizedBy !== "undefined"
            ? colVars[iii].normalizedBy
            : false,
        formatOptions: colVars[iii].formatOptions,
      });
    }

    colVars = filterHPMSVariables("other");
    for (var iiii = colVars.length - 1; iiii >= 0; iiii--) {
      cols.push({
        id: colVars[iiii].variable,
        numeric: true,
        disablePadding: false,
        label: colVars[iiii].summaryName,
        tooltip: colVars[iiii].tooltip,
        checked: true,
        category: colVars[iiii].category,
        normalizedBy:
          typeof colVars[iiii].normalizedBy !== "undefined"
            ? colVars[iiii].normalizedBy
            : false,
        formatOptions: colVars[iiii].formatOptions,
      });
    }

    return cols;
  }
  // let columns = buildCols();
  const [columns, setColumns] = useState(query.tableColumnsTop100);
  useEffect(() => {
    let cols = [];
    let colVars = [];

    if (query.layerSpecs.category === "Top 100") {
      setColumns(query.tableColumnsTop100);

      if (query.layerSpecs.layer.split(" ")[0] === "Directional")
        colVars = SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL;
      else colVars = SUMMARY_VARIABLES_ROUTE_100;

      for (var i = colVars.length - 1; i >= 0; i--) {
        cols.push(colVars[i]);
      }

      cols = cols.concat(
        query.tableColumnsTop100.filter((itm) => itm.category !== "route")
      );
    } else {
      if (grouping === "route_id") {
        colVars = SUMMARY_VARIABLES_ROUTE;
      } else {
        colVars.push(
          SUMMARY_VARIABLES_GROUPING.find((value) => {
            return value.id === grouping;
          })
        );
      }

      for (var i = colVars.length - 1; i >= 0; i--) {
        cols.push(colVars[i]);
      }
      cols = cols.concat(
        query.tableColumnsHPMS.filter((itm) => itm.category !== "route")
      );

      // setColumns(query.tableColumnsHPMS)
    }

    setColumns(cols);

    // let columnsTemp = [];
    // if (query.layerSpecs.category === "Top 100")
    //   columnsTemp = query.tableColumnsTop100;
    // else columnsTemp = query.tableColumnsHPMS;
    let tableColumnsTemp = [];
    cols.map(
      (headCell) =>
        headCell.checked &&
        tableColumnsTemp.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    setTableColumns(tableColumnsTemp);
  }, [query.layerSpecs.layer, grouping]);

  function buildColsState(cols, category, state) {
    columns
      .filter((item) => item.category === category)
      .map(
        (col, index) =>
          (cols = {
            ...cols,
            [col.id]: state === "keep" ? columnsState[col.id] : state,
          })
      );

    return cols;
  }

  const [columnsState, setColumnsState] = useState(query.tableColumns);
  useEffect(() => {
    let cols = {};

    cols = buildColsState(cols, "route", true);
    cols = buildColsState(cols, "all", true);
    cols = buildColsState(cols, "truck", false);

    dispatch({
      type: "setTableColumns",
      selection: cols,
    });
    // }, [roadLayerType, grouping]);
  }, [query.layerSpecs.category, grouping]);

  useEffect(() => {
    setColumnsState(query.tableColumns);
  }, [query.tableColumns, grouping]);

  useEffect(() => {
    if (
      // query.roadLayerType === "MD100-Directional"
      query.layerSpecs.category === "Top 100" &&
      query.directionality !== "crossSectional"
    ) {
      let temColState = query.tableColumns;
      temColState.direction = true;
      setColumnsState(temColState);
    }
  }, [
    // query.roadLayerType
    query.layerSpecs.category,
    query.directionality,
  ]);

  function buildColsNew(type) {
    let cols = [];
    let columnsTemp = columns;

    columns.map(
      (headCell) =>
        headCell.checked &&
        cols.push({
          title: headCell.label,
          field: headCell.id,
          cellStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          headerStyle: {
            borderTopWidth: 0,
            borderBottomWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 2,
            borderColor: "rgba(224, 224, 224, 0.5)", //borderColor: 'grey',
            borderStyle: "solid",
          },
          tooltip: headCell.tooltip,
          tooltipStyle: {
            fontSize: 12,
          },
          render: (rowData) =>
            rowData[headCell.id] !== null &&
            typeof rowData[headCell.id] !== "undefined" &&
            rowData[headCell.id] !== "undefined"
              ? rowData[headCell.id].toLocaleString(
                  "en-US",
                  headCell.formatOptions
                )
              : "",
        })
    );

    return cols;
  }

  function aggrigateRow(rawRow) {
    let row = {};

    // for (const column in columnsState) {
    //   let colVars = columns.find((obj) => {
    //     return obj.id === column;
    //   });
    columns.map((colVars, index) => {
      let column = colVars.id;
      // check to see if normalization is needed.
      if (
        // columnsState[column] &&
        colVars.checked &&
        // typeof colVars !== "undefined" &&
        typeof colVars.normalizedBy !== "undefined" &&
        colVars.normalizedBy
      ) {
        let sum = 0;
        let normSum = 0;
        for (var i = rawRow.length - 1; i >= 0; i--) {
          sum += rawRow[i][column] * rawRow[i][colVars.normalizedBy];
          normSum += rawRow[i][colVars.normalizedBy];
        }

        if (normSum === 0) row[column] = 0;
        else row[column] = sum / normSum;
        // standard aggregate.
      } else if (
        // columnsState[column] &&
        colVars.checked &&
        // typeof colVars !== "undefined" &&
        // colVars.numeric &&
        // column !== "beg_point" &&
        // column !== "end_point"
        // colVars.normalizedBy
        typeof colVars.normalizedBy !== "undefined" &&
        !colVars.normalizedBy
      ) {
        row[column] = rawRow.reduce(function (prev, cur) {
          return prev + cur[column];
        }, 0);
        // Text only.
      } else if (colVars.checked) {
        let label = false;
        let col = columns.find((c) => column === c.id);

        if (
          typeof col !== "undefined" &&
          typeof col.queryVariable !== "undefined"
        ) {
          label = query[col.queryVariable].find(
            (value) => value.id == rawRow[0][column]
          );
        }

        if (label) {
          row[column] = label.label;
        } else {
          row[column] = rawRow[0][column];
        }
      }
    });

    return row;
  }

  function buildRow(rawRow) {
    let row = {};
    // for (const column in columnsState) {
    //   if (columnsState[column]) {
    //     if (column === "trkvolper") {
    //       row[column] = (rawRow["trkaadt"] / rawRow["aadt"]) * 100;
    //     } else {
    //       row[column] = rawRow[column];
    //     }
    //   }
    // }
    columns.map((colVars, index) => {
      let column = colVars.id;

      if (colVars.checked) {
        if (column === "trkvolper") {
          row[column] = (rawRow["trkaadt"] / rawRow["aadt"]) * 100;
        } else {
          row[column] = rawRow[column];
        }
      }
    });

    if (
      // query.roadLayerType === "MD100" ||
      // query.roadLayerType === "MD100-Directional"
      query.layerSpecs.category === "Top 100"
    ) {
      if (rawRow.direction) row.id = rawRow.mergekey + "-" + rawRow.direction;
      else row.id = rawRow.mergekey;
    } else {
      row.id = rawRow.id;
    }

    return row;
  }

  function buildRows() {
    let rows = [];
    if (
      // query.roadLayerType !== "MD100" &&
      // query.roadLayerType !== "MD100-Directional"
      query.layerSpecs.category !== "Top 100"
    ) {
      // if (grouping === "total") {
      //   let row = aggrigateRow(tableData);
      //   row.trkvolper = (row.trkaadt / row.aadt) * 100;
      //   rows.push(row);
      // } else {
      let rowsGroup = groupBy(tableData, grouping);
      for (const group in rowsGroup) {
        let row = aggrigateRow(rowsGroup[group]);
        row.segments = rowsGroup[group];
        row.id = row.segments[0].id;
        row.trkvolper = (row.trkaadt / row.aadt) * 100;
        if (grouping === "route_id") {
          row.end_point = row.segments[row.segments.length - 1].end_point;
        }

        rows.push(row);
      }
      // }
    } else {
      let rawRows = tableData;

      for (var i = rawRows.length - 1; i >= 0; i--) {
        rows.push(buildRow(rawRows[i]));
      }
    }

    return rows;
  }
  let rows = buildRows();

  function handleChange(e) {
    setGrouping(e.target.value);
  }

  const isRoadSelected = (selectedRows, feature) => {
    return selectedRows.includes(feature.properties.id);
    // return selectedRows.some(function (rowIndex) {
    //   const road = getRoad(rowIndex);

    //   return (
    //     (feature.ria_rte_id === road.ria_rte_id ||
    //       feature.rte_id === road.ria_rte_id) &&
    //     feature.frm_dfo >= road.frm_dfo &&
    //     feature.to_dfo <= road.to_dfo
    //   );
    // });
  };

  const updateSelectedRows = (srows) => {
    // dispatch({
    //   type: "setHighlightQuery",
    //   query: isRoadSelected.bind(null, srows),
    // });

    dispatch({
      type: "setUserSelectedRoads",
      selection: srows,
    });
  };

  const handleClick = (data) => {
    setSelectedRows(data);
    if (data.length > 0) {
      if (
        // query.roadLayerType === "MD100" ||
        // query.roadLayerType === "MD100-Directional"
        query.layerSpecs.category === "Top 100"
      ) {
        let newSelecteds = data.map((n) => n.id);
        if (query.roadLayerType === "MD100-Directional")
          newSelecteds = data.map((n) => n.id.substring(0, n.id.length - 3));

        updateSelectedRows(newSelecteds);
      } else {
        let segmentIds = [];
        for (var d = data.length - 1; d >= 0; d--) {
          for (var ds = data[d].segments.length - 1; ds >= 0; ds--) {
            segmentIds.push(data[d].segments[ds].id);
          }
        }

        updateSelectedRows(segmentIds);
      }

      return;
    }

    updateSelectedRows([]);
  };
  useEffect(() => {
    setSelectedRows([]);
    updateSelectedRows([]);
  }, [
    // query.roadLayerType
    query.layerSpecs.category,
  ]);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [csvFileNameTest, setCsvFileNameTest] = useState("myTable");
  const [exportCols, setExportCols] = useState();
  const [exportData, setExportData] = useState();

  const TABLE_ICONS = {
    Export: () => <SaveAlt fontSize="large" className={classes.exportButton} />,
  };

  // let tableColumns = buildColsNew("full_table");
  const [tableColumns, setTableColumns] = useState(buildColsNew("full_table"));
  useEffect(() => {
    setTableColumns(buildColsNew("full_table"));
  }, [columns]);

  return (
    <div className={classes.root}>
      {rows.length !== null && (
        <Paper className={classes.paper + " table-wrap"}>
          <MaterialTable
            title="Summary"
            columns={buildColsNew("full_table")}
            // columns={tableColumns}
            // data={rows.map((row) =>
            //   selectedRows.find((selected) => selected.id === row.id)
            //     ? { ...row, tableData: { checked: true } }
            //     : row
            // )}
            data={rows}
            localization={{
              header: {
                actions: "",
              },
            }}
            tableRef={tableRef}
            // options={{
            //   pageSize: 10,
            //   pageSizeOptions: [10, 25, 50, 100],
            //   exportButton: { csv: true },
            //   // selection: grouping === "total" ? false : true,
            //   exportAllData: true,
            // }}
            options={{
              exportButton: true,
              // selection: true,
              exportAllData: true,
              exportMenu: [
                {
                  label: "Export Table",
                  exportFunc: (cols, data) => {
                    setOpenExportDialog(true);

                    let newCols = [
                      {
                        title: "Reporting Year",
                        id: "year",
                        numeric: true,
                        disablePadding: false,
                        label: "Reporting Year",
                        tooltip: (
                          <div
                            style={{
                              fontSize: 12,
                              maxWidth: 400,
                            }}
                          >
                            Reporting Year - data year is one year prior.
                          </div>
                        ),
                        checked: true,
                        category: "reporting",
                      },
                    ];
                    let dataOut = data.map((obj) => {
                      return [query.yearSelected].concat(obj);
                    });
                    setExportCols(newCols.concat(cols));
                    setExportData(dataOut);

                    let filenameTemp = query.layerSpecs.layer
                      .replace(/-/g, "")
                      .replace(/\s+/g, "")
                      .replace(/\(+/g, "-")
                      .replace(/\)+/g, "");

                    setCsvFileNameTest(
                      filenameTemp +
                        "_" +
                        grouping.replace(/_/g, "") +
                        "_" +
                        getCurrentDate()
                    );

                    // setExportCols(cols);
                    // setExportData(data);
                  },
                },
              ],
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],

              // toolbarButtonAlignment:
              //   selectedRows.length > 0 ? "left" : "right", // here is the option to change toolbar buttons' alignment
            }}
            icons={TABLE_ICONS}
            actions={[
              {
                tooltip: "Select Grouping",
                hidden:
                  // query.roadLayerType === "MD100" ||
                  // query.roadLayerType === "MD100-Directional"
                  query.layerSpecs.category === "Top 100",
                icon: () => {
                  return (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="grouping">Group By</InputLabel>
                      <Select
                        labelId="grouping"
                        id="grouping"
                        value={grouping}
                        onChange={handleChange}
                      >
                        <MenuItem value="route_id">Route ID</MenuItem>
                        <MenuItem value="ownership">Ownership</MenuItem>
                        <MenuItem value="f_system">Functional System</MenuItem>
                        <MenuItem value="urban_code">Urban Code</MenuItem>
                        <MenuItem value="nhs">
                          National Highway System (NHS)
                        </MenuItem>
                        <MenuItem value="strahnet">
                          {/* <MenuItem value="strahnet_t"> */}
                          Strategic Highway Network (STRAHNET)
                        </MenuItem>
                        <MenuItem value="truck">
                          National Truck Network (NN)
                        </MenuItem>
                        <MenuItem value="county_cod">County</MenuItem>
                        <MenuItem value="total">Total</MenuItem>
                      </Select>
                    </FormControl>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
              {
                tooltip: "",
                icon: () => {
                  return (
                    <div>
                      <TableColumnSelector
                        columns={columns}
                        setColumns={setColumns}
                        tableType={"summary"}
                      />
                    </div>
                  );
                },
                onClick: () => {},
                isFreeAction: true,
              },
              (rowData) => {
                let index = selectedRows.filter(
                  (item) => item.id === rowData.id
                ).length;

                return {
                  icon:
                    index > 0
                      ? () => (
                          <div style={{ lineHeight: 0 }}>
                            <p style={{ fontSize: 12 }}>Hide</p>
                            <MapIcon style={{ color: "green" }} />
                          </div>
                        )
                      : () => (
                          <div style={{ lineHeight: 0 }}>
                            <p style={{ fontSize: 12 }}>Show</p>
                            <MapIcon style={{ color: "red" }} />
                          </div>
                        ),
                  tooltip: index > 0 ? "Hide Map" : "Show Map",

                  onClick: (event, rowData) => {
                    // let index = selectedRows.filter(
                    //   (item) => item.id === rowData.id
                    // ).length;
                    let tempSelectedRows = [];
                    if (index === 0) {
                      tempSelectedRows = [...selectedRows, rowData];
                    } else {
                      tempSelectedRows = selectedRows.filter(
                        (item) => item.id !== rowData.id
                      );
                    }
                    handleClick(tempSelectedRows);
                  },
                };
              },
            ]}
            // onSelectionChange={(srows) => handleClick(srows)}
            // detailPanel={[
            //   {
            //     tooltip: "Show Detailed Information",
            //     render: (rowData) => {
            //       return (
            //         <div
            //           style={{
            //             fontSize: 100,
            //             textAlign: "center",
            //             color: "white",
            //             backgroundColor: "#43A047",
            //           }}
            //         >
            //           {materialTableDetailPanel(rowData)}
            //         </div>
            //       );
            //     },
            //   },
            // ]}
          />
          <ExportFileNameDialog
            openExportDialog={openExportDialog}
            setOpenExportDialog={setOpenExportDialog}
            csvFileNameTest={csvFileNameTest}
            setCsvFileNameTest={setCsvFileNameTest}
            exportCols={exportCols}
            exportData={exportData}
          />
        </Paper>
      )}
    </div>
  );
}
