/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  Popup,
  ZoomControl,
} from "react-leaflet";

// import Control from "react-leaflet-control";
import {
  COLOR_LINES,
  POPUP_GROUPS,
  SUMMARY_VARIABLES_HPMS,
  // SUMMARY_VARIABLES_TOP100,
  commodity_val_consts,
  yearOptions,
  geographic_options,
} from "../common/constants";
// import { QueryContext } from "./QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import bbox from "@turf/bbox";

import MapFilter from "./MapFilter";

import TollPlazasLayer from "./other_layers/TollPlazasLayer";
import PortsLayer from "./other_layers/PortsLayer";
import OtherTerminalsLayer from "./other_layers/OtherTerminalsLayer";
import IntermodalFacilityLayer from "./other_layers/IntermodalFacilityLayer";
import CargoAirportsLayer from "./other_layers/CargoAirportsLayer";
import AutoTerminalsLayer from "./other_layers/AutoTerminalsLayer";
import DistributionCentersLayer from "./other_layers/DistributionCentersLayer";
import TruckWeighInspStationsLayer from "./other_layers/TruckWeighInspStationsLayer";
import AirportsLayer from "./other_layers/AirportsLayer";
import CountyBoundaryLayer from "./other_layers/CountyBoundaryLayer";
import DistrictBoundaryLayer from "./other_layers/DistrictBoundaryLayer";
import TruckParkingLayer from "./other_layers/TruckParkingLayer";
import Tooltip from "@mui/material/Tooltip";

import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import { TiledMapLayer } from "react-esri-leaflet";

import { QuickInfoControl } from "./QuickInfoControl";

import { latLngBounds, latLng } from "leaflet";

import { mapFocusCategories } from "../common/constants";

import CtpLayer from "./other_layers/CtpLayer";
import CrabLayer from "./other_layers/CrabLayer";
import ShaProjectsLayer from "./other_layers/ShaProjectsLayer";
import LandUse2010 from "./other_layers/LandUse2010";

import { renderToStaticMarkup } from "react-dom/server";
import ConstructionIcon from "@mui/icons-material/Construction";
import FlightIcon from "@mui/icons-material/Flight";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AnchorIcon from "@mui/icons-material/Anchor";
import TollIcon from "@mui/icons-material/Toll";
import LocalParkingIcon from "@mui/icons-material/LocalParking";

import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  linearProgress: {
    marginTop: "5px",
  },
}));

/**
 * Renders the Leaflet map with the HPMS road segments
 *
 * @param {*} props React props
 */
function LeafletMapState(props) {
  const { openDrawer } = props;
  const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  // console.log("renderStateMap-check", query.layerSpecs, query.statsLayerDesc);
  // const [top100Filters] = useState(query.top100Filters);

  const [hpmsGeo, setHpmsGeo] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const [fullHPMSdata, setFullHPMSdata] = useState([]);
  // setGeoJsonKey causes re-rendering of map to include selected segment colors
  const [geoJsonKey, setGeoJsonKey] = useState(Date.now());
  const [hpmsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [info, setInfo] = useState({});
  const hpmsColorContainer = useRef("#ffffff");
  const [checked, setChecked] = useState({
    truck: false,
    directional: false,
  });
  const [map, setMap] = useState(null);

  const lineWeights = { normal: 3, selected: 8, minimized: 1 };
  const hpmsWeightContainer = useRef(lineWeights.normal);

  const handleCheckChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };
  const [directionalSwitch, setDirectionalSwitch] = useState(false);

  function setBounds(fc) {
    let mapBounds_bbox = bbox({ type: "FeatureCollection", features: fc });
    if (mapBounds_bbox[1] !== Infinity)
      setMapBounds([
        [mapBounds_bbox[1], mapBounds_bbox[0]],
        [mapBounds_bbox[3], mapBounds_bbox[2]],
      ]);
    // UpdateMapBounds(map);
  }

  function setCommodityValueDir(f_system, aadt_combi, aadt_singl) {
    let commodityvalue = 0;
    if ((f_system = 1)) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.ihcoton *
          commodity_val_consts.ihval +
          (aadt_singl / 2) *
            commodity_val_consts.ihsiton *
            commodity_val_consts.ihval) *
          365) /
        1000000;
      return commodityvalue;
    }
    if ((f_system = 2)) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.fwycoton *
          commodity_val_consts.fwyval +
          (aadt_singl / 2) *
            commodity_val_consts.fwysiton *
            commodity_val_consts.fwyval) *
          365) /
        1000000;
      return commodityvalue;
    }
    if (f_system > 2) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.pascoton *
          commodity_val_consts.pasval +
          (aadt_singl / 2) *
            commodity_val_consts.passiton *
            commodity_val_consts.pasval) *
          365) /
        1000000;
      return commodityvalue;
    }
  }
  const [featureCount, setFeatureCount] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useMemo(() => {
    setIsLoading(true);
    setFullHPMSdata([]);

    let cntyCounter = 0;
    let countyIds = [];

    const fetchCountyPromise = async (dataUrl, index) => {
      const countyPromise = await axios.get(dataUrl);
      // setLoadingProgress(((index + 1) / countyIds.length) * 100);
      cntyCounter = cntyCounter + 1;
      setLoadingProgress((cntyCounter / countyIds.length) * 100);
      return countyPromise;
    };

    // Set countyIds
    if (query.geoRoute === "hpms/geojson") {
      // Areas

      if (query.geography.region !== "None") {
        // Regions - pull by county

        countyIds = geographic_options.filter(
          (item) =>
            item.text.replace(/\s+/g, "-").toLowerCase() ===
            query.geography.region
        )[0].counties;
        // countyIds = [];
      }
      if (query.geography.county !== "None") {
        // Counties
        countyIds = [query.geography.county];
      }
    } else {
      // Corridors/Roads
      countyIds = [];
    }

    // Set promises = [];
    let promises = [];
    let url = "";
    if (countyIds.length > 0) {
      promises = countyIds.map((r, index) => {
        const dataUrl =
          `${process.env.REACT_APP_API_ROOT}` +
          `/roads/findByCounty?county=` +
          r +
          "&year=" +
          query.yearSelected;
        //  `${process.env.REACT_APP_API_ROOT}/roads/findByCounty?county=${query.geography.county}&year=${query.yearSelected}`;
        return fetchCountyPromise(dataUrl, index);
      });
    } else {
      url = `${process.env.REACT_APP_API_ROOT}/roads/findByCorridor?lookupTable=${query.segments.segmentsTable}&corridor=${query.segments.segmentSelection}&year=${query.yearSelected}`;
      promises = fetchCountyPromise(url, 0);
    }

    // Get promise results
    let promiseResult = [];
    if (countyIds.length > 0) {
      promiseResult = Promise.allSettled(promises);
    } else {
      promiseResult = promises;
    }

    let fTemp = [];

    function setRowData(obj) {
      obj.color = COLOR_LINES.normal;
      obj.weight = lineWeights.normal;
      obj.color_base = obj.color;
      obj.weight_base = obj.weight;
      obj.properties.id = obj.id;

      // obj.properties.seg_length =
      //   obj.properties.end_point - obj.properties.beg_point;
      // obj.properties.lanemiles =
      //   (obj.properties.end_point - obj.properties.beg_point) *
      //   obj.properties.through_la;
      // obj.properties.lanemiles =
      //   obj.properties.seg_length * obj.properties.through_la;
      // obj.properties.trkaadt =
      //   obj.properties.aadt_sgl_u + obj.properties.aadt_combi;
      obj.properties.commodity_value1 = setCommodityValueDir(
        obj.properties.f_system,
        obj.properties.aadt_combi,
        obj.properties.aadt_sgl_u
      );
      obj.properties.commodity_value2 = obj.properties.commodity_value1;
      obj.properties.commodity_value =
        obj.properties.commodity_value1 + obj.properties.commodity_value2;
      // obj.properties.tttrest = Math.max(
      //   obj.properties.ttr95a,
      //   obj.properties.ttr95p,
      //   obj.properties.ttr95o,
      //   obj.properties.ttr95m,
      //   obj.properties.ttr95w
      // );
      // obj.properties.tttrest1 = Math.max(
      //   obj.properties.ttr95a1,
      //   obj.properties.ttr95p1,
      //   obj.properties.ttr95o1,
      //   obj.properties.ttr95m1,
      //   obj.properties.ttr95w1
      // );
      // obj.properties.tttrest2 = Math.max(
      //   obj.properties.ttr95a2,
      //   obj.properties.ttr95p2,
      //   obj.properties.ttr95o2,
      //   obj.properties.ttr95m2,
      //   obj.properties.ttr95w2
      // );
      obj.properties.iri_vmt = obj.properties.iri;
      if (obj.properties.iri === 0) {
        // essentialy removes iri = 0 from weighting
        obj.properties.ttivmt_iri = 0;
        obj.properties.lanemiles_iri = 0;
      } else {
        obj.properties.ttivmt_iri = obj.properties.ttivmt;
        // obj.properties.lanemiles_iri =
        //   (obj.properties.end_point - obj.properties.beg_point) *
        //   obj.properties.through_la;
        obj.properties.lanemiles_iri =
          obj.properties.seg_length * obj.properties.through_la;
      }
      return obj;
    }
    // Process process results
    if (countyIds.length > 0) {
      promiseResult.then((responses) => {
        responses.forEach((response, index) => {
          let f = response.value.data;
          let fc = f.map((row) => {
            return setRowData(row);
          });
          fTemp = fTemp.concat(fc);
        });
        // .catch((err) => console.error(err));

        // console.log("fTemp-check", fTemp);
        setHpmsGeo({ type: "FeatureCollection", features: fTemp });
        // dispatch({
        //   type: "setFeatures",
        //   //   payload: fTemp,
        //   selection: fTemp.map((x) => x.properties),
        // });
        setBounds(fTemp);
        setFullHPMSdata(fTemp);
        setFeatureCount(fTemp.length);
        setIsLoading(false);
      });
    } else {
      promises.then((response) => {
        let f = response.data;
        let fc = f.map((row) => {
          return setRowData(row);
        });
        fTemp = fTemp.concat(fc);
        setHpmsGeo({ type: "FeatureCollection", features: fTemp });
        // dispatch({
        //   type: "setFeatures",
        //   // payload: fTemp,
        //   selection: fTemp.map((x) => x.properties),
        // });
        setBounds(fTemp);
        setFullHPMSdata(fTemp);
        setFeatureCount(fTemp.length);
        setIsLoading(false);
      });
    }

    // let url = "";
    // if (query.geoRoute === "top100/geojson") {
    //   //url = `${process.env.REACT_APP_API_ROOT}/${query.geoRoute}/${props.year}`;
    //   url = `${process.env.REACT_APP_API_ROOT}/roads/findTop100ByYear?year=${query.yearSelected}`;
    // } else {
    //   if (query.geoRoute === "hpms/geojson") {
    //     //url = `${process.env.REACT_APP_API_ROOT}/${query.geoRoute}/${query.geography.region}/${query.geography.county}/${props.year}`;
    //     if (query.geography.region !== "None")
    //       url = `${process.env.REACT_APP_API_ROOT}/roads/findByRegion?region=${query.geography.region}&year=${query.yearSelected}`;
    //     if (query.geography.county !== "None")
    //       url = `${process.env.REACT_APP_API_ROOT}/roads/findByCounty?county=${query.geography.county}&year=${query.yearSelected}`;
    //   } else {
    //     // console.log("props", props);
    //     //url = `${process.env.REACT_APP_API_ROOT}/${query.geoRoute}/${props.segmentsTable}/name=${props.segmentSelection}&year=${props.year}`;
    //     url = `${process.env.REACT_APP_API_ROOT}/roads/findByCorridor?lookupTable=${props.segmentsTable}&corridor=${props.segmentSelection}&year=${query.yearSelected}`;
    //   }
    // }

    // axios
    //   .get(url)
    //   .then((response) => {
    //     let f = response.data.features;
    //     // console.log("f-check", f);
    //     let fc = f.map((obj) => {
    //       obj.color = COLOR_LINES.normal;
    //       obj.weight = lineWeights.normal;
    //       obj.properties.id = obj.id;
    //       if (query.geoRoute === "top100/geojson") {
    //         obj.properties.tttrest = Math.max(
    //           obj.properties.ttr95a,
    //           obj.properties.ttr95p,
    //           obj.properties.ttr95o,
    //           obj.properties.ttr95m,
    //           obj.properties.ttr95w
    //         );
    //         return obj;
    //       }

    //       obj.properties.seg_length =
    //         obj.properties.end_point - obj.properties.beg_point;
    //       obj.properties.lanemiles =
    //         (obj.properties.end_point - obj.properties.beg_point) *
    //         obj.properties.through_la;
    //       obj.properties.trkaadt =
    //         obj.properties.aadt_sgl_u + obj.properties.aadt_combi;
    //       obj.properties.commodity_value1 = setCommodityValueDir(
    //         obj.properties.f_system,
    //         obj.properties.aadt_combi,
    //         obj.properties.aadt_sgl_u
    //       );
    //       obj.properties.commodity_value2 = obj.properties.commodity_value1;
    //       obj.properties.commodity_value =
    //         obj.properties.commodity_value1 + obj.properties.commodity_value2;
    //       obj.properties.tttrest = Math.max(
    //         obj.properties.ttr95a,
    //         obj.properties.ttr95p,
    //         obj.properties.ttr95o,
    //         obj.properties.ttr95m,
    //         obj.properties.ttr95w
    //       );
    //       obj.properties.tttrest1 = Math.max(
    //         obj.properties.ttr95a1,
    //         obj.properties.ttr95p1,
    //         obj.properties.ttr95o1,
    //         obj.properties.ttr95m1,
    //         obj.properties.ttr95w1
    //       );
    //       obj.properties.tttrest2 = Math.max(
    //         obj.properties.ttr95a2,
    //         obj.properties.ttr95p2,
    //         obj.properties.ttr95o2,
    //         obj.properties.ttr95m2,
    //         obj.properties.ttr95w2
    //       );
    //       obj.properties.iri_vmt = obj.properties.iri;
    //       if (obj.properties.iri === 0) {
    //         // essentialy removes iri = 0 from weighting
    //         obj.properties.ttivmt_iri = 0;
    //         obj.properties.lanemiles_iri = 0;
    //       } else {
    //         obj.properties.ttivmt_iri = obj.properties.ttivmt;
    //         obj.properties.lanemiles_iri =
    //           (obj.properties.end_point - obj.properties.beg_point) *
    //           obj.properties.through_la;
    //       }

    //       return obj;
    //     });
    //     // console.log("fc-check", fc);

    //     setHpmsGeo({ type: "FeatureCollection", features: fc });
    //     dispatch({
    //       type: "setFeatures",
    //       payload: fc,
    //     });
    //     setBounds(fc);
    //     setFullHPMSdata(fc);
    //     setFeatureCount(fc.length);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    // }, [props.year, query.geography.region, query.geography.county, query.yearSelected]);
    if (geoJsonLayer) {
      geoJsonLayer.current.clearLayers(); // remove old data
      geoJsonLayer.current.addData(hpmsGeo); // might need to be geojson.features
    }
  }, [query.geography.region, query.geography.county, query.yearSelected]);

  // useEffect(() => {
  //   setChecked({ ...checked, directional: false });
  //   if (query.yearSelected != 2018) {
  //     setDirectionalSwitch(true);
  //   } else {
  //     setDirectionalSwitch(false);
  //   }
  // }, [query.yearSelected]);

  useEffect(() => {
    if (fullHPMSdata.length > 0) {
      setIsLoading(true);

      //let fc_filter2 = fullHPMSdata.filter(row => row.properties.dlyrank === 150);
      let fc_filter2 = fullHPMSdata;

      query.ownershipFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            (row) => row.properties.ownership !== filterValue
          ));
        }
      });
      query.fsystemFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            (row) => row.properties.f_system !== filterValue
          ));
        }
      });
      query.urbanCodeFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            (row) => row.properties.urban_code !== filterValue
          ));
        }
      });
      query.nhsFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            (row) => row.properties.nhs !== filterValue
          ));
        }
      });
      query.strahnetFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            // (row) => row.properties.strahnet_t !== filterValue
            (row) => row.properties.strahnet !== filterValue
          ));
        }
      });
      query.truckNetworkFilters.forEach((filterRow) => {
        let filterValue = parseInt(filterRow.id, 10);
        if (!filterRow.checked) {
          return (fc_filter2 = fc_filter2.filter(
            (row) => row.properties.truck !== filterValue
          ));
        }
      });

      if (fc_filter2.length > 1) {
        if (selectedMapLineCategory !== "none") {
          // console.log("fc_filter2-check", fc_filter2);
          // let tempCategory = "delay";
          let tempCategory = selectedMapLineCategory;

          let maxValue = 10000;
          let minValue = 0;

          if (query.vehicleType === "truck")
            tempCategory = mapLineCategories.filter(
              (itm) => itm.all === selectedMapLineCategory
            )[0].truck;

          if (
            query.directionality === "crossSectional" ||
            selectedMapLineCategory === "iri" ||
            selectedMapLineCategory === "commodity_value"
          ) {
            maxValue = getTopGeojsonN(fc_filter2, tempCategory, 1);
            minValue = getBottomGeojsonN(fc_filter2, tempCategory, 1);
            setMinMaxValues({
              min: minValue,
              max: maxValue,
            });
          } else {
            let maxValue1 = getTopGeojsonN(fc_filter2, tempCategory + "1", 1);
            // let minValue1 = getBottomGeojsonN(
            //   fc_filter2,
            //   tempCategory + "1",
            //   1
            // );
            let minValue1 = getBottomGeojsonNNo0(
              fc_filter2,
              tempCategory + "1",
              1
            );
            // console.log(
            //   "minValueTest-check",
            //   getBottomGeojsonNNo0(fc_filter2, tempCategory + "1", 2)
            // );
            let maxValue2 = getTopGeojsonN(fc_filter2, tempCategory + "2", 1);
            // let minValue2 = getBottomGeojsonN(
            //   fc_filter2,
            //   tempCategory + "2",
            //   1
            // );
            let minValue2 = getBottomGeojsonNNo0(
              fc_filter2,
              tempCategory + "2",
              1
            );
            maxValue = Math.max(maxValue1, maxValue2);
            minValue = Math.min(minValue1, minValue2);

            setMinMaxValues({
              min: Math.min(minValue1, minValue2),
              max: Math.max(maxValue1, maxValue2),
            });
          }
          let increm = (maxValue - minValue) * 0.2;
          let fc = [];
          if (
            query.directionality === "crossSectional" ||
            selectedMapLineCategory === "iri" ||
            selectedMapLineCategory === "commodity_value"
          ) {
            fc = fc_filter2.map((row) => {
              //   row.color = "red";
              row.color = setMapLineColor(
                row.properties[tempCategory],
                maxValue,
                minValue,
                increm
              );

              row.weight = setMapLineWeight(
                row.properties[tempCategory],
                maxValue,
                minValue,
                increm
              );

              row.color_base = row.color;
              row.weight_base = row.weight;
              return row;
            });
          } else {
            fc = fc_filter2.map((row) => {
              //   row.color = "red";
              row.color = setMapLineColor(
                //   row.properties[tempCategory],
                Math.max(
                  row.properties[tempCategory + "1"],
                  row.properties[tempCategory + "2"]
                ),
                maxValue,
                minValue,
                increm
              );

              row.weight = setMapLineWeight(
                row.properties[tempCategory],
                maxValue,
                minValue,
                increm
              );

              row.color_base = row.color;
              row.weight_base = row.weight;

              return row;
            });
          }
          //   console.log("fc-check", fc);
          //setHpmsGeo({ type: "FeatureCollection", features: fc });
          //setGeoJsonKey(Date.now());
        } else {
          let fc = fc_filter2.map((row) => {
            row.color = COLOR_LINES.normal;
            row.weight = lineWeights.normal;

            row.color_base = row.color;
            row.weight_base = row.weight;

            return row;
          });
          //setHpmsGeo({ type: "FeatureCollection", features: fc });
          //setGeoJsonKey(Date.now());
        }
      }
      // dispatch({
      //   type: "setFeatures",
      //   payload: fc_filter2,
      // });

      setHpmsGeo({ type: "FeatureCollection", features: fc_filter2 });
      if (geoJsonLayer.current) {
        geoJsonLayer.current.clearLayers(); // remove old data
        geoJsonLayer.current.addData({
          type: "FeatureCollection",
          features: fc_filter2,
        }); // might need to be geojson.features
      }

      // if (geoJsonLayerRef) {
      //   geoJsonLayerRef.leafletElement
      //     .clearLayers()
      //     .addData({ type: "FeatureCollection", features: fc_filter2 });
      // }

      setFeatureCount(fc_filter2.length);
      if (fc_filter2.length > 0) setBounds(fc_filter2);

      setIsLoading(false);
      // setGeoJsonKey(Date.now());
    }
  }, [
    // query.top100Filters,
    // query.truckTop100Filters,
    query.ownershipFilters,
    query.fsystemFilters,
    query.urbanCodeFilters,
    query.nhsFilters,
    query.strahnetFilters,
    query.truckNetworkFilters,
    query.yearSelected,
    fullHPMSdata,
  ]);

  /* Highlights selected road segments */
  // useEffect(() => {
  //   /* I am cloning the hpms data.
  //    * Is it the best way to refresh the map
  //    * with highlights?
  //    */
  //   const isSelected = query.highlightQuery;
  //   console.log("isSelected-check", isSelected);

  //   if (typeof isSelected === "function") {
  //     hpmsGeo.features
  //       .filter((f) => isSelected(f))
  //       .forEach((f) => {
  //         //   f.color = COLOR_LINES.selected;
  //         //   f.weight = lineWeights.selected;
  //         f.weight = f.weight_base;
  //       });
  //     hpmsGeo.features
  //       .filter((f) => !isSelected(f))
  //       .forEach((f) => {
  //         //   f.color = COLOR_LINES.normal;
  //         //   f.weight = lineWeights.normal;
  //         f.weight = 0.5;
  //       });

  //     //        setBounds(fc);
  //     if (hpmsGeo.features.length > 0) {
  //       if (hpmsGeo.features.filter((f) => isSelected(f)).length > 0) {
  //         setBounds(hpmsGeo.features.filter((f) => isSelected(f)));
  //       } else {
  //         setBounds(hpmsGeo.features);
  //       }
  //     }
  //     setGeoJsonKey(Date.now());
  //   }
  // }, [query.highlightQuery]);

  const getHPMSStyle = (feature) => {
    return { color: feature.color, weight: feature.weight };
  };

  /* Highlights on mouseOver */
  const handleMouseOver = (feature, e) => {
    const layer = e.target;
    hpmsColorContainer.current = feature.color;
    hpmsWeightContainer.current = feature.weight;
    feature.color = COLOR_LINES.selected;
    feature.weight = lineWeights.selected;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.7,
    });

    setInfo(feature.properties);
  };

  /* Reset default style on mouseOut */
  const handleMouseOut = (feature, e) => {
    const layer = e.target;
    feature.color = hpmsColorContainer.current;
    feature.weight = hpmsWeightContainer.current;
    layer.setStyle({
      weight: feature.weight,
      color: feature.color,
      dashArray: "",
      fillOpacity: 0.7,
    });
  };

  const handleClickSegment = (feature, layer, e) => {
    // setPopupTitle(
    //   `Road: ${feature.properties.route_id} (${feature.properties.beg_point} to ${feature.properties.end_point})`
    // );
    // setPopupTitle(
    //   <div>
    //     <p className="custom-block-title">
    //       Road ID: {feature.properties.route_id}
    //     </p>
    //     <p className="custom-block-title">
    //       {feature.properties.beg_point} to {feature.properties.end_point}
    //     </p>
    //   </div>
    // );
    let fromTo = `${feature.properties.beg_point} to ${feature.properties.end_point}`;
    setPopupTitle(
      <div>
        <p className="left-text" style={{ marginBottom: 1 }}>
          <strong>Route ID:</strong> {feature.properties.route_id}
        </p>
        <p className="left-text" style={{ marginTop: 1 }}>
          <strong>From/To:</strong> {fromTo}
        </p>
      </div>
    );
  };

  /* Tracks user road selection process*/
  const defaultUserSelection = {
    from_route_id: "",
    from_beg_point: Number.Nan,
    from_end_point: Number.NaN,
    to_beg_point: Number.NaN,
    to_end_point: Number.NaN,
  };

  const userSelection = useRef(defaultUserSelection);

  /* Resets the previous user selection highlights */
  const resetUserSelection = (route_id) => {
    hpmsGeo.features
      .filter((f) => f.properties.route_id === route_id)
      .forEach((f) => {
        // f.color = COLOR_LINES.normal;
        // f.weight = lineWeights.normal;
        f.color = f.color_base;
        f.weight = f.weight_base;
      });
    userSelection.current = defaultUserSelection;
  };

  const highlightUserSelection = () => {
    const selection = userSelection.current;

    hpmsGeo.features
      .filter((f) => f.properties.route_id === selection.from_route_id)
      .forEach((f) => {
        // f.color = COLOR_LINES.selected;
        // f.weight = lineWeights.normal;
        f.weight = 7;
      });

    hpmsGeo.features
      .filter((f) => f.properties.route_id !== selection.from_route_id)
      .forEach((f) => {
        // f.color = COLOR_LINES.normal;
        // f.weight = lineWeights.minimized;

        f.weight = 0.1;
      });

    if (!isNaN(selection.to_end_point)) {
      // console.log("end-check-good");
      const fromDfo = Math.min(
        selection.from_beg_point,
        selection.to_beg_point
      );
      const toDfo = Math.max(selection.from_end_point, selection.to_end_point);
      hpmsGeo.features
        .filter(
          (f) =>
            f.properties.route_id === selection.from_route_id &&
            f.properties.beg_point >= fromDfo &&
            f.properties.end_point <= toDfo
        )
        .forEach((f) => {
          // f.color = COLOR_LINES.selectedSection;
          f.weight = 7;
        });
    } else {
      /* if toHere is not selected */
      //   hpmsGeo.features.filter(
      //     (f) =>
      //       f.properties.route_id === selection.from_route_id &&
      //       f.properties.beg_point === selection.from_beg_point
      //   )[0].color = COLOR_LINES.selectedSection;
      hpmsGeo.features
        .filter(
          (f) =>
            f.properties.route_id === selection.from_route_id &&
            f.properties.beg_point === selection.from_beg_point
        )
        .forEach((f) => {
          // f.color = COLOR_LINES.selectedSection;
          f.weight = 7;
        });
    }

    setGeoJsonKey(Date.now());
  };

  function handleAddSegment() {
    // console.log("popuRef-check", popupRef);
    // let featureProps =
    //   popupRef.current._source.feature.properties;
    let featureProps = popupRef.current._source.feature.properties;

    if (
      query.geoRoute === "hpms/geojson" ||
      query.geoRoute === "hpms_segments/geojson"
    ) {
      dispatch({
        type: "setUserSelection",
        selection: {
          route_id: featureProps.route_id,
          beg_point: featureProps.beg_point,
          end_point: featureProps.end_point,
        },
      });

      dispatch({
        type: "setUserSelectionMD100",
        selection: {
          route_id: "",
          beg_point: NaN,
          end_point: NaN,
        },
      });

      dispatch({
        type: "setUserSelectedSegments",
        selection: query.userSelectedSegments.concat({
          route_id: featureProps.route_id,
          beg_point: featureProps.beg_point,
          end_point: featureProps.end_point,
          mergekey:
            featureProps.route_id +
            "_" +
            featureProps.beg_point +
            "_" +
            featureProps.end_point,
        }),
      });
    } else {
      dispatch({
        type: "setUserSelection",
        selection: {
          route_id: "",
          beg_point: NaN,
          end_point: NaN,
        },
      });

      dispatch({
        type: "setUserSelectionMD100",
        selection: {
          route_id: featureProps.seg_name,
          beg_point: featureProps.seg_from,
          end_point: featureProps.seg_to,
        },
      });
    }

    popupRef.current.remove(); //closes the popup
  }

  function handleFrom() {
    let featureProps = popupRef.current._source.feature.properties;

    resetUserSelection(userSelection.current.from_route_id);
    userSelection.current = {
      from_route_id: featureProps.route_id,
      from_beg_point: featureProps.beg_point,
      from_end_point: featureProps.end_point,
    };

    highlightUserSelection();

    popupRef.current.remove(); //closes the popup
  }

  function handleTo() {
    let featureProps = popupRef.current._source.feature.properties;

    let newSelection = { ...userSelection.current };
    newSelection.to_beg_point = featureProps.beg_point;
    newSelection.to_end_point = featureProps.end_point;
    userSelection.current = newSelection;

    const fromDfo = Math.min(
      newSelection.from_beg_point,
      newSelection.to_beg_point
    );

    const toDfo = Math.max(
      newSelection.from_end_point,
      newSelection.to_end_point
    );

    dispatch({
      type: "setUserSelection",
      selection: {
        route_id: newSelection.from_route_id,
        beg_point: fromDfo,
        end_point: toDfo,
      },
    });

    dispatch({
      type: "setUserSelectionMD100",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: NaN,
      },
    });

    dispatch({
      type: "setUserSelectedSegments",
      selection: query.userSelectedSegments.concat({
        route_id: newSelection.from_route_id,
        beg_point: fromDfo,
        end_point: toDfo,
        mergekey: newSelection.from_route_id + "_" + fromDfo + "_" + toDfo,
      }),
    });

    highlightUserSelection();

    const selection = userSelection.current;

    // hpmsGeo.features
    //   .filter((f) => f.properties.route_id === selection.from_route_id)
    //   .forEach((f) => (f.color = COLOR_LINES.normal));

    hpmsGeo.features.forEach((f) => {
      //   f.color = COLOR_LINES.normal;
      //   f.weight = lineWeights.normal;
      f.color = f.color_base;
      f.weight = f.weight_base;
    });

    popupRef.current.remove(); //closes the popup

    setGeoJsonKey(Date.now());
  }

  function handleClearSelection() {
    let featureProps = popupRef.current._source.feature.properties;

    const selection = userSelection.current;

    // hpmsGeo.features
    //   .filter((f) => f.properties.route_id === selection.from_route_id)
    //   .forEach((f) => {
    //     f.color = COLOR_LINES.normal;
    //     f.weight = lineWeights.normal;
    //   });
    hpmsGeo.features
      // .filter((f) => f.properties.route_id === selection.from_route_id)
      .forEach((f) => {
        // f.color = COLOR_LINES.normal;
        // f.weight = lineWeights.normal;
        f.color = f.color_base;
        f.weight = f.weight_base;
      });

    popupRef.current.remove(); //closes the popup

    setGeoJsonKey(Date.now());
  }

  /* @see See https://jsfiddle.net/thbh99nu/2/ */
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: handleMouseOver.bind(null, feature),
      mouseout: handleMouseOut.bind(null, feature),
      click: handleClickSegment.bind(null, feature, layer),
    });
  };

  /* have to pass a unique key to Map to re-render leaflet map
   * when the road data is changed. Since the road data
   * changed based on regionId and year, this pass the combination of both
   * as a key. This is a bug in react-leaflet.
   * See https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
   */
  const { BaseLayer, Overlay } = LayersControl;
  const [mapBounds, setMapBounds] = useState(props.bounds);
  // console.log("mapBounds-check", mapBounds);
  const [mapZoom, setMapZoom] = useState(props.Zoom);

  function returnHPMSVariable(name) {
    for (var i = SUMMARY_VARIABLES_HPMS.length - 1; i >= 0; i--) {
      if (
        name === SUMMARY_VARIABLES_HPMS[i].variable ||
        name.substring(0, name.length - 1) ===
          SUMMARY_VARIABLES_HPMS[i].variable
      ) {
        return SUMMARY_VARIABLES_HPMS[i];
      }
    }
  }

  function PopupData(props) {
    return (
      <div>
        <ul>
          {POPUP_GROUPS[props.type].map((value, index) => {
            const field = returnHPMSVariable(value);
            return (
              <li key={index}>
                {field.summaryName}:{" "}
                {info[value] === null
                  ? ""
                  : info[value].toLocaleString("en-US", field.formatOptions)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  let toFrom = "";
  let routeId = "";
  if (typeof info.route_id !== "undefined") {
    toFrom = `${info.beg_point.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })} to ${info.end_point.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })}`;
    routeId = info.route_id;
  } else if (typeof info.seg_name !== "undefined") {
    toFrom = `${info.seg_from} to ${info.seg_to}`;
    routeId = info.seg_name;
  }

  const popupRef = useRef(false);

  window.popupRef = popupRef;
  function handlePopupOpen(a, b, c) {
    let featureProps = popupRef.current._source.feature.properties;
    // console.log("featureProps-check", featureProps);
    if (
      query.geoRoute === "hpms/geojson" ||
      query.geoRoute === "hpms_segments/geojson"
    ) {
      setPopupTitle(
        `Road: ${
          featureProps.route_id
        } (${featureProps.beg_point.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })} to ${featureProps.end_point.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        })})`
      );
    } else {
      setPopupTitle(
        `Road: ${featureProps.seg_name} (${featureProps.seg_from} to ${featureProps.seg_to})`
      );
    }

    //   let showToHere =
    //     userSelection.current.from_ria_rte_id === featureProps.ria_rte_id;
    //   setShowToHereButton(showToHere);
  }
  const [popupTitle, setPopupTitle] = useState("untitled");

  const geoJsonLayer = useRef(null);
  const [geoJsonLayerRef, setGeoJsonLayerRef] = useState(null);

  useEffect(() => {
    // if (geoJsonLayer.current) {
    //   geoJsonLayer.current.leafletElement.clearLayers().addData(hpmsGeo);
    // }
    // if (geoJsonLayerRef) {
    //   geoJsonLayerRef.current.clearLayers(); // remove old data
    //   geoJsonLayerRef.leafletElement.clearLayers().addData(hpmsGeo);
    // }
    //geoJsonLayer
    if (geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers(); // remove old data
      geoJsonLayer.current.addData(hpmsGeo); // might need to be geojson.features
    }

    let fTempProperties = [];
    let tempData = hpmsGeo.features;
    tempData.map((x) => fTempProperties.push(x.properties));

    dispatch({
      type: "setFeatures",
      //   payload: fTemp,
      // selection: fTemp.map((x) => x.properties),
      selection: fTempProperties,
    });
  }, [hpmsGeo]);

  const layerRef = useRef();
  // layerRef.current.on("tileload", (e) => {
  //   console.log("The underlying leaflet tileload event is:", e);
  // });

  // Classes used by Leaflet to position controls
  const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
  };

  //   function QuickInfoControl({ position }) {
  //     const positionClass =
  //       (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  //     return (
  //       <div className={positionClass}>
  //         <div className="leaflet-control leaflet-bar">
  //           <div className={"details " + props.mode}>
  //             {typeof info.route_id === "undefined" &&
  //               typeof info.seg_name === "undefined" && (
  //                 <Typography variant="subtitle1">
  //                   Hover over a road to see data
  //                 </Typography>
  //               )}
  //             {!(
  //               typeof info.route_id === "undefined" &&
  //               typeof info.seg_name === "undefined"
  //             ) && (
  //               <div>
  //                 <FormGroup row>
  //                   <FormControlLabel
  //                     control={
  //                       <Switch
  //                         checked={checked.truck}
  //                         onChange={handleCheckChange}
  //                         name="truck"
  //                         inputProps={{
  //                           title: "Show Truck",
  //                           "aria-label": "toggle truck data",
  //                         }}
  //                       />
  //                     }
  //                     label={<Icon>local_shipping</Icon>}
  //                     labelPlacement="start"
  //                   />
  //                   {(query.geoRoute === "hpms/geojson" ||
  //                     query.geoRoute === "hpms_segments/geojson") && (
  //                     <Tooltip title="Show directional information">
  //                       <FormControlLabel
  //                         control={
  //                           <Switch
  //                             checked={checked.directional}
  //                             onChange={handleCheckChange}
  //                             name="directional"
  //                             inputProps={{
  //                               title: "Show Directional",
  //                               "aria-label": "toggle directional data",
  //                             }}
  //                             disabled={directionalSwitch}
  //                           />
  //                         }
  //                         label={<Icon>swap_vert</Icon>}
  //                         labelPlacement="start"
  //                       />
  //                     </Tooltip>
  //                   )}
  //                 </FormGroup>

  //                 <p className="left-text">
  //                   <strong>Year:</strong> {query.yearSelected}
  //                 </p>
  //                 {checked.directional === false &&
  //                   checked.truck === false &&
  //                   (query.geoRoute === "hpms/geojson" ||
  //                     query.geoRoute === "hpms_segments/geojson") && (
  //                     // All veh combined data.
  //                     <PopupData type="all" />
  //                   )}
  //                 {checked.truck === false &&
  //                   query.geoRoute === "top100/geojson" && (
  //                     // All veh combined data.
  //                     <PopupData type="top100" />
  //                   )}
  //                 {checked.directional === false &&
  //                   checked.truck === true &&
  //                   (query.geoRoute === "hpms/geojson" ||
  //                     query.geoRoute === "hpms_segments/geojson") && (
  //                     // Truck combined data.
  //                     <PopupData type="truck" />
  //                   )}
  //                 {checked.truck === true && query.geoRoute === "top100/geojson" && (
  //                   // Truck combined data.
  //                   <PopupData type="top100truck" />
  //                 )}
  //                 {checked.directional === true && checked.truck === false && (
  //                   // Directional all veh data.
  //                   <div className="directional">
  //                     <div className="direction-1">
  //                       <div className="direction-title">
  //                         <strong>{info.direction1}</strong>
  //                       </div>
  //                       <PopupData type="all1" />
  //                     </div>
  //                     <div className="direction-2">
  //                       <div className="direction-title">
  //                         <strong>{info.direction2}</strong>
  //                       </div>
  //                       <PopupData type="all2" />
  //                     </div>
  //                   </div>
  //                 )}
  //                 {checked.directional === true && checked.truck === true && (
  //                   // Directional truck data.
  //                   <div className="directional">
  //                     <div className="direction-1">
  //                       <div className="direction-title">
  //                         <strong>{info.direction1}</strong>
  //                       </div>
  //                       <PopupData type="truck1" />
  //                     </div>
  //                     <div className="direction-2">
  //                       <div className="direction-title">
  //                         <strong>{info.direction2}</strong>
  //                       </div>
  //                       <PopupData type="truck2" />
  //                     </div>
  //                   </div>
  //                 )}
  //                 <p className="left-text">
  //                   <strong>Route ID:</strong> {routeId}
  //                 </p>
  //                 <p>
  //                   <strong>From/To:</strong> {toFrom}
  //                 </p>
  //                 <div>Click segments to add to data table</div>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  useEffect(() => {
    // console.log("map-check", map);
    // if (map) homeButton.click();
    // map.fitBounds(mapBounds);
    if (map) {
      // console.log("map.fitBounds-check", mapBounds);
      map.fitBounds(mapBounds);
    }
  }, [mapBounds]);
  function MapResetControl({ position, map }) {
    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    const onClick = useCallback(() => {
      // map.setView(center, zoom);
      map.fitBounds(mapBounds);
    }, [map]);

    return (
      <div className={positionClass}>
        <div className="resetmapcontrol">
          <div
            className="leaflet-control leaflet-bar"
            style={{ borderStyle: "none" }}
          >
            <IconButton
              className="home-button"
              color="inherit"
              aria-label="reset map"
              title="Reset Map"
              edge="start"
              // onClick={() => setMapZoom(mapZoom - 1)}
              onClick={onClick}
            >
              <Icon>home</Icon>
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  // const mapLineCategoriesTop100 = [
  //   { description: "None", all: "none", truck: "none" },
  //   { description: "Ranking", all: "dlyrank", truck: "trkdlyrank" },
  //   { description: "Delay/mile", all: "dlymile", truck: "trkdlymile" },
  //   { description: "TTI", all: "tti", truck: "trktti" },
  //   { description: "PTI 95", all: "pti95", truck: "trkpti95" },
  //   { description: "TTTR - Est.", all: "tttrest", truck: "tttrest" },
  //   { description: "IRI", all: "iri", truck: "iri" },
  //   {
  //     description: "Commodity Value",
  //     all: "commodity_value",
  //     truck: "commodity_value",
  //   },
  // ];
  const mapLineCategories = [
    { description: "None", all: "none", truck: "none" },
    { description: "Delay", all: "delay", truck: "trkdelay" },
    { description: "TTI", all: "tti", truck: "trktti" },
    { description: "PTI 95", all: "pti95", truck: "trkpti95" },
    { description: "TTTR - Est.", all: "tttrest", truck: "tttrest" },
    { description: "IRI", all: "iri", truck: "iri" },
    {
      description: "Commodity Value",
      all: "commodity_value",
      truck: "commodity_value",
    },
    {
      description: "Congested CO2 (Tons)",
      all: "ghg",
      truck: "ghgtrk",
    },
    {
      description: "Normal CO2 (Tons)",
      all: "ghgbase",
      truck: "ghgtrkbase",
    },
  ];
  const [selectedMapLineCategory, setSelectedMapLineCategory] =
    useState("none");
  useMemo(() => {
    // console.log(
    //   "mapFocus-test-check",
    //   query.mapFocus,
    //   mapFocusCategories.filter(
    //     (itm) =>
    //       itm.category === query.geoRoute && itm.description === query.mapFocus
    //   )[0].all
    // );
    setSelectedMapLineCategory(
      mapFocusCategories.filter(
        (itm) =>
          itm.category === query.geoRoute && itm.description === query.mapFocus
      )[0].all
    );
  }, [query.mapFocus]);

  function getTopJsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone.sort((a, b) => b[prop] - a[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getBottomJsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone.sort((a, b) => a[prop] - b[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getBottomJsonNNo0(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.filter((itm) => itm[prop] > 0).slice(0);

    let selectedRow = clone.sort((a, b) => a[prop] - b[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getTopGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => b.properties[prop] - a.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
    // return selectedRow[0];
  }

  function getBottomGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => a.properties[prop] - b.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
  }
  function getBottomGeojsonNNo0(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.filter((itm) => itm.properties[prop] > 0).slice(0);

    let selectedRow = clone
      .sort((a, b) => a.properties[prop] - b.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
  }

  const [minMaxValues, setMinMaxValues] = useState({
    min: 10000000,
    max: 0,
  });
  useEffect(() => {
    // console.log("query.userSelectedRoads-check", query.userSelectedRoads);
    // console.log("checked.directional-check", checked.directional);
    if (hpmsGeo.features.length > 0) {
      // setSelectedMapLineCategory("delay");
      if (selectedMapLineCategory !== "none") {
        // console.log("hpmsGeo.features-check", hpmsGeo.features);
        // let tempCategory = "delay";
        let tempCategory = selectedMapLineCategory;

        let maxValue = 10000;
        let minValue = 0;

        if (query.vehicleType === "truck")
          tempCategory = mapLineCategories.filter(
            (itm) => itm.all === selectedMapLineCategory
          )[0].truck;

        if (
          query.directionality === "crossSectional" ||
          selectedMapLineCategory === "iri" ||
          selectedMapLineCategory === "commodity_value"
        ) {
          maxValue = getTopGeojsonN(hpmsGeo.features, tempCategory, 1);
          minValue = getBottomGeojsonN(hpmsGeo.features, tempCategory, 1);
          setMinMaxValues({
            min: minValue,
            max: maxValue,
          });
        } else {
          let maxValue1 = getTopGeojsonN(
            hpmsGeo.features,
            tempCategory + "1",
            1
          );
          // let minValue1 = getBottomGeojsonN(
          //   hpmsGeo.features,
          //   tempCategory + "1",
          //   1
          // );
          let minValue1 = getBottomGeojsonNNo0(
            hpmsGeo.features,
            tempCategory + "1",
            1
          );
          // console.log(
          //   "minValueTest-check",
          //   getBottomGeojsonNNo0(hpmsGeo.features, tempCategory + "1", 2)
          // );
          let maxValue2 = getTopGeojsonN(
            hpmsGeo.features,
            tempCategory + "2",
            1
          );
          // let minValue2 = getBottomGeojsonN(
          //   hpmsGeo.features,
          //   tempCategory + "2",
          //   1
          // );
          let minValue2 = getBottomGeojsonNNo0(
            hpmsGeo.features,
            tempCategory + "2",
            1
          );
          maxValue = Math.max(maxValue1, maxValue2);
          minValue = Math.min(minValue1, minValue2);

          setMinMaxValues({
            min: Math.min(minValue1, minValue2),
            max: Math.max(maxValue1, maxValue2),
          });
        }
        let increm = (maxValue - minValue) * 0.2;
        let fc = [];
        if (
          query.directionality === "crossSectional" ||
          selectedMapLineCategory === "iri" ||
          selectedMapLineCategory === "commodity_value"
        ) {
          fc = hpmsGeo.features.map((row) => {
            //   row.color = "red";
            row.color = setMapLineColor(
              row.properties[tempCategory],
              maxValue,
              minValue,
              increm
            );

            row.weight = setMapLineWeight(
              row.properties[tempCategory],
              maxValue,
              minValue,
              increm
            );

            row.color_base = row.color;
            row.weight_base = row.weight;
            return row;
          });
        } else {
          fc = hpmsGeo.features.map((row) => {
            //   row.color = "red";
            row.color = setMapLineColor(
              //   row.properties[tempCategory],
              Math.max(
                row.properties[tempCategory + "1"],
                row.properties[tempCategory + "2"]
              ),
              maxValue,
              minValue,
              increm
            );

            row.weight = setMapLineWeight(
              row.properties[tempCategory],
              maxValue,
              minValue,
              increm
            );

            row.color_base = row.color;
            row.weight_base = row.weight;

            return row;
          });
        }
        //   console.log("fc-check", fc);
        setHpmsGeo({ type: "FeatureCollection", features: fc });
        setGeoJsonKey(Date.now());
      } else {
        let fc = hpmsGeo.features.map((row) => {
          row.color = COLOR_LINES.normal;
          row.weight = lineWeights.normal;

          row.color_base = row.color;
          row.weight_base = row.weight;

          return row;
        });
        setHpmsGeo({ type: "FeatureCollection", features: fc });
        setGeoJsonKey(Date.now());
      }
    }
  }, [
    // hpmsGeo,
    // hpmsGeo.features,
    query.geoRoute,
    query.vehicleType,
    selectedMapLineCategory,
    // query.userSelectedRoads,
    query.directionality,
  ]);
  useEffect(() => {
    if (query.userSelectedRoads.length > 0) {
      hpmsGeo.features
        .filter((f) => query.userSelectedRoads.indexOf(f.id) === -1)
        .forEach((f) => {
          // f.color = COLOR_LINES.selectedSection;
          f.weight = 0.1;
        });
      hpmsGeo.features
        .filter((f) => query.userSelectedRoads.indexOf(f.id) > -1)
        .forEach((f) => {
          // f.color = COLOR_LINES.selectedSection;
          f.weight = f.weight_base * 2;
        });
      // console.log(
      //   "userSelectedRoads-check",
      //   hpmsGeo.features.filter(
      //     (f) => query.userSelectedRoads.indexOf(f.id) > -1
      //   )
      // );
      setBounds(
        hpmsGeo.features.filter(
          (f) => query.userSelectedRoads.indexOf(f.id) > -1
        )
      );

      setGeoJsonKey(Date.now());
    } else {
      hpmsGeo.features.forEach((f) => {
        // f.color = COLOR_LINES.selectedSection;
        f.weight = f.weight_base;
      });
      setBounds(hpmsGeo.features);

      setGeoJsonKey(Date.now());
    }
  }, [query.userSelectedRoads]);

  function setMapLineColor(value, maxValue, minValue, increm) {
    if (value >= minValue + increm * 4) return "red";
    if (value >= minValue + increm * 3 && value < minValue + increm * 4)
      return "orange";
    if (value >= minValue + increm * 2 && value < minValue + increm * 3)
      return "yellow";
    if (value >= minValue + increm * 1 && value < minValue + increm * 2)
      return "blue";
    return "green";
  }
  function setMapLineWeight(value, maxValue, minValue, increm) {
    // if (value >= minValue + increm * 4) return 5;
    // if (value >= minValue + increm * 3 && value < minValue + increm * 4)
    //   return 4;
    // if (value >= minValue + increm * 2 && value < minValue + increm * 3)
    //   return 3;
    // if (value >= minValue + increm * 1 && value < minValue + increm * 2)
    //   return 2;
    // return 1;
    if (value >= minValue + increm * 4) return 6;
    if (value >= minValue + increm * 3 && value < minValue + increm * 4)
      return 4.8;
    if (value >= minValue + increm * 2 && value < minValue + increm * 3)
      return 3.6;
    if (value >= minValue + increm * 1 && value < minValue + increm * 2)
      return 2.4;
    return 1.5;
  }

  useEffect(() => {
    // console.log("openDrawer-check", props.openDrawer);

    // if (map) map.fitBounds(mapBounds);
    // if (openDrawer === false) console.log("map center:", map.getCenter());
    // if (openDrawer === false) return <SetViewOnClick bounds={mapBounds} />;
    // if (openDrawer === false) map.setView(map.getCenter(), map.getZoom() - 1);

    if (map)
      setTimeout(function () {
        map.invalidateSize();
      }, 185);
  }, [openDrawer]);

  const crabLayerRef = useRef();
  const ctpLayerRef = useRef();
  const ShaProjectsLayerRef = useRef();
  const LandUse2010LayerRef = useRef();

  const layerIcons = {
    ctp: renderToStaticMarkup(
      <ConstructionIcon
        style={{
          fontSize: 15,
          color: "#0047AB",
        }}
      />
    ),
    shaProjects: renderToStaticMarkup(
      <ConstructionIcon
        style={{
          fontSize: 15,
          color: "#FFC300",
        }}
      />
    ),
    airports: renderToStaticMarkup(
      <FlightIcon
        style={{
          fontSize: 16,
          color: "#FFFFFF",
          backgroundColor: "#A9A9A9",
        }}
      />
    ),
    truckWeighInspStations: renderToStaticMarkup(
      <LocalShippingIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#CBC3E3",
        }}
      />
    ),
    distributionCenters: renderToStaticMarkup(
      <BusinessIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#90ee90",
        }}
      />
    ),
    autoTerminals: renderToStaticMarkup(
      <DirectionsCarIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#add8e6",
        }}
      />
    ),
    cargoAirports: renderToStaticMarkup(
      <FlightIcon
        style={{
          fontSize: 16,
          color: "#FFFF00",
          backgroundColor: "#000000",
        }}
      />
    ),
    intermodalFacilities: renderToStaticMarkup(
      <Icon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#11b461",
        }}
      />
    ),
    otherTerminals: renderToStaticMarkup(
      <Icon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#FFFF00",
        }}
      />
    ),
    ports: renderToStaticMarkup(
      <AnchorIcon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#0000ff",
        }}
      />
    ),
    tollPlazas: renderToStaticMarkup(
      <TollIcon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#ff7800",
        }}
      />
    ),
    fhwaTruckParking: renderToStaticMarkup(
      <LocalParkingIcon
        style={{
          fontSize: 16,
          color: "#000000",
          backgroundColor: "#add8e6",
        }}
      />
    ),
    crabLayer: renderToStaticMarkup(
      <Stack>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "rgb(255,255,0,150)",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          0 to 1 Foot CRAB Inundated
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#008000",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          1 to 2 Foot CRAB Inundated
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#800080",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Greater than 2 Foot CRAB Inundated
        </div>
      </Stack>
    ),
    landUse2010: renderToStaticMarkup(
      <Stack>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFEBAF",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Very Low Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFFF00",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Low Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFAA00",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Medium Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#732600",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          High Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FF0000",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Commercial
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#A900E6",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Industrial
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#005CE6",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Institutional
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#9C9C9C",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Other Developed Lands
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#D3FFBE",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Agriculture
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#38A800",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Forest
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#9EBBD7",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Water
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#97DBF2",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Wetlands
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#D7C29E",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Barren Land
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#4E4E4E",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Transportation
        </div>
      </Stack>
    ),
  };
  const landUse2010Features = [
    {
      Description: "Very Low Density Residential",
      backgroundColor: "#FFEBAF",
      layerRef: useRef(),
    },
    {
      Description: "Low Density Residential",
      backgroundColor: "#FFFF00",
      layerRef: useRef(),
    },
    {
      Description: "Medium Density Residential",
      backgroundColor: "#FFAA00",
      layerRef: useRef(),
    },
    {
      Description: "High Density Residential",
      backgroundColor: "#732600",
      layerRef: useRef(),
    },
    {
      Description: "Commercial",
      backgroundColor: "#FF0000",
      layerRef: useRef(),
    },
    {
      Description: "Industrial",
      backgroundColor: "#A900E6",
      layerRef: useRef(),
    },
    {
      Description: "Institutional",
      backgroundColor: "#005CE6",
      layerRef: useRef(),
    },
    {
      Description: "Other Developed Lands",
      backgroundColor: "#9C9C9C",
      layerRef: useRef(),
    },
    {
      Description: "Agriculture",
      backgroundColor: "#D3FFBE",
      layerRef: useRef(),
    },
    { Description: "Forest", backgroundColor: "#38A800", layerRef: useRef() },
    { Description: "Water", backgroundColor: "#9EBBD7", layerRef: useRef() },
    {
      Description: "Wetlands",
      backgroundColor: "#9EBBD7",
      layerRef: useRef(),
    },
    {
      Description: "Barren Land",
      backgroundColor: "#D7C29E",
      layerRef: useRef(),
    },
    {
      Description: "Transportation",
      backgroundColor: "#4E4E4E",
      layerRef: useRef(),
    },
  ];

  return (
    <MapContainer
      bounds={mapBounds}
      zoom={mapZoom}
      minZoom={8}
      attributionContol={true}
      scrollWheelZoom={true}
      ref={setMap}
      zoomControl={false}
    >
      {/* <Map
        bounds={mapBounds}
        zoom={mapZoom}
        minZoom={8}
        attributionContol={true}
        scrollWheelZoom={true}
      > */}

      <LoadingOverlay
        // active={isLoading}
        active={fullHPMSdata.length > 0 ? false : true}
        spinner={
          <div>
            <BounceLoader />
            <LinearProgress
              variant="determinate"
              value={loadingProgress}
              className={classes.linearProgress}
            />
          </div>
        }
        text={
          query.statsLayerDesc
            ? "Loading stats layer..."
            : "Please select a layer from the menu on the left..."
        }
        // styles={{
        //   overlay: (base) => ({
        //     ...base,
        //     height: "650px",
        //   }),
        // }}
      >
        <ZoomControl position="topright" />
        <MapResetControl position="topright" map={map} />

        <LayersControl position="topright">
          <BaseLayer checked name="Streets" key={"mapbox.streets"}>
            <TileLayer
              url={process.env.REACT_APP_MAPBOX_STREETS_V10_URL}
              attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
              id="mapbox.streets"
              access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            />
          </BaseLayer>
          <BaseLayer name="Satellite">
            <TileLayer
              url={process.env.REACT_APP_MAPBOX_URL}
              attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
              id="mapbox/satellite-v9"
              access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            />
          </BaseLayer>
          <BaseLayer name="Satellite-Streets">
            <TileLayer
              url={process.env.REACT_APP_MAPBOX_URL}
              attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
              id="mapbox/satellite-streets-v11"
              access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            />
          </BaseLayer>
          <BaseLayer name="Light">
            <TileLayer
              url={process.env.REACT_APP_MAPBOX_URL}
              attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
              id="mapbox/light-v10"
              access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            />
          </BaseLayer>
          <BaseLayer name="Dark">
            <TileLayer
              url={process.env.REACT_APP_MAPBOX_URL}
              attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
              id="mapbox/dark-v10"
              access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
            />
          </BaseLayer>
          {hpmsGeo.features.length > 0 && (
            <Overlay checked name="Roads" style={{ zIndex: 500 }}>
              <GeoJSON
                data={hpmsGeo}
                style={getHPMSStyle}
                onEachFeature={onEachFeature}
                ref={geoJsonLayer}
                whenCreated={setGeoJsonLayerRef}
              >
                <Popup
                  ref={popupRef}
                  // onOpen={handlePopupOpen}
                >
                  <div className="custom-block">
                    {/* <p className="custom-block-title">
                      <strong>Layer ID:</strong> {query.layerSpecs.layer}
                    </p> */}
                    {/* <p className="custom-block-title">{popupTitle}</p> */}
                    {popupTitle}
                    <div>
                      <p>
                        To add road segment to the data table, click{" "}
                        <strong>Add segment</strong> .
                      </p>
                      <p className="from-to-links">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleAddSegment}
                        >
                          Add segment
                        </button>
                      </p>
                    </div>
                    {(query.geoRoute === "hpms/geojson" ||
                      query.geoRoute === "hpms_segments/geojson") && (
                      <div>
                        <div>
                          <p> To add a corridor to the data table:</p>
                          <ol>
                            <li>
                              Click <strong>From Here</strong> on first segment
                            </li>
                            <li>
                              Click <strong>To Here</strong> on final segment to
                              add corridor to data table.
                            </li>
                          </ol>
                        </div>
                        <p className="from-to-links">
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={handleFrom}
                          >
                            From here
                          </button>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={handleTo}
                          >
                            To here
                          </button>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={handleClearSelection}
                          >
                            Clear selection
                          </button>
                        </p>
                      </div>
                    )}
                  </div>
                </Popup>
              </GeoJSON>
            </Overlay>
          )}
          <Overlay
            name="Freight Crashes 2018 - Tiled (ArcGIS Layer)"
            style={{ zIndex: 500 }}
          >
            <TiledMapLayer
              ref={layerRef}
              url="https://tiles.arcgis.com/tiles/njFNhDsUCentVYJW/arcgis/rest/services/Freight_Crashes_2018_Tiled/MapServer"
            />
          </Overlay>

          <Overlay
            name={"FHWA Truck Parking 2019 " + layerIcons.fhwaTruckParking}
            style={{ zIndex: 500 }}
          >
            <TruckParkingLayer layerIcon={layerIcons.fhwaTruckParking} />
          </Overlay>
          <Overlay
            name={"Toll Plazas " + layerIcons.tollPlazas}
            style={{ zIndex: 499 }}
          >
            <TollPlazasLayer layerIcon={layerIcons.tollPlazas} />
          </Overlay>

          <Overlay name={"Ports " + layerIcons.ports} style={{ zIndex: 498 }}>
            <PortsLayer layerIcon={layerIcons.ports} />
          </Overlay>
          <Overlay
            name={"Other Terminals " + layerIcons.otherTerminals}
            style={{ zIndex: 497 }}
          >
            <OtherTerminalsLayer layerIcon={layerIcons.otherTerminals} />
          </Overlay>
          <Overlay
            name={"Intermodal Facilities " + layerIcons.intermodalFacilities}
            style={{ zIndex: 496 }}
          >
            <IntermodalFacilityLayer
              layerIcon={layerIcons.intermodalFacilities}
            />
          </Overlay>
          <Overlay
            name={"Cargo Airports " + layerIcons.cargoAirports}
            style={{ zIndex: 495 }}
          >
            <CargoAirportsLayer layerIcon={layerIcons.cargoAirports} />
          </Overlay>
          <Overlay
            name={"Auto Terminals " + layerIcons.autoTerminals}
            style={{ zIndex: 494 }}
          >
            <AutoTerminalsLayer layerIcon={layerIcons.autoTerminals} />
          </Overlay>
          <Overlay
            // name="Distribution Centers"
            // name={layerIcons.distributionCenters + " Distribution Centers"}
            name={"Distribution Centers " + layerIcons.distributionCenters}
            style={{ zIndex: 493 }}
          >
            <DistributionCentersLayer
              layerIcon={layerIcons.distributionCenters}
            />
          </Overlay>
          <Overlay
            name={
              "Truck Weigh/Inspection Stations " +
              layerIcons.truckWeighInspStations
            }
            style={{ zIndex: 492 }}
          >
            <TruckWeighInspStationsLayer
              layerIcon={layerIcons.truckWeighInspStations}
            />
          </Overlay>
          <Overlay
            // name="Airports"
            name={"Airports " + layerIcons.airports}
            style={{ zIndex: 491 }}
          >
            <AirportsLayer layerIcon={layerIcons.airports} />
          </Overlay>
          <Overlay name="MDOT SHA County Boundaries" style={{ zIndex: 590 }}>
            <CountyBoundaryLayer />
          </Overlay>
          <Overlay name="MDOT SHA District Boundaries" style={{ zIndex: 590 }}>
            <DistrictBoundaryLayer />
          </Overlay>
          <Overlay
            name={
              "Climate Ready Action Boundary (CRAB) Inundated Zones" +
              layerIcons.crabLayer
            }
            style={{ zIndex: 590 }}
          >
            <CrabLayer layerRef={crabLayerRef} mapRef={setMap} />
          </Overlay>
          <Overlay
            // name="Consolidated Transportation Program (CTP) FY2022 - FY2027"
            name={
              "Consolidated Transportation Program (CTP) FY2022 - FY2027 " +
              layerIcons.ctp
            }
            style={{ zIndex: 590 }}
          >
            <CtpLayer layerRef={ctpLayerRef} iconMarkup={layerIcons.ctp} />
          </Overlay>
          <Overlay
            // name="Consolidated Transportation Program (CTP) FY2022 - FY2027"
            name={"MDOT SHA Projects " + layerIcons.shaProjects}
            style={{ zIndex: 590 }}
          >
            <ShaProjectsLayer
              layerRef={ShaProjectsLayerRef}
              iconMarkup={layerIcons.shaProjects}
            />
          </Overlay>
          <Overlay
            // name={"Land Use 2010" + layerIcons.landUse2010}
            name={"Land Use 2010"}
            style={{ zIndex: 590 }}
          >
            <LandUse2010 layerRef={LandUse2010LayerRef} />
          </Overlay>
          {landUse2010Features.map((item, index) => (
            <Overlay
              name={
                renderToStaticMarkup(
                  <Icon
                    style={{
                      fontSize: 16,
                      color: "#000000",
                      backgroundColor: item.backgroundColor,
                      borderRadius: 50,
                      transform: [{ scaleX: 2 }],
                      // marginLeft: 25,
                      marginRight: 10,
                      opacity: 0.5,
                      border: "1px solid",
                      borderColor: "primary.main",
                    }}
                  />
                ) +
                "Land Use 2010 - " +
                item.Description
              }
              style={{ zIndex: 590 }}
              key={"lu2010_" + index}
            >
              <LandUse2010
                layerRef={item.layerRef}
                luDescription={item.Description}
              />
            </Overlay>
          ))}
        </LayersControl>
      </LoadingOverlay>
      <QuickInfoControl
        position="bottomleft"
        geoRoute={query.geoRoute}
        // mapLineCategoriesTop100={mapLineCategoriesTop100}
        mapLineCategories={mapLineCategories}
        selectedMapLineCategory={selectedMapLineCategory}
        setSelectedMapLineCategory={setSelectedMapLineCategory}
        checked={checked}
        setChecked={setChecked}
        minMaxValues={minMaxValues}
        info={info}
        routeId={routeId}
        toFrom={toFrom}
        PopupData={PopupData}
      />
      {/* <Control position="bottomleft">
        <div className={"details " + props.mode}>
          {typeof info.route_id === "undefined" &&
            typeof info.seg_name === "undefined" && (
              <Typography variant="subtitle1">
                Hover over a road to see data
              </Typography>
            )}
          {!(
            typeof info.route_id === "undefined" &&
            typeof info.seg_name === "undefined"
          ) && (
            <div>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked.truck}
                      onChange={handleCheckChange}
                      name="truck"
                      inputProps={{
                        title: "Show Truck",
                        "aria-label": "toggle truck data",
                      }}
                    />
                  }
                  label={<Icon>local_shipping</Icon>}
                  labelPlacement="start"
                />
                {(query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  <Tooltip title="Show directional information - only available for 2018 at this time.">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked.directional}
                          onChange={handleCheckChange}
                          name="directional"
                          inputProps={{
                            title: "Show Directional",
                            "aria-label": "toggle directional data",
                          }}
                          disabled={directionalSwitch}
                        />
                      }
                      label={<Icon>swap_vert</Icon>}
                      labelPlacement="start"
                    />
                  </Tooltip>
                )}
              </FormGroup>

              <p className="left-text">
                
                <strong>Year:</strong> {query.yearSelected}
              </p>
              {checked.directional === false &&
                checked.truck === false &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // All veh combined data.
                  <PopupData type="all" />
                )}
              {checked.truck === false && query.geoRoute === "top100/geojson" && (
                // All veh combined data.
                <PopupData type="top100" />
              )}
              {checked.directional === false &&
                checked.truck === true &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // Truck combined data.
                  <PopupData type="truck" />
                )}
              {checked.truck === true && query.geoRoute === "top100/geojson" && (
                // Truck combined data.
                <PopupData type="top100truck" />
              )}
              {checked.directional === true && checked.truck === false && (
                // Directional all veh data.
                <div className="directional">
                  <div className="direction-1">
                    <div className="direction-title">
                      <strong>{info.direction1}</strong>
                    </div>
                    <PopupData type="all1" />
                  </div>
                  <div className="direction-2">
                    <div className="direction-title">
                      <strong>{info.direction2}</strong>
                    </div>
                    <PopupData type="all2" />
                  </div>
                </div>
              )}
              {checked.directional === true && checked.truck === true && (
                // Directional truck data.
                <div className="directional">
                  <div className="direction-1">
                    <div className="direction-title">
                      <strong>{info.direction1}</strong>
                    </div>
                    <PopupData type="truck1" />
                  </div>
                  <div className="direction-2">
                    <div className="direction-title">
                      <strong>{info.direction2}</strong>
                    </div>
                    <PopupData type="truck2" />
                  </div>
                </div>
              )}
              <p className="left-text">
                <strong>Route ID:</strong> {routeId}
              </p>
              <p>
                <strong>From/To:</strong> {toFrom}
              </p>
              <div>Click segments to add to data table</div>
            </div>
          )}
        </div>
      </Control> */}
      {/* <Control position="topleft">
        <IconButton
          className="home-button"
          color="inherit"
          aria-label="reset map"
          title="Reset Map"
          edge="start"
          onClick={() => setMapZoom(mapZoom - 1)}
        >
          <Icon>home</Icon>
        </IconButton>
      </Control> */}
      {/* <FilterControl position="topright" /> */}
      {/* <Control position="topright">
        <MapFilter
          layerID={props.layerID}
          yearFilters={yearOptions}
          top100Filters={top100Filters}
          truckTop100Filters={query.truckTop100Filters}
          ownershipFilters={query.ownershipFilters}
          fsystemFilters={query.fsystemFilters}
          urbanCodeFilters={query.urbanCodeFilters}
          nhsFilters={query.nhsFilters}
          strahnetFilters={query.strahnetFilters}
          truckNetworkFilters={query.truckNetworkFilters}
          featureCount={featureCount}
        />
      </Control> */}
      {/* </Map> */}
    </MapContainer>
  );
}

export default LeafletMapState;
