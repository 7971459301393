export const vehicleTypes = [
  {
    text: "All Vehicles",
    value: "all",
  },
  {
    text: "Truck Only",
    value: "truck",
  },
];
export const directionalityOptions = [
  {
    text: "Cross Sectional",
    value: "crossSectional",
  },
  {
    text: "Directional",
    value: "directional",
    disabled: true,
  },
];
export const mapFocusCategories = [
  {
    category: "top100/geojson",
    description: "None",
    all: "none",
    truck: "none",
  },
  {
    category: "top100/geojson",
    description: "Ranking",
    all: "dlyrank",
    truck: "trkdlyrank",
  },
  {
    category: "top100/geojson",
    description: "Delay/mile",
    all: "dlymile",
    truck: "trkdlymile",
  },
  {
    category: "top100/geojson",
    description: "TTI",
    all: "tti",
    truck: "trktti",
  },
  {
    category: "top100/geojson",
    description: "PTI 95",
    all: "pti95",
    truck: "trkpti95",
  },
  {
    category: "top100/geojson",
    description: "TTTR - Est.",
    all: "tttrest",
    truck: "tttrest",
  },
  { category: "top100/geojson", description: "IRI", all: "iri", truck: "iri" },
  {
    category: "top100/geojson",
    description: "Commodity Value",
    all: "commodity_value",
    truck: "commodity_value",
  },
  { category: "hpms/geojson", description: "None", all: "none", truck: "none" },
  {
    category: "hpms/geojson",
    description: "Delay",
    all: "delay",
    truck: "trkdelay",
  },
  { category: "hpms/geojson", description: "TTI", all: "tti", truck: "trktti" },
  {
    category: "hpms/geojson",
    description: "PTI 95",
    all: "pti95",
    truck: "trkpti95",
  },
  {
    category: "hpms/geojson",
    description: "TTTR - Est.",
    all: "tttrest",
    truck: "tttrest",
  },
  { category: "hpms/geojson", description: "IRI", all: "iri", truck: "iri" },
  {
    category: "hpms/geojson",
    description: "Commodity Value",
    all: "commodity_value",
    truck: "commodity_value",
  },
  {
    category: "hpms_segments/geojson",
    description: "None",
    all: "none",
    truck: "none",
  },
  {
    category: "hpms_segments/geojson",
    description: "Delay",
    all: "delay",
    truck: "trkdelay",
  },
  {
    category: "hpms_segments/geojson",
    description: "TTI",
    all: "tti",
    truck: "trktti",
  },
  {
    category: "hpms_segments/geojson",
    description: "PTI 95",
    all: "pti95",
    truck: "trkpti95",
  },
  {
    category: "hpms_segments/geojson",
    description: "TTTR - Est.",
    all: "tttrest",
    truck: "tttrest",
  },
  {
    category: "hpms_segments/geojson",
    description: "IRI",
    all: "iri",
    truck: "iri",
  },
  {
    category: "hpms_segments/geojson",
    description: "Commodity Value",
    all: "commodity_value",
    truck: "commodity_value",
  },
  {
    category: "top100/geojson",
    description: "Congested CO2 (lbs)",
    all: "dayghg",
    truck: "dayghgtrk",
  },
  {
    category: "top100/geojson",
    description: "Normal CO2 (lbs)",
    all: "dayghgbase",
    truck: "dayghgba_1",
  },
  {
    category: "hpms/geojson",
    description: "Congested CO2 (Tons)",
    all: "ghg",
    truck: "ghgtrk",
  },
  {
    category: "hpms/geojson",
    description: "Normal CO2 (Tons)",
    all: "ghgbase",
    truck: "ghgtrkbase",
  },
];

/* Colors from the "paired" and "accent" color scheme
 * at https://vega.github.io/vega/docs/schemes/#categorical.
 * normal: paired[0] light blue
 * selected: paired[1] dark blue
 * selectedSection: accent[2] light orange
 */
export const COLOR_LINES = {
  normal: "#981e32",
  notSelected: "",
  // selected: "#ffc838",
  // selected: "#4727b2",
  // selected: "#013220", // dark green
  selected: "#ff00ff",
  // selectedSection: "#b28c27",
  // selectedSection: "#013220", // dark green
  selectedSection: "#ff00ff",
};

// Note: segmentsTable and selection only used for geoRoute = 'hpms_segments/geojson'
export const geographicChoices = [
  {
    category: "Top 100",
    text: "Top 100 Monitored Roads",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Top 100",
    text: "Top 100 All-Vehicle Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Top 100",
    text: "Top 100 Truck Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Top 100",
    text: "Directional Top 100 All-Vehicle Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Top 100",
    text: "Directional Top 100 Truck Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Region",
    text: "DC Metro Area",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Frederick", "Montgomery", "Prince-Georges"],
  },
  {
    category: "Region",
    text: "Baltimore Area",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Baltimore", "Baltimore-City"],
  },
  {
    category: "Region",
    text: "Western",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Washington", "Allegany", "Garrett"],
  },
  {
    category: "Region",
    text: "Central",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Anne-Arundel",
      "Baltimore",
      "Baltimore-City",
      "Carroll",
      "Harford",
      "Howard",
    ],
  },
  {
    category: "Region",
    text: "Southern",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Calvert", "Charles", "St-Marys"],
  },
  {
    category: "Region",
    text: "Eastern Shore",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Caroline",
      "Cecil",
      "Dorchester",
      "Kent",
      "Queen-Annes",
      "Somerset",
      "Talbot",
      "Wicomico",
      "Worchester",
    ],
  },
  {
    category: "Region",
    text: "Statewide",
    icon: "all_inclusive",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Allegany",
      "Anne-Arundel",
      "Baltimore",
      "Baltimore-City",
      "Calvert",
      "Caroline",
      "Carroll",
      "Cecil",
      "Charles",
      "Dorchester",
      "Frederick",
      "Garrett",
      "Harford",
      "Howard",
      "Kent",
      "Montgomery",
      "Prince-Georges",
      "Queen-Annes",
      "Somerset",
      "St-Marys",
      "Talbot",
      "Washington",
      "Wicomico",
      "Worchester",
    ],
  },
  {
    category: "County",
    text: "Allegany",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Anne Arundel",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Baltimore City",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Baltimore",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Calvert",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Caroline",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Carroll",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Cecil",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Charles",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Dorchester",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Frederick",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Garrett",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Harford",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Howard",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Kent",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Montgomery",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Prince George's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Queen Anne's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Somerset",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "St. Mary's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Talbot",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Washington",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Wicomico",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "County",
    text: "Worcester",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    category: "Critical Corridors",
    text: "Maryland Critical Rural Corridors",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "sha_critical_rural_freight_corridors",
    segmentSelection: "all",
  },
  {
    category: "Critical Corridors",
    text: "Maryland Critical Urban Corridors",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "sha_critical_urban_freight_corridors",
    segmentSelection: "all",
  },
  {
    category: "Critical Corridors",
    text: "Maryland Freight Network (including critical corridors)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "freight_network_analysis_routes",
    segmentSelection: "all",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-70 (Pennsylvania State Line to US 40 (Frederick)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-70__Pennsylvania_State_Line_to_US_40__Frederick_",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-70 (US 40 (Frederick) to I-695)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-70__US_40__Frederick__to_I-695_",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-81",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-81",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-83",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-83",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-95 (I-495 to I-695)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95__I-495_to_I-695_",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-95 (I-695) to Delaware State Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95__I-695__to_Delaware_State_Line_",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-97",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-97",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-495",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-695",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-795",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-795",
  },
  {
    category: "Other Freeway Corridors",
    text: "I-895",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895",
  },
  {
    category: "Other Freeway Corridors",
    text: "US 50",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50",
  },
  {
    category: "Other Freeway Corridors",
    text: "MD 32 (MD 108 to I-97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_32__MD_108_to_I-97_",
  },
  {
    category: "Other Freeway Corridors",
    text: "MD 100 (US 29 to MD 177)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_100__US_29_to_MD_177__",
  },
  {
    category: "Other Freeway Corridors",
    text: "MD 200 (I-370 to US 1)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_200__I-370_to_US_1_",
  },
  {
    category: "Other Freeway Corridors",
    text: "MD 295 (MD 201 to Waterview Avenue)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_295__MD_201_to_Waterview_Avenue_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 295 (MD 201 to Waterview Avenue)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_295__MD_201_to_Waterview_Avenue_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 2 (US 50/301 to MD 1o)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_2__US_50_301_to_MD_1o_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 3 (US 50/301 to I-97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_3__US_50_301_to_I-97_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 4 (DC line to Dower House Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_4__DC_line_to_Dower_House_Road_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 5 (I-95 to DC line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_5__I-95_to_DC_line_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 5 (US 301 to MD 223)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_5__US_301_to_MD_223_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 24 (US 40 to US 1)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_24__US_40_to_US_1_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 26 (MD 32 to Baltimore City Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_26__MD_32_to_Baltimore_City_Line_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 28 (Riffle Ford Road to MD 97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_28__Riffle_Ford_Road_to_MD_97_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 32 (MD 108 to MD 26)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_32__MD_108_to_MD_26_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 43 (I-695 to US 40)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_43__I-695_to_US_40_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 45 (Baltimore City Line to Shawan Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_45__Baltimore_City_Line_to_Shawan_Road_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 85 (English Muffin Way to I-70)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_85__English_Muffin_Way_to_I-70_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 97 (DC Line to MD 108)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_97__DC_Line_to_MD_108_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 124 (MD 28 to MD 108)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_124__MD_28_to_MD_108_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 140 (MD 97 to Baltimore City Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_140__MD_97_to_Baltimore_City_Line_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 175 (MD 32 to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_175__MD_32_to_US_29_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 185 (DC Line to MD 97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_185__DC_Line_to_MD_97_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 193 (MD 201 to MD 650)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_193__MD_201_to_MD_650_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 197 (US 301 to MD 450)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_197__US_301_to_MD_450_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 201 (MD 450 to MD 212)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_201__MD_450_to_MD_212_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 210 (MD 228 to I-95)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_210__MD_228_to_I-95_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 228 (MD 201 to US 301)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_228__MD_201_to_US_301_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 355 (DC Line to MD 27)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_355__DC_Line_to_MD_27_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 410 (MD 355 to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_410__MD_355_to_US_29_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 410 (MD 650 to Pennsy Drive)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_410__MD_650_to_Pennsy_Drive_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 450 (MD 202 to MD 704?)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_450__MD_202_to_MD_704?_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 450 (Housely Road to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_450__Housely_Road_to_MD_2_",
  },
  {
    category: "Other Arterial Corridors",
    text: "MD 650 (DC Line to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_650__DC_Line_to_US_29_",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 1 (MD 410 to MD 198)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_1__MD_410_to_MD_198_",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 1 (Baltimore City Line to Honeygo Boulevard)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_1__Baltimore_City_Line_to_Honeygo_Boulevard_",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 29 (MD 97 to MD 650)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_29__MD_97_to_MD_650_",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 29 (Industrial Parkway to MD 198",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_29__Industrial_Parkway_to_MD_198",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 40 (I-70 to Cleveland Avenue) ",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_40__I-70_to_Cleveland_Avenue__",
  },
  {
    category: "Other Arterial Corridors",
    text: "US 301 (Billingsley Road to MD 5)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_301__Billingsley_Road_to_MD_5_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-895 Northbound (I-695 to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895_Northbound__I-695_to_MD_2_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-270 West Spur Southbound (I-270 Split to I-495)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-270_West_Spur_Southbound__I-270_Split_to_I-495_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-695 Outer Loop (MD 170 to MD 10)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__MD_170_to_MD_10_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-495 Inner Loop (MD 414 to I-295)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_414_to_I-295_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-495 Outer Loop (MD 187 to MD 190)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Outer_Loop__MD_187_to_MD_190_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-495 Inner Loop (MD 187 to MD 185)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_187_to_MD_185_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-895 Southbound (Moravia Road to Holabird Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895_Southbound__Moravia_Road_to_Holabird_Road_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-95 Northbound (I-695 to MD 295)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95_Northbound__I-695_to_MD_295_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-695 Outer Loop (I-95 to 147)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__I-95_to_147_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-695 Outer Loop (MD 129 to MD 26)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__MD_129_to_MD_26_",
  },
  {
    category: "Other Freight Corridors",
    text: "US 50 Eastbound (MD 665 to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50_Eastbound__MD_665_to_MD_2_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-495 Inner Loop (MD 212 to Greenbelt METRO Center)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_212_to_Greenbelt_METRO_Center_",
  },
  {
    category: "Other Freight Corridors",
    text: "US 50 Eastbound (Buschs Frontage Road to Bay Bridge)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50_Eastbound__Buschs_Frontage_Road_to_Bay_Bridge_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-95 Southbound (I-895 to O’Donnell Street)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95_Southbound__I-895_to_ODonnell_Street_",
  },
  {
    category: "Other Freight Corridors",
    text: "I-695 Inner Loop (MD 129 to I-83)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Inner_Loop__MD_129_to_I-83_",
  },
];
export const geographic_options_top100 = [
  {
    text: "Top 100 Monitored Roads",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Top 100 All-Vehicle Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Top 100 Truck Bottlenecks",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "top100/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
];

export const geographic_options = [
  {
    text: "DC Metro Area",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Frederick", "Montgomery", "Prince-Georges"],
  },
  {
    text: "Baltimore Area",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Baltimore", "Baltimore-City"],
  },
  {
    text: "Western",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Washington", "Allegany", "Garrett"],
  },
  {
    text: "Central",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Anne-Arundel",
      "Baltimore",
      "Baltimore-City",
      "Carroll",
      "Harford",
      "Howard",
    ],
  },
  {
    text: "Southern",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: ["Calvert", "Charles", "St-Marys"],
  },
  {
    text: "Eastern Shore",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Caroline",
      "Cecil",
      "Dorchester",
      "Kent",
      "Queen-Annes",
      "Somerset",
      "Talbot",
      "Wicomico",
      "Worchester",
    ],
  },
  {
    text: "Statewide",
    icon: "all_inclusive",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
    counties: [
      "Allegany",
      "Anne-Arundel",
      "Baltimore",
      "Baltimore-City",
      "Calvert",
      "Caroline",
      "Carroll",
      "Cecil",
      "Charles",
      "Dorchester",
      "Frederick",
      "Garrett",
      "Harford",
      "Howard",
      "Kent",
      "Montgomery",
      "Prince-Georges",
      "Queen-Annes",
      "Somerset",
      "St-Marys",
      "Talbot",
      "Washington",
      "Wicomico",
      "Worchester",
    ],
  },
];

export const geographic_options_counties = [
  {
    text: "Allegany",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Anne Arundel",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Baltimore City",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Baltimore",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Calvert",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Caroline",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Carroll",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Cecil",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Charles",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Dorchester",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Frederick",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Garrett",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Harford",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Howard",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Kent",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Montgomery",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Prince George's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Queen Anne's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Somerset",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "St. Mary's",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Talbot",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Washington",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Wicomico",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
  {
    text: "Worcester",
    icon: "location_city",
    active: true,
    geoRoute: "hpms/geojson",
    segmentsTable: "roads",
    segmentSelection: "all",
  },
];

export const geographic_options_critical_corridors = [
  {
    text: "Maryland Critical Rural Corridors",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "sha_critical_rural_freight_corridors",
    segmentSelection: "all",
  },
  {
    text: "Maryland Critical Urban Corridors",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "sha_critical_urban_freight_corridors",
    segmentSelection: "all",
  },
  {
    text: "Maryland Freight Network (including critical corridors)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "freight_network_analysis_routes",
    segmentSelection: "all",
  },
];

export const geographic_options_other_fway_corridors = [
  {
    text: "I-70 (Pennsylvania State Line to US 40 (Frederick)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-70__Pennsylvania_State_Line_to_US_40__Frederick_",
  },
  {
    text: "I-70 (US 40 (Frederick) to I-695)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-70__US_40__Frederick__to_I-695_",
  },
  {
    text: "I-81",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-81",
  },
  {
    text: "I-83",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-83",
  },
  {
    text: "I-95 (I-495 to I-695)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95__I-495_to_I-695_",
  },
  {
    text: "I-95 (I-695) to Delaware State Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95__I-695__to_Delaware_State_Line_",
  },
  {
    text: "I-97",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-97",
  },
  {
    text: "I-495",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495",
  },
  {
    text: "I-695",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695",
  },
  {
    text: "I-795",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-795",
  },
  {
    text: "I-895",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895",
  },
  {
    text: "US 50",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50",
  },
  {
    text: "MD 32 (MD 108 to I-97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_32__MD_108_to_I-97_",
  },
  {
    text: "MD 100 (US 29 to MD 177)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_100__US_29_to_MD_177__",
  },
  {
    text: "MD 200 (I-370 to US 1)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_200__I-370_to_US_1_",
  },
  {
    text: "MD 295 (MD 201 to Waterview Avenue)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_295__MD_201_to_Waterview_Avenue_",
  },
];

export const geographic_options_other_art_corridors = [
  {
    text: "MD 295 (MD 201 to Waterview Avenue)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_295__MD_201_to_Waterview_Avenue_",
  },
  {
    text: "MD 2 (US 50/301 to MD 1o)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_2__US_50_301_to_MD_1o_",
  },
  {
    text: "MD 3 (US 50/301 to I-97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_3__US_50_301_to_I-97_",
  },
  {
    text: "MD 4 (DC line to Dower House Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_4__DC_line_to_Dower_House_Road_",
  },
  {
    text: "MD 5 (I-95 to DC line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_5__I-95_to_DC_line_",
  },
  {
    text: "MD 5 (US 301 to MD 223)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_5__US_301_to_MD_223_",
  },
  {
    text: "MD 24 (US 40 to US 1)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_24__US_40_to_US_1_",
  },
  {
    text: "MD 26 (MD 32 to Baltimore City Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_26__MD_32_to_Baltimore_City_Line_",
  },
  {
    text: "MD 28 (Riffle Ford Road to MD 97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_28__Riffle_Ford_Road_to_MD_97_",
  },
  {
    text: "MD 32 (MD 108 to MD 26)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_32__MD_108_to_MD_26_",
  },
  {
    text: "MD 43 (I-695 to US 40)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_43__I-695_to_US_40_",
  },
  {
    text: "MD 45 (Baltimore City Line to Shawan Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_45__Baltimore_City_Line_to_Shawan_Road_",
  },
  {
    text: "MD 85 (English Muffin Way to I-70)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_85__English_Muffin_Way_to_I-70_",
  },
  {
    text: "MD 97 (DC Line to MD 108)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_97__DC_Line_to_MD_108_",
  },
  {
    text: "MD 124 (MD 28 to MD 108)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_124__MD_28_to_MD_108_",
  },
  {
    text: "MD 140 (MD 97 to Baltimore City Line)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_140__MD_97_to_Baltimore_City_Line_",
  },
  {
    text: "MD 175 (MD 32 to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_175__MD_32_to_US_29_",
  },
  {
    text: "MD 185 (DC Line to MD 97)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_185__DC_Line_to_MD_97_",
  },
  {
    text: "MD 193 (MD 201 to MD 650)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_193__MD_201_to_MD_650_",
  },
  {
    text: "MD 197 (US 301 to MD 450)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_197__US_301_to_MD_450_",
  },
  {
    text: "MD 201 (MD 450 to MD 212)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_201__MD_450_to_MD_212_",
  },
  {
    text: "MD 210 (MD 228 to I-95)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_210__MD_228_to_I-95_",
  },
  {
    text: "MD 228 (MD 201 to US 301)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_228__MD_201_to_US_301_",
  },
  {
    text: "MD 355 (DC Line to MD 27)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_355__DC_Line_to_MD_27_",
  },
  {
    text: "MD 410 (MD 355 to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_410__MD_355_to_US_29_",
  },
  {
    text: "MD 410 (MD 650 to Pennsy Drive)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_410__MD_650_to_Pennsy_Drive_",
  },
  {
    text: "MD 450 (MD 202 to MD 704?)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_450__MD_202_to_MD_704?_",
  },
  {
    text: "MD 450 (Housely Road to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_450__Housely_Road_to_MD_2_",
  },
  {
    text: "MD 650 (DC Line to US 29)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "MD_650__DC_Line_to_US_29_",
  },
  {
    text: "US 1 (MD 410 to MD 198)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_1__MD_410_to_MD_198_",
  },
  {
    text: "US 1 (Baltimore City Line to Honeygo Boulevard)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_1__Baltimore_City_Line_to_Honeygo_Boulevard_",
  },
  {
    text: "US 29 (MD 97 to MD 650)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_29__MD_97_to_MD_650_",
  },
  {
    text: "US 29 (Industrial Parkway to MD 198",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_29__Industrial_Parkway_to_MD_198",
  },
  {
    text: "US 40 (I-70 to Cleveland Avenue) ",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_40__I-70_to_Cleveland_Avenue__",
  },
  {
    text: "US 301 (Billingsley Road to MD 5)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_301__Billingsley_Road_to_MD_5_",
  },
];

export const geographic_options_other_freight_corridors = [
  {
    text: "I-895 Northbound (I-695 to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895_Northbound__I-695_to_MD_2_",
  },
  {
    text: "I-270 West Spur Southbound (I-270 Split to I-495)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-270_West_Spur_Southbound__I-270_Split_to_I-495_",
  },
  {
    text: "I-695 Outer Loop (MD 170 to MD 10)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__MD_170_to_MD_10_",
  },
  {
    text: "I-495 Inner Loop (MD 414 to I-295)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_414_to_I-295_",
  },
  {
    text: "I-495 Outer Loop (MD 187 to MD 190)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Outer_Loop__MD_187_to_MD_190_",
  },
  {
    text: "I-495 Inner Loop (MD 187 to MD 185)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_187_to_MD_185_",
  },
  {
    text: "I-895 Southbound (Moravia Road to Holabird Road)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-895_Southbound__Moravia_Road_to_Holabird_Road_",
  },
  {
    text: "I-95 Northbound (I-695 to MD 295)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95_Northbound__I-695_to_MD_295_",
  },
  {
    text: "I-695 Outer Loop (I-95 to 147)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__I-95_to_147_",
  },
  {
    text: "I-695 Outer Loop (MD 129 to MD 26)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Outer_Loop__MD_129_to_MD_26_",
  },
  {
    text: "US 50 Eastbound (MD 665 to MD 2)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50_Eastbound__MD_665_to_MD_2_",
  },
  {
    text: "I-495 Inner Loop (MD 212 to Greenbelt METRO Center)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-495_Inner_Loop__MD_212_to_Greenbelt_METRO_Center_",
  },
  {
    text: "US 50 Eastbound (Buschs Frontage Road to Bay Bridge)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "US_50_Eastbound__Buschs_Frontage_Road_to_Bay_Bridge_",
  },
  {
    text: "I-95 Southbound (I-895 to O’Donnell Street)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-95_Southbound__I-895_to_ODonnell_Street_",
  },
  {
    text: "I-695 Inner Loop (MD 129 to I-83)",
    icon: "emoji_transportation",
    active: true,
    geoRoute: "hpms_segments/geojson",
    segmentsTable: "maryland_corridors",
    segmentSelection: "I-695_Inner_Loop__MD_129_to_I-83_",
  },
];

export const SUMMARY_VARIABLES_ROUTE = [
  {
    id: "end_point",
    numeric: true,
    disablePadding: false,
    label: "To",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Ending milemarker</div>
    ),
  },
  {
    id: "beg_point",
    numeric: true,
    disablePadding: false,
    label: "From",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Starting milemarker</div>
    ),
  },
  {
    id: "route_id",
    numeric: false,
    disablePadding: true,
    label: "Route ID",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        HPMS route identifier/road name
      </div>
    ),
  },
];

export const SUMMARY_VARIABLES_GROUPING = [
  {
    id: "route_id",
    numeric: false,
    disablePadding: true,
    label: "Route ID",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        HPMS route identifier/road name
      </div>
    ),
  },
  {
    id: "ownership",
    numeric: false,
    disablePadding: true,
    label: "Ownership",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        The entity that has legal ownership of a roadway
      </div>
    ),
    queryVariable: "ownershipFilters",
  },
  {
    id: "f_system",
    numeric: false,
    disablePadding: true,
    label: "Functional System",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        The FHWA approved Functional Classification System
      </div>
    ),
    queryVariable: "fsystemFilters",
  },
  {
    id: "urban_code",
    numeric: false,
    disablePadding: true,
    label: "Urban Code",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        The U.S. Census Urban Area Code
      </div>
    ),
    queryVariable: "urbanCodeFilters",
  },
  {
    id: "nhs",
    numeric: false,
    disablePadding: true,
    label: "NHS",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        A Roadway that is a component of the National Highway System (NHS)
      </div>
    ),
    queryVariable: "nhsFilters",
  },
  {
    id: "strahnet",
    // id: "strahnet_t",
    numeric: false,
    disablePadding: true,
    label: "STRAHNET",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Roadway section that is a component of the Strategic Highway Network
        (STRAHNET)
      </div>
    ),
    queryVariable: "strahnetFilters",
  },
  {
    id: "truck",
    numeric: false,
    disablePadding: true,
    label: "NN",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Roadway section that is a component of the National Truck Network (NN)
        as defined by 23 CFR 658
      </div>
    ),
    queryVariable: "truckNetworkFilters",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: true,
    label: "Total",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Total of each column</div>
    ),
  },
  {
    id: "county_cod",
    numeric: false,
    disablePadding: true,
    label: "County",
    checked: true,
    category: "route",
    tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Counties</div>,
    queryVariable: "countyFilters",
  },
];

export const SUMMARY_VARIABLES_ROUTE_100 = [
  {
    id: "seg_to",
    numeric: true,
    disablePadding: false,
    label: "To",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Ending road name</div>
    ),
  },
  {
    id: "seg_from",
    numeric: true,
    disablePadding: false,
    label: "From",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Starting road name</div>
    ),
  },
  {
    id: "seg_name",
    numeric: false,
    disablePadding: true,
    label: "Road",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Top 100 Bottlenecks road name
      </div>
    ),
  },
];

export const SUMMARY_VARIABLES_ROUTE_100_DIRECTIONAL = [
  {
    id: "direction",
    numeric: false,
    disablePadding: true,
    label: "Direction",
    checked: true,
    category: "route",
    tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Direction</div>,
  },
  {
    id: "seg_to",
    numeric: true,
    disablePadding: false,
    label: "To",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Ending road name</div>
    ),
  },
  {
    id: "seg_from",
    numeric: true,
    disablePadding: false,
    label: "From",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Starting road name</div>
    ),
  },
  {
    id: "seg_name",
    numeric: false,
    disablePadding: true,
    label: "Road",
    checked: true,
    category: "route",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Top 100 Bottlenecks road name
      </div>
    ),
  },
];

export const SUMMARY_VARIABLES_HPMS = [
  {
    variable: "trkdelay",
    variableDirection1: "trkdelay1",
    variableDirection2: "trkdelay2",
    summaryName: "Truck Person-Hours of Delay",
    definition: "Annual Hours of Truck Delay",
    explanation: `The mileage traveled by all trucks on a highway segment\
 in the peak period over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of truck delay is the best measure of the size of
        the congestion problem.
        <ul>
          <li>
            Combines elements of the TTI (intensity of congestion on any section
            of road) with a magnitude element (the amount of trucks suffering
            that congestion).
          </li>
          <li>
            Prioritizes highly traveled sections above those that are less
            heavily traveled.
          </li>
          <li>
            Often shown in person-hours of delay so that a one-to-one comparison
            can be made with passenger vehicle delay (percentage of overall
            delay is due to trucks).
          </li>
          <li>
            Assumes a truck occupancy of 1.14 persons, which can be used to
            convert person-hours of delay to vehicle hours of delay.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkpti80",
    variableDirection1: "trkpti801",
    variableDirection2: "trkpti802",
    summaryName: "Truck Planning Time Index 80",
    definition: "80th Percentile Truck Planning Time Index",
    explanation: `80th percentile Truck Planning Time Index (PTI)\
 is the ratio of the 80th percentile truck travel time as compared\
 to the truck free-flow travel time.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period truck trip to be on-time 4 out of 5
        times the trip is made.
        <ul>
          <li>
            Computed as the 80th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a week. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkpti95",
    variableDirection1: "trkpti951",
    variableDirection2: "trkpti952",
    summaryName: "Truck Planning Time Index 95",
    definition: "95th Percentile Truck Planning Time Index",
    explanation: `95th percentile Truck Planning Time Index (PTI)\
is the ratio of the 95th percentile truck travel time\
as compared to the truck free-flow travel time.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period truck trip to be on-time 19 out of
        20 times the trip is made.
        <ul>
          <li>
            Computed as the 95th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a month. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkaadt", // derived:
    variableDirection1: "trkaadt",
    variableDirection2: "trkaadt",
    summaryName: "Truck AADT",
    definition: "Average Annual Daily Truck Traffic",
    explanation: `The total volume of truck traffic on a highway segment\
 for one year, divided by the number of days in the year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    normalizedBy: "seg_length",
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Average annual daily truck traffic
      </div>
    ),
  },
  {
    variable: "trkvolper", // derived:
    variableDirection1: "trkvolper",
    variableDirection2: "trkvolper",
    summaryName: "Truck %",
    definition: "Percentage of traffic that is truck volume",
    explanation: `The Truck AADT / AADT.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>Truck volume percentage</div>
    ),
  },
  {
    variable: "trkttivmt",
    variableDirection1: "trkttivmt1",
    variableDirection2: "trkttivmt2",
    summaryName: "Truck Vehicle Miles of Travel",
    definition: "Daily Truck Vehicle Miles of Travel",
    explanation: `The mileage traveled by all trucks on a highway segment\
 over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Peak Period truck vehicle miles of travel (VMT) .
        <ul>
          <li>Used to weight truck TTI and PTI values through a corridor .</li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkrefspee",
    variableDirection1: "trkrefspee",
    variableDirection2: "trkrefspee",
    summaryName: "Truck Reference Speed",
    definition: "Average Network Truck Free Flow Speed",
    explanation: "The average speed of trucks in uncongested conditions.",
    formatOptions: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Estimated truck speed in uncongested conditions
      </div>
    ),
  },
  {
    variable: "trkspeed",
    variableDirection1: "trkspeed1",
    variableDirection2: "trkspeed2",
    summaryName: "Truck Congested Speed",
    definition: "Average Network Truck Congested Speed",
    explanation: "The average speed of trucks in congested conditions.",
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Estimated peak period truck speed
      </div>
    ),
  },
  {
    variable: "trktti",
    variableDirection1: "trktti1",
    variableDirection2: "trktti2",
    summaryName: "Truck Travel Time Index",
    definition: "Truck Travel Time Index",
    explanation: `Travel Time Index is the ratio of the peak-period travel time\
 for trucks as compared to the free-flow travel time for trucks. This measure\
 is computed for the AM peak period (6:00 a.m. to 9:00 a.m.) and PM peak period\
 (4:00 p.m. to 7:00 p.m.) on weekdays.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "trkttivmt",
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Unitless measure that indicates the amount of extra time for the average
        truck trip due to peak period congestion.
        <ul>
          <li>
            A TTI value of 1.40 indicates a 20-minute trip in the off-peak will
            take 28 minutes in the peak.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "delay",
    variableDirection1: "delay1",
    variableDirection2: "delay2",
    summaryName: "Person Hours of Delay",
    definition: "Annual Person Hours of Delay",
    explanation: `Person hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of delay is the best measure of the size of the
        congestion problem.
        <ul>
          <li>
            Combines elements of the TTI (intensity of congestion on any section
            of road) with a magnitude element (the amount of vehicles suffering
            that congestion).
          </li>
          <li>
            Prioritizes highly traveled sections above those that are less
            heavily traveled.
          </li>
          <li>
            Shown in person-hours of delay. This measure is based on a passenger
            vehicle occupancy of 1.50 persons per vehicle and a truck occupancy
            of 1.14 persons persons per vehicle.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "pti80",
    variableDirection1: "pti801",
    variableDirection2: "pti802",
    summaryName: "Planning Time Index 80",
    definition: "80th Percentile Planning Time Index",
    explanation: `80th percentile Planning Time Index (PTI)  is the ratio\
 of the 80th percentile travel time as compared to the free-flow travel time.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period trip to be on-time 4 out of 5 times
        the trip is made.
        <ul>
          <li>
            Computed as the 80th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a week. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "pti95",
    variableDirection1: "pti951",
    variableDirection2: "pti952",
    summaryName: "Planning Time Index 95",
    definition: "95th Percentile Planning Time Index",
    explanation: `95th percentile Planning Time Index (PTI)  is the ratio of\
 the 95th percentile travel time as compared to the free-flow travel time.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period trip to be on-time 19 out of 20
        times the trip is made.
        <ul>
          <li>
            Computed as the 95th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a month. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "aadt",
    variableDirection1: "aadt",
    variableDirection2: "aadt",
    summaryName: "AADT",
    definition: "Average Annual Daily Traffic",
    explanation: `The total volume of traffic on a highway segment\
 for one year, divided by the number of days in the year.`,
    formatOptions: { maximumFractionDigits: 0 }, // rounded to closest integer
    normalizedBy: "seg_length",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Average annual daily traffic
      </div>
    ),
  },

  //  {
  //    variable: "vmt", // derived: Math.round(adt_cur * len_sec)
  //    summaryName: "Vehicle Miles of Travel",
  //    definition: "Daily Vehicle Miles of Travel",
  //    explanation: `The mileage traveled by all vehicles on a highway segment\
  // over an average day in a year.`,
  //    formatOptions: { maximumFractionDigits: 0 },
  //    category: "all",
  //    defaultVisibility: false
  //  },
  {
    variable: "ttivmt",
    variableDirection1: "ttivmt1",
    variableDirection2: "ttivmt2",
    summaryName: "Peak Vehicle Miles of Travel",
    definition: "Daily Peak Period Vehicle Miles of Travel",
    explanation: `The mileage traveled by all vehicles on a highway segment\
 in the peak period over an average day in a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Peak Period vehicle miles of travel (VMT).
        <ul>
          <li>Used to weight TTI and PTI values through a corridor.</li>
        </ul>
      </div>
    ),
  },
  {
    variable: "congcost",
    variableDirection1: "congcost1",
    variableDirection2: "congcost2",
    summaryName: "Congested Costs",
    definition: "Annual Costs ($) of Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual cost of congestion based on wasted time and fuel.
        <ul>
          <li>
            Two cost components are associated with congestion: delay and fuel
            cost.
          </li>
          <li>
            Passenger vehicle cost is based on person-hours of delay, which
            assumes a vehicle occupancy of 1.50 persons per passenger vehicle
            and gallons of wasted gasoline.
          </li>
          <li>
            Truck cost is based on vehicle-hours of delay, based on dividing the
            person-hours of truck delay by the average truck occupancy of 1.14
            persons, and the gallons of wasted diesel.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "ghg",
    variableDirection1: "ghg1",
    variableDirection2: "ghg2",
    summaryName: "Congested CO2 Tons",
    definition: "Annual Tons of CO2 Due to Traffic Congestion",
    explanation:
      "Congested Tons of CO2 is the extra CO2 emmitted from vehicles\
 in congested conidtions.",
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual tons of greenhouse gas emissions (CO2) due to congestion.
      </div>
    ),
  },
  {
    variable: "ghgbase",
    variableDirection1: "ghgbase1",
    variableDirection2: "ghgbase2",
    summaryName: "Normal CO2 Tons",
    definition: "Annual Tons of CO2 Associated with Uncongested Travel",
    explanation: `Congested Tons of CO2 is the CO2 emmitted from vehicles\
 in uncongested conditions.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual tons greenhouse gas emissions (CO2) for all travel.
      </div>
    ),
  },

  //  {
  //    variable: "len_sec",
  //    summaryName: "Miles of Highway",
  //    definition: "Total Highway Miles of Analysis",
  //    explanation: "The total miles of highway for the roadways being analyzed.",
  //    formatOptions: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  //    category: "all",
  //    defaultVisibility: false
  //  },
  //  {
  //    variable: "lanemiles", // derived: len_sec * num_lanes
  //    summaryName: "Lane Miles of Highway",
  //    definition: "Total Lane Miles of Analysis",
  //    explanation: `The total lanes*miles of highway for the roadways\
  // being analyzed.`,
  //    formatOptions: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  //    category: "all",
  //    defaultVisibility: false
  //  },
  {
    variable: "allrefspee",
    variableDirection1: "allrefspee",
    variableDirection2: "allrefspee",
    summaryName: "Reference Speed",
    definition: "Average Network Free Flow Speed",
    explanation: "The average speed of vehicles in uncongested conditions.",
    formatOptions: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Estimated speed for all vehicles in uncongested conditions.
      </div>
    ),
  },
  {
    variable: "allspeed",
    variableDirection1: "allspeed1",
    variableDirection2: "allspeed2",
    summaryName: "Congested Speed",
    definition: "Average Network Congested Speed",
    explanation: "The average speed of vehicles in congested conditions.",
    formatOptions: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Estimated peak period speed for all vehicles.
      </div>
    ),
  },
  {
    variable: "tti",
    variableDirection1: "tti1",
    variableDirection2: "tti2",
    summaryName: "Travel Time Index",
    definition: "Travel Time Index",
    explanation: `Travel Time Index is the ratio of the peak-period travel time\
 as compared to the free-flow travel time. This measure is computed for\
 the AM peak period (6:00 a.m. to 9:00 a.m.) and PM peak period\
 (4:00 p.m. to 7:00 p.m.) on weekdays.`,
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    normalizedBy: "ttivmt",
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Unitless measure that indicates the amount of extra time for the average
        trip due to peak period congestion.
        <ul>
          <li>
            A TTI value of 1.40 indicates a 20-minute trip in the off-peak will
            take 28 minutes in the peak.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "commcost",
    variableDirection1: "commcost1",
    variableDirection2: "commcost2",
    summaryName: "Truck Congested Costs",
    definition: "Annual Costs ($) of Truck Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual cost of truck congestion based on wasted time and fuel.
        <ul>
          <li>
            Two cost components are associated with congestion: delay and fuel
            cost.
          </li>
          <li>
            Truck cost is based on vehicle-hours of delay (based on dividing the
            person-hours of truck delay by the average truck occupancy of 1.14
            persons) and the gallons of wasted diesel.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "commodity_value",
    variableDirection1: "commodity_value1",
    variableDirection2: "commodity_value2",
    summaryName: "Commodity Values (Annual $1,000,000)",
    definition:
      "Annual estimated value of the movement of goods on the segment/corridor.",
    explanation: `Commodity value represents an estimate of the annual value of the \
    movement of goods traversing the segment.  It is derived from the use of the \
    Freight Analysis Framework (FAF) provided by the Federal Highway Administration (FHWA) \
    and assessed using truck characteristics and volumes. It is an estimate meant to reflect \
    the level of freight on that segment per year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "other",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Commodity value represents an estimate of the annual value of the
        movement of goods traversing the segment. It is derived from the use of
        the Freight Analysis Framework (FAF) provided by the Federal Highway
        Administration (FHWA) and assessed using truck characteristics and
        volumes. It is an estimate meant to reflect the level of freight on that
        segment per year.
      </div>
    ),
  },
  {
    variable: "seg_length",
    variableDirection1: "seg_length",
    variableDirection2: "seg_length",
    summaryName: "Segment Length",
    definition: "Segment Length",
    explanation: "Segment Length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "route",
    defaultVisibility: true,
    tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Segment Length</div>,
  },
  {
    variable: "lanemiles",
    variableDirection1: "lanemiles",
    variableDirection2: "lanemiles",
    summaryName: "Lanemiles",
    definition: "Lanemiles",
    explanation: "Lanemiles",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "route",
    defaultVisibility: true,
    tooltip: <div style={{ fontSize: 12, maxWidth: 400 }}>Lanemiles</div>,
  },
  {
    variable: "ttr95a",
    variableDirection1: "ttr95a1",
    variableDirection2: "ttr95a2",
    summaryName: "TTTR Estimate - AM Peak Period (6-10AM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – AM Peak Period (6-10AM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95p",
    variableDirection1: "ttr95p1",
    variableDirection2: "ttr95p2",
    summaryName: "TTTR Estimate - PM Peak Period (4-8PM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – PM Peak Period (4-8PM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95o",
    variableDirection1: "ttr95o1",
    variableDirection2: "ttr95o2",
    summaryName: "TTTR Estimate - Overnight Period (8PM-6AM all days)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Overnight Period (8PM-6AM all days)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95m",
    variableDirection1: "ttr95m1",
    variableDirection2: "ttr95m2",
    summaryName: "TTTR Estimate - Midday Period (10AM-4PM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Midday Period (10AM-4PM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95w",
    variableDirection1: "ttr95w1",
    variableDirection2: "ttr95w2",
    summaryName: "TTTR Estimate - Weekend Period (6AM-8PM, weekends only)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Weekend Period (6AM-8PM, weekends only)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "tttrest",
    variableDirection1: "tttrest1",
    variableDirection2: "tttrest2",
    summaryName: "TTTR - Estimated",
    definition: "Truck Travel Time Reliability (TTTR) – Estimated",
    explanation:
      "Maximum of the five TTTR time period values. This is not the TTTR reported per federal requirements.",
    normalizedBy: "seg_length",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Maximum of the five TTR time period values. This is not the TTTR
        reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "iri",
    variableDirection1: "iri",
    variableDirection2: "iri",
    summaryName: "IRI - Weighted by Lanemiles",
    definition: "International Roughness Index (IRI) weighted by lanemiles",
    explanation: "International Roughness Index (IRI) weighted by lanemiles.",
    normalizedBy: "lanemiles_iri",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "other",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        International Roughness Index (IRI) weighted by lanemiles.
      </div>
    ),
  },
  {
    variable: "iri_vmt",
    variableDirection1: "iri_vmt",
    variableDirection2: "iri_vmt",
    summaryName: "IRI - Weighted by VMT",
    definition: "International Roughness Index (IRI) weighted by VMT",
    explanation: "International Roughness Index (IRI) weighted by VMT.",
    normalizedBy: "ttivmt_iri",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "other",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        International Roughness Index (IRI) weighted by VMT.
      </div>
    ),
  },
  {
    variable: "ghgtrk",
    variableDirection1: "ghgtrk1",
    variableDirection2: "ghgtrk2",
    summaryName: "Truck Congested CO2 Tons",
    definition: "Annual Tons of truck CO2 Due to Traffic Congestion",
    explanation:
      "Truck Congested Tons of CO2 is the extra CO2 emmitted from vehicles\
 in congested conidtions.",
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual tons of truck greenhouse gas emissions (CO2) due to congestion.
      </div>
    ),
  },
  {
    variable: "ghgtrkbase",
    variableDirection1: "ghgtrkbase1",
    variableDirection2: "ghgtrkbase2",
    summaryName: "Normal Truck CO2 Tons",
    definition: "Annual Tons of Truck CO2 Associated with Uncongested Travel",
    explanation: `Truck Congested Tons of CO2 is the CO2 emmitted from vehicles\
 in uncongested conditions.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual tons of truck greenhouse gas emissions (CO2) for all travel.
      </div>
    ),
  },
];

export const CATEGORICAL_VARIABLES = [
  {
    variable: "county",
    mapped: "co_name",
    definition: "County Name",
  },
  {
    variable: "hsys_new",
    mapped: "hsys_new",
    definition: "Highway System Designation",
  },
  {
    variable: "ru",
    mapped: "ru_n",
    definition: "Rural Urban Code",
  },
  {
    variable: "sec_nhs",
    mapped: "sec_nhs_n",
    definition: "National Highway System",
  },
  {
    variable: "hwy",
    mapped: "highway_name",
    definition: "Major Highways",
  },
];

export const POPUP_GROUPS = {
  all: ["delay", "congcost", "tti", "pti80", "pti95", "aadt"],
  top100: [
    "dlyrank",
    "delay",
    "dlymile",
    "tlcost",
    "tttrest",
    "iri",
    "commodity_value",
  ],
  truck: [
    "trkdelay",
    "commcost",
    "trktti",
    "trkpti80",
    "trkpti95",
    "trkaadt",
    "tttrest",
  ],
  top100truck: [
    "trkdlyrank",
    "trkdelay",
    "trkdlymile",
    "trkcost",
    "tttrest",
    "iri",
    "commodity_value",
  ],
  all1: ["delay1", "congcost1", "tti1", "pti801", "pti951"],
  all2: ["delay2", "congcost2", "tti2", "pti802", "pti952"],
  truck1: [
    "trkdelay1",
    "commcost1",
    "trktti1",
    "trkpti801",
    "trkpti951",
    "tttrest1",
  ],
  truck2: [
    "trkdelay2",
    "commcost2",
    "trktti2",
    "trkpti802",
    "trkpti952",
    "tttrest2",
  ],
};

export const CATEGORY_SUMMARY_VARIABLES = [
  {
    variable: "hrs_delay",
    summaryName: "Hours of Delay",
    definition: "Hours of Delay",
    subcategory: ["delay", "trkdelay"],
    defaultVisibility: true,
  },
  {
    variable: "plan_time_index",
    summaryName: "Planning Time Index",
    definition: "Planning Time Index",
    subcategory: ["pti80", "trkpti80", "pti95", "trkpti95"],
    defaultVisibility: false,
  },
];

export const SUMMARY_VARIABLES_TOP100 = [
  {
    variable: "trkdlyrank",
    summaryName: "Truck Top 100 Bottlenecks Rank",
    definition: "Truck Top 100 Bottlenecks Rank",
    explanation: `Truck Top 100 Bottlenecks Rank`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Rank of road segment among all monitored road segments based on truck
        performance.
      </div>
    ),
  },
  {
    variable: "dlyrank",
    summaryName: "Top 100 Bottlenecks Rank",
    definition: "Top 100 Bottlenecks Rank",
    explanation: `Top 100 Bottlenecks Rank`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Rank of road segment among all monitored road segments based on all
        vehicle performance.
      </div>
    ),
  },
  {
    variable: "trkdelay",
    summaryName: "Truck Person-Hours Hours of Delay",
    definition: "Annual Person-Hours of Truck Delay",
    explanation: `Truck Person-Hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of truck delay is the best measure of the size of
        the congestion problem.
        <ul>
          <li>
            Combines elements of the TTI (intensity of congestion on any section
            of road) with a magnitude element (the amount of trucks suffering
            that congestion).
          </li>
          <li>
            Prioritizes highly traveled sections above those that are less
            heavily traveled.
          </li>
          <li>
            Often shown in person-hours of delay so that a one-to-one comparison
            can be made with passenger vehicle delay (percentage of overall
            delay is due to trucks).
          </li>
          <li>
            Assumes a truck occupancy of 1.14 persons, which can be used to
            convert person-hours of delay to vehicle hours of delay.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "delay",
    summaryName: "Person Hours of Delay",
    definition: "Annual Person Hours of Delay",
    explanation: `Person hours of delay is the difference in travel time\
 from uncogested traffic and congested traffic. This is for all persons\
 in vehicles traveling for a year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of delay is the best measure of the size of the
        congestion problem.
        <ul>
          <li>
            Combines elements of the TTI (intensity of congestion on any section
            of road) with a magnitude element (the amount of vehicles suffering
            that congestion).
          </li>
          <li>
            Prioritizes highly traveled sections above those that are less
            heavily traveled.
          </li>
          <li>
            Shown in person-hours of delay. This measure is based on a passenger
            vehicle occupancy of 1.50 persons per vehicle and a truck occupancy
            of 1.14 persons persons per vehicle.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "dlymile",
    summaryName: "Person Hours of Delay per Mile",
    definition: "Annual Person Hours of Delay per Mile",
    explanation: `Person hours of delay per mile.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of delay per mile of roadway.
      </div>
    ),
  },
  {
    variable: "trkdlymile",
    summaryName: "Truck Person-Hours of Delay per Mile",
    definition: "Annual Person-Hours of Truck Delay per Mile",
    explanation: `Truck Person-Hours of delay  per Mile.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual person-hours of truck delay per mile of roadway.
        <ul>
          <li>
            Assumes a truck occupancy of 1.14 persons, which can be used to
            convert person-hours of delay to vehicle hours of delay.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trktti",
    summaryName: "Truck Travel Time Index",
    definition: "Truck Travel Time Index",
    explanation: "Truck Travel Time Index",
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Unitless measure that indicates the amount of extra time for the average
        truck trip due to peak period congestion.
        <ul>
          <li>
            A TTI value of 1.40 indicates a 20-minute trip in the off-peak will
            take 28 minutes in the peak.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkpti95",
    summaryName: "Truck Planning Time Index 95",
    definition: "95th Percentile Truck Planning Time Index",
    explanation: "95th Percentile Truck Planning Time Index",
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period truck trip to be on-time 19 out of
        20 times the trip is made.
        <ul>
          <li>
            Computed as the 95th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a month. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "trkcost",
    summaryName: "Truck Congested Costs",
    definition: "Annual Costs ($) of Truck Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual cost of truck congestion based on wasted time and fuel.
        <ul>
          <li>
            Two cost components are associated with congestion: delay and fuel
            cost.
          </li>
          <li>
            Truck cost is based on vehicle-hours of delay (based on dividing the
            person-hours of truck delay by the average truck occupancy of 1.14
            persons) and the gallons of wasted diesel.
          </li>
        </ul>
      </div>
    ),
  },

  {
    variable: "tti",
    summaryName: "Travel Time Index",
    definition: "Travel Time Index",
    explanation: "Travel Time Index",
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Unitless measure that indicates the amount of extra time for the average
        trip due to peak period congestion.
        <ul>
          <li>
            A TTI value of 1.40 indicates a 20-minute trip in the off-peak will
            take 28 minutes in the peak.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "pti95",
    summaryName: "Planning Time Index 95",
    definition: "95th Percentile Planning Time Index",
    explanation: "95th Percentile Planning Time Index",
    formatOptions: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Travel time reliability measure representing the total travel time that
        should be planned for a peak period trip to be on-time 19 out of 20
        times the trip is made.
        <ul>
          <li>
            Computed as the 95th percentile travel time divided by the free-flow
            travel time.
          </li>
          <li>
            Shows the amount of time that should be planned for a trip to be
            late for only one day a month. A PTI of 3.00 means that for a
            20-minute trip in light traffic, 60 minutes should be planned.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "tlcost",
    summaryName: "Congested Costs",
    definition: "Annual Costs ($) of Congestion",
    explanation: `Congested costs is the annual costs to travelers due to\
 such factors as loss productivity and extra vehicle wear`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual cost of congestion based on wasted time and fuel.
        <ul>
          <li>
            Two cost components are associated with congestion: delay and fuel
            cost.
          </li>
          <li>
            Passenger vehicle cost is based on person-hours of delay, which
            assumes a vehicle occupancy of 1.50 persons per passenger vehicle
            and gallons of wasted gasoline.
          </li>
          <li>
            Truck cost is based on vehicle-hours of delay, based on dividing the
            person-hours of truck delay by the average truck occupancy of 1.14
            persons, and the gallons of wasted diesel.
          </li>
        </ul>
      </div>
    ),
  },
  {
    variable: "ttr95a",
    summaryName: "TTTR Estimate - AM Peak Period (6-10AM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – AM Peak Period (6-10AM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95p",
    summaryName: "TTTR Estimate - PM Peak Period (4-8PM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – PM Peak Period (4-8PM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95o",
    summaryName: "TTTR Estimate - Overnight Period (8PM-6AM all days)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Overnight Period (8PM-6AM all days)",
    explanation:
      "Truck Travel Time Reliability is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95m",
    summaryName: "TTTR Estimate - Midday Period (10AM-4PM)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Midday Period (10AM-4PM)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "ttr95w",
    summaryName: "TTTR Estimate - Weekend Period (6AM-8PM, weekends only)",
    definition:
      "Truck Travel Time Reliability (TTTR) Estimate – Weekend Period (6AM-8PM, weekends only)",
    explanation:
      "Truck Travel Time Reliability Estimate is the 95th percentile travel time divided by the 50th percentile travel time for the given time period. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Truck Travel Time Reliability is the 95th percentile travel time divided
        by the 50th percentile travel time for the given time period. This is
        not the TTTR reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "tttrest",
    summaryName: "TTTR - Estimated",
    definition: "Truck Travel Time Reliability (TTTR) – Estimated",
    explanation:
      "Maximum of the five TTTR time period values. This is not the TTTR reported per federal requirements.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Maximum of the five TTR time period values. This is not the TTTR
        reported per federal requirements.
      </div>
    ),
  },
  {
    variable: "iri",
    summaryName: "IRI - Weighted by Lanemiles",
    definition: "International Roughness Index (IRI) weighted by lanemiles",
    explanation: "International Roughness Index (IRI) weighted by lanemiles.",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "other",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        International Roughness Index (IRI) weighted by lanemiles.
      </div>
    ),
  },
  {
    variable: "iri_vmt",
    summaryName: "IRI - Weighted by VMT",
    definition: "International Roughness Index (IRI) weighted by VMT",
    explanation: "International Roughness Index (IRI) weighted by VMT.",
    normalizedBy: "ttivmt_iri",
    formatOptions: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    category: "other",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        International Roughness Index (IRI) weighted by VMT.
      </div>
    ),
  },
  {
    variable: "commodity_value",
    summaryName: "Commodity Values (Annual $1,000,000)",
    definition:
      "Annual estimated value of the movement of goods on the segment/corridor.",
    explanation: `Commodity value represents an estimate of the annual value of the \
    movement of goods traversing the segment.  It is derived from the use of the \
    Freight Analysis Framework (FAF) provided by the Federal Highway Administration (FHWA) \
    and assessed using truck characteristics and volumes. It is an estimate meant to reflect \
    the level of freight on that segment per year.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "other",
    defaultVisibility: true,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Commodity value represents an estimate of the annual value of the
        movement of goods traversing the segment. It is derived from the use of
        the Freight Analysis Framework (FAF) provided by the Federal Highway
        Administration (FHWA) and assessed using truck characteristics and
        volumes. It is an estimate meant to reflect the level of freight on that
        segment per year.
      </div>
    ),
  },
  {
    variable: "dayghgtrk",
    summaryName: "Truck Congested CO2 Lbs",
    definition: "Annual Pounds of truck CO2 Due to Traffic Congestion",
    explanation:
      "Truck Congested Pounds of CO2 is the extra CO2 emmitted from vehicles\
 in congested conidtions.",
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual lbs of truck greenhouse gas emissions (CO2) due to congestion.
      </div>
    ),
  },
  {
    variable: "dayghgba_1",
    summaryName: "Normal Truck CO2 Lbs",
    definition: "Annual Lbs of Truck CO2 Associated with Uncongested Travel",
    explanation: `Truck Congested Lbs of CO2 is the CO2 emmitted from vehicles\
 in uncongested conditions.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "truck",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual lbs of truck greenhouse gas emissions (CO2) for all travel.
      </div>
    ),
  },
  {
    variable: "dayghg",
    summaryName: "Congested CO2 Lbs",
    definition: "Annual Pounds of CO2 Due to Traffic Congestion",
    explanation:
      "Congested Pounds of CO2 is the extra CO2 emmitted from vehicles\
 in congested conidtions.",
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual lbs of greenhouse gas emissions (CO2) due to congestion.
      </div>
    ),
  },
  {
    variable: "dayghgbase",
    summaryName: "Normal CO2 Lbs",
    definition: "Annual Lbs of CO2 Associated with Uncongested Travel",
    explanation: `Congested Lbs of CO2 is the CO2 emmitted from vehicles\
 in uncongested conditions.`,
    formatOptions: { maximumFractionDigits: 0 },
    category: "all",
    defaultVisibility: false,
    tooltip: (
      <div style={{ fontSize: 12, maxWidth: 400 }}>
        Annual lbs of greenhouse gas emissions (CO2) for all travel.
      </div>
    ),
  },
];

export const databaseVariablesHPMS = [
  {
    variable: "seg_length",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "lanemiles",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "aadt",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "aadt_combi",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "aadt_sgl_u",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "allfuel",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allfuel1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allfuel2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "allrefspe1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "allrefspe4",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "allrefspee",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "allspeed",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "allspeed1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "allspeed2",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "beg_point",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "commcost",
    condition: "sum",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "commcost1",
    condition: "sum",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "commcost2",
    condition: "sum",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "congcost",
    condition: "sum",
    normalizedBy: "ttivmt",
  },
  {
    variable: "congcost1",
    condition: "sum",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "congcost2",
    condition: "sum",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "delay",
    condition: "sum",
    normalizedBy: "ttivmt",
  },
  {
    variable: "delay1",
    condition: "sum",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "delay2",
    condition: "sum",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "direction1",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "direction2",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "end_point",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "estimated",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "ghg",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghg1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghg2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgbase2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrk2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbas0",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbas3",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ghgtrkbase",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "id",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "opdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "opdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "opdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "optrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pktrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "pti80",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "pti801",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "pti802",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "pti95",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "pti951",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "pti952",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "route_id",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "state_code",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "trkaadt",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "trkdelay",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkdelay1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkdelay2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkfuel2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkpti80",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkpti801",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkpti802",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkpti95",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkpti951",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkpti952",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkrefspee",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkrefspe2",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkrefspe5",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkspeed",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trkspeed1",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trkspeed2",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trktti",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "trktti1",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "trktti2",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "trkttivmt",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkttivmt1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "trkttivmt2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "truck",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "tti",
    condition: "normalized",
    normalizedBy: "ttivmt",
  },
  {
    variable: "tti1",
    condition: "normalized",
    normalizedBy: "ttivmt1",
  },
  {
    variable: "tti2",
    condition: "normalized",
    normalizedBy: "ttivmt2",
  },
  {
    variable: "ttivmt",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ttivmt1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "ttivmt2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "volume_gro",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "wedly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wedly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wedly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly1",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "wetrkdly2",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "year_recor",
    condition: "none",
    normalizedBy: "",
  },
  {
    variable: "commodity_value",
    condition: "normalized",
    normalizedBy: "trkttivmt",
  },
  {
    variable: "commodity_value1",
    condition: "normalized",
    normalizedBy: "trkttivmt1",
  },
  {
    variable: "commodity_value2",
    condition: "normalized",
    normalizedBy: "trkttivmt2",
  },
  {
    variable: "ttr95a",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95p",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95o",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95m",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95w",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95a1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95p1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95o1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95m1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95w1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95a2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95p2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95o2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95m2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "ttr95w2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "tttrest",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "tttrest1",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "tttrest2",
    condition: "normalized",
    normalizedBy: "seg_length",
  },
  {
    variable: "iri",
    condition: "normalized",
    normalizedBy: "lanemiles",
  },
  {
    variable: "iri_vmt",
    condition: "normalized",
    normalizedBy: "ttivmt_iri",
  },
  {
    variable: "ttivmt_iri",
    condition: "sum",
    normalizedBy: "",
  },
  {
    variable: "lanemiles_iri",
    condition: "sum",
    normalizedBy: "",
  },
];

// MD FAF Values
// fsystem=1 is IH
// fsystem=2 is FWY
// all others = pas

//  if f_system=1 then do;
//    fafvalue=((aadt_combi/2*ihcoton*ihval)+(aadt_singl/2*ihsiton*ihval))*365/1000000;
//  end;
//  if f_system=2 then do;
//    fafvalue=((aadt_combi/2*fwycoton*fwyval)+(aadt_singl/2*fwysiton*fwyval))*365/1000000;
//  end;
//  if f_system>2 then do;
//    fafvalue=((aadt_combi/2*pascoton*pasval)+(aadt_singl/2*passiton*pasval))*365/1000000;
//  end;
export const commodity_val_consts = {
  allval: 1486,
  ihval: 2312,
  fwyval: 1897,
  pasval: 781,
  ihcoton: 11.94,
  ihsiton: 2.71,
  fwycoton: 10.57,
  fwysiton: 3.61,
  pascoton: 10.61,
  passiton: 2.98,
};

// yearFilter: [
//   {
//     label: 2018,
//     value: 2018,
//     checked: true,
//   },
export const yearOptions = [
  {
    label: "2018",
    value: 2018,
    checked: true,
  },
  {
    label: "2019",
    value: 2019,
    checked: false,
  },
  {
    label: "2020",
    value: 2020,
    checked: false,
  },
  {
    label: "2021 Estimated",
    value: 2021,
    checked: false,
  },
  {
    label: "2022 Estimated",
    value: 2022,
    checked: false,
  },
];
