import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import RoadSegmentTable from "./RoadSegmentTable";
import SummaryTable from "./SummaryTable";

import { QueryContext } from "../map/QueryContext";

import SettingsFiltersTab from "../tabs/SettingsFiltersTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`data-tabpanel-${index}`}
      aria-labelledby={`data-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `data-tab-${index}`,
    "aria-controls": `data-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DataTabs(props) {
  const [query, dispatch] = useContext(QueryContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rows, setRows] = useState([]);
  const [roads, setRoads] = useState([]);
  const [roadsID, setRoadsID] = useState(0);
  const [roadsData, setRoadsData] = useState([]);
  useEffect(() => {
    dispatch({
      type: "setUserSelection",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: NaN,
      },
    });

    dispatch({
      type: "setUserSelectionMD100",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: "",
      },
    });

    setRoads([]);
    setRoadsID(0);
    setRoadsData([]);
    setRows([]);

    setValue(0);
  }, [query.statsLayerDesc]);

  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="static">
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="data tabs for road segments and summaries"
        >
          {/* <Tab label="Settings/Filters" {...a11yProps(0)} /> */}
          <Tab label="Road Segments" {...a11yProps(0)} />
          <Tab label="Summary" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <SettingsFiltersTab />
      </TabPanel>{" "} */}
      <TabPanel value={value} index={0}>
        <RoadSegmentTable
          rows={rows}
          setRows={setRows}
          roads={roads}
          setRoads={setRoads}
          roadsID={roadsID}
          setRoadsID={setRoadsID}
          roadsData={roadsData}
          setRoadsData={setRoadsData}
          layerID={query.statsLayerDesc}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SummaryTable layerID={query.statsLayerDesc} />
      </TabPanel>
    </div>
  );
}
