import React, { useState, useContext, useEffect } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import { QueryBuilder } from "@material-ui/icons";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  colselectLabel: {
    paddingRight: 15,
  },
  colselectColumn2: {
    paddingLeft: 15,
  },
}));

export default function TableColumnSelector(props) {
  const classes = useStyles();
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [dialogWidth, setDialogWidth] = useState("lg");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let columns = [];
  let dispatchType;
  if (query.layerSpecs.category === "Top 100") {
    columns = query.tableColumnsTop100;
    dispatchType = "setTableColumnsTop100";
  }
  if (query.layerSpecs.category !== "Top 100") {
    columns = query.tableColumnsHPMS;
    dispatchType = "setTableColumnsHPMS";
  }

  const handleChange = (event) => {
    // NOTE: TRUCK % MUST HAVE AADT AND TRUCK AADT.
    // if (event.target.name === "trkvolper" && event.target.checked) {
    //   columnsState.aadt = true;
    //   columnsState.trkaadt = true;
    // }
    columns.filter((itm) => itm.id === event.target.name)[0].checked =
      event.target.checked;
    if (event.target.name === "trkvolper" && event.target.checked) {
      columns.filter((itm) => itm.id === "aadt")[0].checked =
        event.target.checked;
      columns.filter((itm) => itm.id === "trkaadt")[0].checked =
        event.target.checked;
    }

    dispatch({
      type: dispatchType,
      selection: columns,
    });
    // dispatch({
    //   type: "setTableColumns",
    //   selection: { ...columnsState, [event.target.name]: event.target.checked },
    // });
  };

  const handleUnselectAllColumnsAll = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", false);
    // cols = buildColsState(cols, "truck", "keep");
    // cols = buildColsState(cols, "other", "keep");

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "all")
      .map((obj) => (obj.checked = false));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  const handleSelectAllColumnsAll = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", true);
    // cols = buildColsState(cols, "truck", "keep");
    // cols = buildColsState(cols, "other", "keep");

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "all")
      .map((obj) => (obj.checked = true));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  const handleUnselectAllColumnsTruck = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", "keep");
    // cols = buildColsState(cols, "truck", false);
    // cols = buildColsState(cols, "other", "keep");

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "truck")
      .map((obj) => (obj.checked = false));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  const handleSelectAllColumnsTruck = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", "keep");
    // cols = buildColsState(cols, "truck", true);
    // cols = buildColsState(cols, "other", "keep");

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "truck")
      .map((obj) => (obj.checked = true));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  const handleSelectAllColumnsOther = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", "keep");
    // cols = buildColsState(cols, "truck", "keep");
    // cols = buildColsState(cols, "other", true);

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "other")
      .map((obj) => (obj.checked = true));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  const handleUnselectAllColumnsOther = (event) => {
    // let cols = {};

    // cols = buildColsState(cols, "route", "keep");
    // cols = buildColsState(cols, "all", "keep");
    // cols = buildColsState(cols, "truck", "keep");
    // cols = buildColsState(cols, "other", false);

    // dispatch({
    //   type: "setTableColumns",
    //   selection: cols,
    // });

    columns
      .filter((itm) => itm.category === "other")
      .map((obj) => (obj.checked = false));
    // setColumns(columnsTemp);
    dispatch({
      type: dispatchType,
      selection: columns,
    });
  };

  // function buildColsState(cols, category, state) {
  //   columns
  //     .filter((item) => item.category === category)
  //     .map((col, index) => {
  //       // console.log("columns-check", columns);
  //       cols = {
  //         ...cols,
  //         [col.id]: state === "keep" ? columnsState[col.id] : state,
  //       };
  //     });

  //   return cols;
  // }

  function DialogColumn(props) {
    return (
      <div>
        <FormGroup
          key={props.formGroupKey}
          className={classes.colselectColumn2}
        >
          <FormLabel component="legend" className={classes.colselectLabel}>
            {props.categoryLabel}
          </FormLabel>
          {columns
            .filter((item) => item.category === props.category)
            .map((col, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={col.id}
                    key={`check-rdsegment-col-${index}`}
                    checked={col.checked}
                    onChange={handleChange}
                  />
                } //{<Checkbox checked={jason} onChange={handleChange} name="jason" />}
                label={col.label}
                key={`check-rdsegment-col-fgl-${index}`}
              />
            ))}
          <Grid
            container
            alignItems="center"
            className={classes.colselectLabel}
          >
            {props.selectAllButton !== "none" && (
              <Grid item xs>
                <Button onClick={props.selectAllButton} color="secondary">
                  Select All
                </Button>
              </Grid>
            )}
            {props.unselectAllButton !== "none" && (
              <Grid item>
                <Button onClick={props.unselectAllButton} color="secondary">
                  Unselect All
                </Button>
              </Grid>
            )}
          </Grid>
        </FormGroup>
      </div>
    );
  }

  return (
    <div>
      <HtmlTooltip placement="bottom-start" title="Select data table columns">
        <IconButton
          component="span"
          color="secondary"
          aria-label="select columns"
          onClick={handleClickOpen}
        >
          <ViewColumnIcon />
        </IconButton>
      </HtmlTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={dialogWidth}
      >
        <DialogTitle>Select table columns below:</DialogTitle>
        <DialogContent>
          <Grid container alignItems="flex-start">
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg1`}
              category={"route"}
              categoryLabel={"Route Information"}
              selectAllButton={"none"}
              unselectAllButton={"none"}
            />
            <Divider orientation="vertical" flexItem />
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg2`}
              category={"all"}
              categoryLabel={"All Vehicle Performance Measures"}
              selectAllButton={handleSelectAllColumnsAll}
              unselectAllButton={handleUnselectAllColumnsAll}
            />
            <Divider orientation="vertical" flexItem />
            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg3`}
              category={"truck"}
              categoryLabel={"Truck Performance Measures"}
              selectAllButton={handleSelectAllColumnsTruck}
              unselectAllButton={handleUnselectAllColumnsTruck}
            />
            <Divider orientation="vertical" flexItem />

            <DialogColumn
              formGroupKey={`check-rdsegment-col-fg4`}
              category={"other"}
              categoryLabel={"Other Measures"}
              selectAllButton={handleSelectAllColumnsOther}
              unselectAllButton={handleUnselectAllColumnsOther}
            />
          </Grid>
          <Divider variant="middle" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
