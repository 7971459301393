import { makeStyles, createTheme } from "@material-ui/core/styles";
// import { makeStyles } from "@material-ui/core/styles";
// import { createTheme } from "@mui/material/styles";

// Main theme and responsive fonts
export function makeTheme(mode) {
  return createTheme({
    palette: {
      type: mode,
      primary: {
        light: "#1E1E1E",
        main: "#1E1E1E",
        dark: "#000000",
        contrastText: "#fff",
        color: "white",
      },
      secondary: {
        light: "#ffd35f",
        main: "#ffc838",
        dark: "#b28c27",
        contrastText: "#000",
      },
      background: {
        paper: mode === "dark" ? "#1E1E1E" : "#ffffff",
      },
    },
  });
}

// Sidebar
const drawerWidth = 265;

export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: 0,
    overflow: "hidden",
    overflowY: "hidden" /* Hide vertical scrollbar */,
    overflowX: "hidden" /* Hide horizontal scrollbar */,
  },
}));
