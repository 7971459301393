import React, { useState, useContext, useEffect } from "react";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";
import LeafletMap from "./LeafletMap";
import LeafletMapState from "./LeafletMapState";

export default function MapLayout(props) {
  const {
    mode,
    statsLayerParam,
    // region,
    // county,
    segmentsTable,
    segmentSelection,
    openDrawer,
  } = props;
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  // console.log("openDrawer-MapLayout-check", openDrawer);
  const [leafletMap, setLeafletsMap] = useState(
    <LeafletMap
      mode={mode}
      // bounds={bounds}
      // year={year}
      // region={region}
      // county={county}
      // setYear={setYear}
      //   layerID={statsLayerParam}
      // geoRoute={geoRoute}
      // segmentsTable={segmentsTable}
      // segmentSelection={segmentSelection}
      dataRoute="hpms/data"
      Zoom={8}
      position={[39.045753, -76.641273]}
      openDrawer={openDrawer}
    />
  );
  useEffect(() => {
    if (query.geoRoute === "top100/geojson")
      return setLeafletsMap(
        <LeafletMap
          mode={mode}
          // bounds={bounds}
          // year={year}
          // region={region}
          // county={county}
          // setYear={setYear}
          //   layerID={statsLayerParam}
          // geoRoute={geoRoute}
          // segmentsTable={segmentsTable}
          // segmentSelection={segmentSelection}
          dataRoute="hpms/data"
          Zoom={8}
          position={[39.045753, -76.641273]}
          openDrawer={openDrawer}
        />
      );
    else
      return setLeafletsMap(
        <LeafletMapState
          mode={mode}
          // bounds={bounds}
          // year={year}
          //   region={query.geography.region}
          //   county={query.geography.county}
          // setYear={setYear}
          //   layerID={statsLayerParam}
          // geoRoute={geoRoute}
          //   segmentsTable={segmentsTable}
          //   segmentSelection={segmentSelection}
          dataRoute="hpms/data"
          Zoom={8}
          position={[39.045753, -76.641273]}
          openDrawer={openDrawer}
        />
      );
  }, [query.geoRoute, query.geography.region, query.geography.county]);

  //   function MapLayerSelect() {
  //     if (query.geoRoute === "top100/geojson") {
  //       return (
  //         <LeafletMap
  //           mode={mode}
  //           // bounds={bounds}
  //           // year={year}
  //           // region={region}
  //           // county={county}
  //           // setYear={setYear}
  //           layerID={statsLayerParam}
  //           // geoRoute={geoRoute}
  //           // segmentsTable={segmentsTable}
  //           // segmentSelection={segmentSelection}
  //           dataRoute="hpms/data"
  //           Zoom={8}
  //           position={[39.045753, -76.641273]}
  //         />
  //       );
  //     } else {
  //       return (
  //         <LeafletMapState
  //           mode={mode}
  //           // bounds={bounds}
  //           // year={year}
  //           region={region}
  //           county={county}
  //           // setYear={setYear}
  //           layerID={statsLayerParam}
  //           // geoRoute={geoRoute}
  //           segmentsTable={segmentsTable}
  //           segmentSelection={segmentSelection}
  //           dataRoute="hpms/data"
  //           Zoom={8}
  //           position={[39.045753, -76.641273]}
  //         />
  //       );
  //     }
  //   }

  return (
    <div id={"map"} className={mode}>
      {/* <MapLayerSelect /> */}
      {query.geoRoute === "top100/geojson" && (
        <LeafletMap
          mode={mode}
          // bounds={bounds}
          // year={year}
          // region={region}
          // county={county}
          // setYear={setYear}
          //   layerID={statsLayerParam}
          // geoRoute={geoRoute}
          // segmentsTable={segmentsTable}
          // segmentSelection={segmentSelection}
          dataRoute="hpms/data"
          Zoom={8}
          position={[39.045753, -76.641273]}
          openDrawer={openDrawer}
        />
      )}{" "}
      {query.geoRoute !== "top100/geojson" && (
        <LeafletMapState
          mode={mode}
          // bounds={bounds}
          // year={year}
          //   region={query.geography.region}
          //   county={query.geography.county}
          // setYear={setYear}
          //   layerID={statsLayerParam}
          // geoRoute={geoRoute}
          //   segmentsTable={segmentsTable}
          //   segmentSelection={segmentSelection}
          dataRoute="hpms/data"
          Zoom={8}
          position={[39.045753, -76.641273]}
          openDrawer={openDrawer}
        />
      )}
      {/* {leafletMap} */}
    </div>
  );
}
