import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

export default function ContactUsDialog(props) {
  const { mode } = props;
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          root: {
            color: mode === "dark" ? "white" : "",
            // backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
          paper: {
            color: mode === "dark" ? "white" : "",
            backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          // input: {
          //   border: `2px solid white`,
          //   color: mode === "dark" ? "white" : "",
          // },
          //
          root: {
            color: mode === "dark" ? "white" : "",
            // backgroundColor: mode === "dark" ? "#1E1E1E" : "",
          },
        },
      },
    },
  });
  const { open, setOpen } = props;
  const handleClose = () => setOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Contact Us</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            This dialog will contain contact information.
            <Link href="#" variant="email">
              l-white@tti.tamu.edu
            </Link>
          </DialogContentText> */}
          <Stack direction="column" spacing={0}>
            <DialogContentText id="alert-dialog-description">
              For more information or feedback, please contact:
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Nicole Katsikides
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Texas A&M Transportation Institute (TTI)
            </DialogContentText>
            <Link href="mailto:n-katsikides@tti.tamu.edu">
              n-katsikides@tti.tamu.edu
            </Link>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
