import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@material-ui/core/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
// import { QueryContext } from "./QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import { mapFocusCategories } from "../common//constants";

export function QuickInfoControl({
  position,
  // geoRoute,
  mapLineCategoriesTop100,
  mapLineCategories,
  selectedMapLineCategory,
  setSelectedMapLineCategory,
  checked,
  setChecked,
  minMaxValues,
  info,
  mode,
  routeId,
  toFrom,
  PopupData,
}) {
  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
  };
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  // let categoryDesc = "None";
  // if (query.geoRoute === "top100/geojson") {
  //   categoryDesc = mapLineCategoriesTop100.filter(
  //     (itm) => itm.all === selectedMapLineCategory
  //   )[0].description;
  // } else {
  //   categoryDesc = mapLineCategories.filter(
  //     (itm) => itm.all === selectedMapLineCategory
  //   )[0].description;
  // }
  let categoryDesc = mapFocusCategories.filter(
    (itm) =>
      itm.category === query.geoRoute && itm.description === query.mapFocus
  )[0].description;
  if (query.vehicleType === "truck") categoryDesc = "Truck " + categoryDesc;

  let maxValue = minMaxValues.max;
  let minValue = minMaxValues.min;
  let increm = (maxValue - minValue) * 0.2;

  const handleCheckChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const handleChangeMapFocus = (event) => {
    // console.log("event-check", event.target);
    setSelectedMapLineCategory(event.target.value);
    // setSelectedMapLineCategory("TTI");
  };

  function LegendControl() {
    // let categoryDesc = "None";
    let categoryDesc = mapFocusCategories.filter(
      (itm) =>
        itm.category === query.geoRoute && itm.description === query.mapFocus
    )[0].description;

    // if (query.geoRoute === "top100/geojson") {
    //   // categoryDesc = mapLineCategoriesTop100.filter(
    //   //   (itm) => itm.all === selectedMapLineCategory
    //   // )[0].description;

    //   if (query.vehicleType === "truck" && query.mapFocus !== "None")
    //     categoryDesc = "Truck " + categoryDesc;
    // } else {
    //   categoryDesc = mapLineCategories.filter(
    //     (itm) => itm.all === selectedMapLineCategory
    //   )[0].description;

    //   if (
    //     query.vehicleType === "truck" &&
    //     mapLineCategories.filter(
    //       (itm) => itm.all === selectedMapLineCategory
    //     )[0].all !==
    //       mapLineCategories.filter(
    //         (itm) => itm.all === selectedMapLineCategory
    //       )[0].truck
    //   )
    //     categoryDesc = "Truck " + categoryDesc;
    // }
    if (query.vehicleType === "truck" && query.mapFocus !== "None")
      categoryDesc = "Truck " + categoryDesc;

    let maxValue = minMaxValues.max;
    let minValue = minMaxValues.min;
    let increm = (maxValue - minValue) * 0.2;

    return (
      <Box
        sx={{
          width: 325,
          // height: 300,
          // backgroundColor: "primary.dark",
          // "&:hover": {
          //   backgroundColor: "primary.main",
          //   opacity: [0.9, 0.8, 0.7],
          // },
        }}
      >
        {/* <Typography sx={{ ml: 0.5, mt: 0, pt: 0 }}>
          <Box fontWeight="fontWeightBold">{"Legend: " + categoryDesc}</Box>
        </Typography> */}
        <Box
          sx={{
            // color: "text.primary",
            fontSize: 14,
            fontWeight: "fontWeightBold",
            mt: "2px",
          }}
        >
          {"Legend: " + categoryDesc}
        </Box>
        {selectedMapLineCategory !== "none" && (
          <Grid container spacing={0} sx={{ ml: 0, mr: 0 }}>
            {selectedMapLineCategory === "dlyrank" && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}
              >
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "red",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  {/* <Typography>1 - 10</Typography> */}
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    1 - 10
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "orange",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  {/* <Typography>11 - 25</Typography> */}
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    11 - 25
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "yellow",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    26 - 50
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "blue",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    51 - 100
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "green",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    101 +
                  </Box>
                </Grid>
              </Grid>
            )}
            {selectedMapLineCategory !== "dlyrank" && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ ml: 0, pl: 0 }}
              >
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "red",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    {(minValue + increm * 4).toLocaleString()} +
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "orange",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    {(minValue + increm * 3).toLocaleString()} -
                    {(minValue + increm * 4).toLocaleString()}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "yellow",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    {(minValue + increm * 2).toLocaleString()} -
                    {(minValue + increm * 3).toLocaleString()}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "blue",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    {(minValue + increm * 1).toLocaleString()} -
                    {(minValue + increm * 2).toLocaleString()}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ ml: 0, pl: 0 }}
                >
                  {" "}
                  <Divider
                    orientation="horizontal"
                    style={{
                      height: "4px",
                      width: "50%",
                      background: "green",
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Box
                    sx={{
                      // color: "text.primary",
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  >
                    {/* {minValue.toLocaleString()} - */}
                    {"< "}
                    {(minValue + increm * 1).toLocaleString()}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    );
  }

  // console.log("info-check", info);
  // marginBottom: "-7.5px"
  return (
    <div className={positionClass} style={{ marginBottom: "-7.5px" }}>
      <div className="leaflet-control leaflet-bar">
        <div className={"details " + mode} style={{ paddingBottom: "2px" }}>
          {/* <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={checked.truck}
                  onChange={handleCheckChange}
                  name="truck"
                  inputProps={{
                    title: "Show Truck",
                    "aria-label": "toggle truck data",
                  }}
                />
              }
              label={<Icon>local_shipping</Icon>}
              labelPlacement="start"
            />
            
            <Tooltip title="Show directional information">
              <FormControlLabel
                control={
                  <Switch
                    checked={checked.directional}
                    onChange={handleCheckChange}
                    name="directional"
                    inputProps={{
                      title: "Show Directional",
                      "aria-label": "toggle directional data",
                    }}
                    // disabled={directionalSwitch}
                  />
                }
                label={<Icon>swap_vert</Icon>}
                labelPlacement="start"
              />
            </Tooltip>
            

            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }}>
              <InputLabel shrink htmlFor="select-multiple-native">
                Map Focus
              </InputLabel>
              <Select
                value={selectedMapLineCategory}
                onChange={handleChangeMapFocus}
                label="Map Focus"
              >
                {query.geoRoute === "top100/geojson" &&
                  mapLineCategoriesTop100.map((name) => (
                    <MenuItem key={name.all} value={name.all}>
                      {name.description}
                    </MenuItem>
                  ))}
                {query.geoRoute !== "top100/geojson" &&
                  mapLineCategories.map((name) => (
                    <MenuItem key={name.all} value={name.all}>
                      {name.description}
                    </MenuItem>
                  ))}
                )
              </Select>
            </FormControl>
          </FormGroup> */}

          {typeof info.route_id === "undefined" &&
            typeof info.seg_name === "undefined" && (
              <Typography variant="subtitle1">
                Hover over a road to see data
              </Typography>
            )}
          {!(
            typeof info.route_id === "undefined" &&
            typeof info.seg_name === "undefined"
          ) && (
            <div>
              {/* <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked.truck}
                        onChange={handleCheckChange}
                        name="truck"
                        inputProps={{
                          title: "Show Truck",
                          "aria-label": "toggle truck data",
                        }}
                      />
                    }
                    label={<Icon>local_shipping</Icon>}
                    labelPlacement="start"
                  />
                  {(query.geoRoute === "hpms/geojson" ||
                    query.geoRoute === "hpms_segments/geojson") && (
                    <Tooltip title="Show directional information">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checked.directional}
                            onChange={handleCheckChange}
                            name="directional"
                            inputProps={{
                              title: "Show Directional",
                              "aria-label": "toggle directional data",
                            }}
                            disabled={directionalSwitch}
                          />
                        }
                        label={<Icon>swap_vert</Icon>}
                        labelPlacement="start"
                      />
                    </Tooltip>
                  )}
                </FormGroup> */}
              {/* 
              <p className="left-text">
                <strong>Year:</strong> {query.yearSelected}
              </p> */}
              <p className="left-text">
                <strong>Route ID:</strong> {routeId}
              </p>
              <p className="left-text">
                <strong>From/To:</strong> {toFrom}
              </p>

              {query.directionality === "crossSectional" &&
                query.vehicleType === "all" &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // All veh combined data.
                  <PopupData type="all" />
                )}
              {query.directionality === "crossSectional" &&
                query.vehicleType === "all" &&
                query.geoRoute === "top100/geojson" && (
                  // All veh combined data.
                  <PopupData type="top100" />
                )}
              {query.directionality === "directional" &&
                query.vehicleType === "all" &&
                query.geoRoute === "top100/geojson" && (
                  // All veh combined data.
                  <PopupData type="top100-directional" />
                )}
              {query.directionality === "crossSectional" &&
                query.vehicleType === "truck" &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // Truck combined data.
                  <PopupData type="truck" />
                )}
              {query.directionality === "crossSectional" &&
                query.vehicleType === "truck" &&
                query.geoRoute === "top100/geojson" && (
                  // Truck combined data.
                  <PopupData type="top100truck" />
                )}
              {query.directionality === "directional" &&
                query.vehicleType === "truck" &&
                query.geoRoute === "top100/geojson" && (
                  // Truck combined data.
                  <PopupData type="top100truck-directional" />
                )}
              {query.directionality === "directional" &&
                query.vehicleType === "all" &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // Truck combined data.
                  <PopupData type="truck" />
                ) && (
                  // Directional all veh data.
                  <div className="directional">
                    <div className="direction-1">
                      <div className="direction-title">
                        <strong>{info.direction1}</strong>
                      </div>
                      <PopupData type="all1" />
                    </div>
                    <div className="direction-2">
                      <div className="direction-title">
                        <strong>{info.direction2}</strong>
                      </div>
                      <PopupData type="all2" />
                    </div>
                  </div>
                )}
              {query.directionality === "directional" &&
                query.vehicleType === "truck" &&
                (query.geoRoute === "hpms/geojson" ||
                  query.geoRoute === "hpms_segments/geojson") && (
                  // Truck combined data.
                  <PopupData type="truck" />
                ) && (
                  // Directional truck data.
                  <div className="directional">
                    <div className="direction-1">
                      <div className="direction-title">
                        <strong>{info.direction1}</strong>
                      </div>
                      <PopupData type="truck1" />
                    </div>
                    <div className="direction-2">
                      <div className="direction-title">
                        <strong>{info.direction2}</strong>
                      </div>
                      <PopupData type="truck2" />
                    </div>
                  </div>
                )}
              {/* <div>Click segments to add to data table</div> */}
              {/* <p className="left-text">Click segments to add to data table</p> */}
            </div>
          )}

          <LegendControl />
        </div>
      </div>
    </div>
  );
}
