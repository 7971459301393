import React from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

export default function ShapefileDownloadDialog(props) {
  const { shapefileDownloadOpen, setShapefileDownloadOpen } = props;

  const handleClose = (e) => {
    setShapefileDownloadOpen(false);
  };

  return (
    <Dialog
      open={shapefileDownloadOpen}
      onClose={handleClose}
      key={"shapefileDownload"}
    >
      {/* <DialogTitle>Sign In</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To create an account, please enter your email address here, along with
          your password and hit the "Create Account" button. Otherwise, enter
          your username and password to log in.
        </DialogContentText> */}

      <DialogTitle>Downloads</DialogTitle>
      <DialogContentText style={{ marginLeft: "10px", marginRight: "10px" }}>
        The data behind MRPT can be downloaded using the following links.
        <DialogContent style={{ marginLeft: "10px", marginRight: "10px" }}>
          <Grid container spacing={1} sx={{ border: 0, boxShadow: "none" }}>
            <Grid container direction="row" sx={{ width: "100%" }} spacing={2}>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1, borderBottom: 1 }}>
                  <Typography href="https://arcg.is/1LyviT" target="_blank">
                    Statewide Shapefiles
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1, borderBottom: 1 }}>
                  <Typography href="https://arcg.is/1LyviT" target="_blank">
                    Top 100 Shapefiles
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1, borderBottom: 1 }}>
                  <Typography href="https://arcg.is/1LyviT" target="_blank">
                    Statewide
                    <Typography href="https://arcg.is/1LyviT" target="_blank">
                      Other Formats
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1, borderBottom: 1 }}>
                  <Typography href="https://arcg.is/1LyviT" target="_blank">
                    Top 100
                  </Typography>
                  <Typography href="https://arcg.is/1LyviT" target="_blank">
                    Other Formats
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="row" sx={{ width: "100%" }}>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/1u1GXy" target="_blank">
                      2022 Estimated Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2022 Estimated Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/1SzPD1" target="_blank">
                      2022 Estimated Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      // href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2022 Estimated Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/0WCnP1" target="_blank">
                      2021 Estimated Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2021 Estimated Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/0TnC0j" target="_blank">
                      2021 Estimated Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      // href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2021 Estimated Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/1LyviT" target="_blank">
                      2020 Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2020 Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link href="https://arcg.is/0TrzSO" target="_blank">
                      2020 Statewide
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                // style={{ border: "0px solid grey", marginRight: 10 }}
                // justifyContent="center"
                // align="right"
              >
                <Box sx={{ mb: 1 }}>
                  <Box sx={{ mb: 1 }}>
                    <Link
                      // href="#"
                      target="_blank"
                      style={{ color: "grey", textDecoration: "none" }}
                    >
                      2020 Top 100
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link href="https://arcg.is/0enLqK" target="_blank">
                        2019 Statewide
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link
                        href="#"
                        target="_blank"
                        style={{ color: "grey", textDecoration: "none" }}
                      >
                        2019 Top 100
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link href="https://arcg.is/1uzHvq0" target="_blank">
                        2019 Statewide
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link
                        // href="#"
                        target="_blank"
                        style={{ color: "grey", textDecoration: "none" }}
                      >
                        2019 Top 100
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row" sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link href="https://arcg.is/TyqaC" target="_blank">
                        2018 Statewide
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link
                        href="#"
                        target="_blank"
                        style={{ color: "grey", textDecoration: "none" }}
                      >
                        2018 Top 100
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link href="https://arcg.is/1e0e40" target="_blank">
                        2018 Statewide
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                  // style={{ border: "0px solid grey", marginRight: 10 }}
                  // justifyContent="center"
                  // align="right"
                >
                  <Box sx={{ mb: 1 }}>
                    <Box sx={{ mb: 1 }}>
                      <Link
                        // href="#"
                        target="_blank"
                        style={{ color: "grey", textDecoration: "none" }}
                      >
                        2018 Top 100
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContentText>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
