/**
Old deprecated method of downloading data is below return of current version.
**/
import React from "react";
import L, { divIcon } from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

export default function AirportsLayer(props) {
  const { layerRef, layerIcon } = props;

  const customMarkerIcon = divIcon({
    html: layerIcon,
  });

  const pointToLayer = (feature, latlng) => {
    let tooltipText = `
    <div>
      <p><strong>Airport:</strong> ${feature.properties.FULLNAME}</p>
    </div>
    `;
    // let popupText = `
    // <div>
    //   <div style="text-align: center;border-bottom: 2.0px solid black;">
    //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Airports</h3>
    //   </div>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.NAME}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>ICAO Code:</strong> ${feature.properties.ICAO_ID}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>City:</strong> ${feature.properties.SERVCITY}</p>
    //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operational Status:</strong> ${feature.properties.OPERSTATUS}</p>
    // </div>
    // `;
    let popupText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Airports</h3>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.FULLNAME}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>ID:</strong> ${feature.properties.LOCID}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>City:</strong> ${feature.properties.CITY_NAME}</p>
    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      url="https://services.arcgis.com/njFNhDsUCentVYJW/ArcGIS/rest/services/Maryland_Public_Use_Airports/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      pointToLayer={pointToLayer}
    />
  );
}

/**
Old deprecated method is below.
**/
// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { GeoJSON, LayerGroup } from "react-leaflet";
// import L from "leaflet";
// // import { QueryContext } from "../QueryContext";
// import { useGlobalState, useGlobalUpdater } from "../../common/GlobalContext";
// import Icon from "@material-ui/core/Icon";
// import { renderToStaticMarkup } from "react-dom/server";
// import { divIcon } from "leaflet";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     backgroundColor: "#A9A9A9",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     left: "-0.25rem",
//     top: "-0.25rem",
//     position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #000000",
//   },
// }));

// export default function AirportsLayer(props) {
//   // const [query, dispatch] = useContext(QueryContext);
//   const query = useGlobalState();
//   const dispatch = useGlobalUpdater();
//   const classes = useStyles();

//   const iconMarkup = renderToStaticMarkup(
//     <span className={classes.icon}>
//       <Icon fontSize="small" style={{ color: "#FFFFFF" }}>
//         flight
//       </Icon>
//     </span>
//   );
//   const customMarkerIcon = divIcon({
//     html: iconMarkup,
//   });

//   const [featuresData, setFeaturesData] = useState({
//     type: "FeatureCollection",
//     features: [],
//   });

//   useEffect(() => {
//     if (query.airportsFeatures.features.length === 0) {
//       const fetchMapData = async () => {
//         try {
//           if (featuresData.features.length === 0) {
//             let url =
//               "https://services.arcgis.com/njFNhDsUCentVYJW/ArcGIS/rest/services/Maryland_Public_Use_Airports/FeatureServer/0/query?where=FID+%3E%3D+0&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=FID%2CFULLNAME%2CLOCID%2CCITY_NAME&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=";
//             // "https://services2.arcgis.com/FiaPA4ga0iQKduv3/ArcGIS/rest/services/Airports/FeatureServer/0/query?where=FID%3E%3D0+and+STATE%3D%27MD%27&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=FID%2COBJECTID%2CGLOBAL_ID%2CIDENT+%2CNAME%2CLATITUDE%2CLONGITUDE%2CELEVATION%2CICAO_ID%2CTYPE_CODE%2CSERVCITY%2CSTATE%2CCOUNTRY%2COPERSTATUS%2CPRIVATEUSE%2CIAPEXISTS%2CDODHIFLIP%2CFAR91%2CFAR93%2CMIL_CODE%2CAIRANAL%2CUS_HIGH%2CUS_LOW%2CAK_HIGH%2CAK_LOW&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=";
//             const response = await axios.get(url);

//             dispatch({
//               type: "setAirportsFeatures",
//               selection: response.data,
//             });

//             setFeaturesData(response.data);
//           }
//         } catch (e) {
//           console.log(e);
//         }
//       };
//       fetchMapData();
//     } else {
//       setFeaturesData(query.airportsFeatures);
//     }
//   }, []);

//   const pointToLayer = (feature, latlng) => {
//     let tooltipText = `
//     <div>
//       <p><strong>Airport:</strong> ${feature.properties.FULLNAME}</p>
//     </div>
//     `;
//     // let popupText = `
//     // <div>
//     //   <div style="text-align: center;border-bottom: 2.0px solid black;">
//     //     <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Airports</h3>
//     //   </div>
//     //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.NAME}</p>
//     //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>ICAO Code:</strong> ${feature.properties.ICAO_ID}</p>
//     //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>City:</strong> ${feature.properties.SERVCITY}</p>
//     //   <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operational Status:</strong> ${feature.properties.OPERSTATUS}</p>
//     // </div>
//     // `;
//     let popupText = `
//     <div>
//       <div style="text-align: center;border-bottom: 2.0px solid black;">
//         <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Airports</h3>
//       </div>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.FULLNAME}</p>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>ID:</strong> ${feature.properties.LOCID}</p>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>City:</strong> ${feature.properties.CITY_NAME}</p>
//     </div>
//     `;
//     return L.marker(latlng, {
//       icon: customMarkerIcon,
//     })
//       .bindTooltip(tooltipText)
//       .bindPopup(popupText);
//   };

//   return (
//     <LayerGroup>
//       {featuresData.features.length > 0 && (
//         <GeoJSON data={featuresData} pointToLayer={pointToLayer} />
//       )}
//     </LayerGroup>
//   );
// }
