/**
Old deprecated method of downloading data is below return of current version.
**/
import React from "react";
import L, { divIcon } from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

export default function DistrictBoundaryLayer(props) {
  const { layerRef, layerIcon } = props;

  const customMarkerIcon = divIcon({
    html: layerIcon,
  });

  const onEachFeature = (feature, layer) => {
    let tooltipText = `
      <div>
        <p><strong>County:</strong> ${feature.properties.COUNTY}</p>
      </div>
      `;
    let popupText = `
      <div>
        <div style="text-align: center;border-bottom: 2.0px solid black;">
          <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Counties</h3>
        </div>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${feature.properties.COUNTY}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County Code:</strong> ${feature.properties.COUNTYNUM}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>County FIPS:</strong> ${feature.properties.COUNTY_FIP}</p>
        <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>District:</strong> ${feature.properties.DISTRICT}</p>
      </div>
      `;
    return layer
      .setStyle({
        color: "#6495ED",
        // fillColor: "#ffa305",
        weight: 4,
        fillOpacity: 0.1,
      })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      url="https://services.arcgis.com/njFNhDsUCentVYJW/arcgis/rest/services/MDOT_SHA_District_Boundaries/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      // pointToLayer={pointToLayer}
      onEachFeature={onEachFeature}
    />
  );
}
