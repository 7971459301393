/**
Old deprecated method of downloading data is below return of current version.
**/
import React from "react";
import L, { divIcon } from "leaflet";

import { FeatureLayer } from "react-esri-leaflet";

export default function PortsLayer(props) {
  const { layerRef, layerIcon } = props;

  const customMarkerIcon = divIcon({
    html: layerIcon,
  });

  const pointToLayer = (feature, latlng) => {
    let tooltipText = `
    <div>
      <p><strong>Port:</strong> ${feature.properties.Port_Name}</p>
    </div>
    `;
    let popupText = `
    <div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports</h3>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name: </strong>${feature.properties.Port_Name}</p>
    </div>
    `;
    return L.marker(latlng, {
      icon: customMarkerIcon,
    })
      .bindTooltip(tooltipText)
      .bindPopup(popupText);
  };

  return (
    <FeatureLayer
      ref={layerRef}
      // where={`CITY = ${city}`}
      url="https://services.arcgis.com/njFNhDsUCentVYJW/ArcGIS/rest/services/Port_2016_FreightStoryMap/FeatureServer/0"
      // eventHandlers={{
      //   loading: () => {
      //     console.log("featurelayer loading");
      //   },
      //   load: () => {
      //     console.log("featurelayer loaded");
      //     if (layerRef && layerRef.current) {
      //       layerRef.current.metadata((error, data) => {
      //         console.log("featurelayer metadata:", data);
      //       });
      //     }
      //   },
      // }}
      pointToLayer={pointToLayer}
    />
  );
}

/**
Old deprecated method is below.
**/
// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { GeoJSON, LayerGroup } from "react-leaflet";
// import L from "leaflet";
// // import { QueryContext } from "../QueryContext";
// import { useGlobalState, useGlobalUpdater } from "../../common/GlobalContext";
// import Icon from "@material-ui/core/Icon";
// import { renderToStaticMarkup } from "react-dom/server";
// import { divIcon } from "leaflet";
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     backgroundColor: "#0000ff",
//     width: "1.25rem",
//     height: "1.25rem",
//     display: "block",
//     left: "-0.25rem",
//     top: "-0.25rem",
//     position: "relative",
//     // borderRadius: "3rem 3rem 0",
//     // transform: "rotate(45deg)",
//     // border: "1px solid #FFFFFF",
//   },
// }));

// export default function PortsLayer(props) {
//   // const [query, dispatch] = useContext(QueryContext);
//   const query = useGlobalState();
//   const dispatch = useGlobalUpdater();
//   const classes = useStyles();

//   const iconMarkup = renderToStaticMarkup(
//     <span className={classes.icon}>
//       <Icon fontSize="small" style={{ color: "#ffffff" }}>
//         anchor
//       </Icon>
//     </span>
//   );
//   const customMarkerIcon = divIcon({
//     html: iconMarkup,
//   });

//   const [featuresData, setFeaturesData] = useState({
//     type: "FeatureCollection",
//     features: [],
//   });

//   useEffect(() => {
//     if (query.portsFeatures.features.length === 0) {
//       const fetchMapData = async () => {
//         try {
//           if (featuresData.features.length === 0) {
//             let url =
//               "https://services.arcgis.com/njFNhDsUCentVYJW/ArcGIS/rest/services/Port_2016_FreightStoryMap/FeatureServer/0/query?where=OBJECTID+%3E%3D+0&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=Port_Name&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=";
//             const response = await axios.get(url);

//             dispatch({
//               type: "setPortsFeatures",
//               selection: response.data,
//             });

//             setFeaturesData(response.data);
//           }
//         } catch (e) {
//           console.log(e);
//         }
//       };
//       fetchMapData();
//     } else {
//       setFeaturesData(query.portsFeatures);
//     }
//   }, []);

//   const pointToLayer = (feature, latlng) => {
//     let tooltipText = `
//     <div>
//       <p><strong>Port:</strong> ${feature.properties.Port_Name}</p>
//     </div>
//     `;
//     let popupText = `
//     <div>
//       <div style="text-align: center;border-bottom: 2.0px solid black;">
//         <h3 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports</h3>
//       </div>
//       <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name: </strong>${feature.properties.Port_Name}</p>
//     </div>
//     `;
//     return L.marker(latlng, {
//       icon: customMarkerIcon,
//     })
//       .bindTooltip(tooltipText)
//       .bindPopup(popupText);
//   };

//   return (
//     <LayerGroup>
//       {featuresData.features.length > 0 && (
//         <GeoJSON data={featuresData} pointToLayer={pointToLayer} />
//       )}
//     </LayerGroup>
//   );
// }
