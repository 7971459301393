import React, { useContext } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// import { QueryContext } from "../map/QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import Collapse from "@material-ui/core/Collapse";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Filter1Icon from "@material-ui/icons/Filter1";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  checkboxLabel: {
    fontSize: "0.75rem",
  },
  colselectColumn2: {
    paddingLeft: 0,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(2),
  },
  filteredIcon: {
    marginLeft: "12.25vw",
  },
  selectAllButton: {
    paddingLeft: 15,
    alignItems: "center",
  },
  header: {
    padding: 0,
    margin: 0,
    alignItems: "left",
  },
  formControlLabel: {
    fontSize: 14,
    whiteSpace: "normal",
  },
}));

const GeneralFilter = (props) => {
  const { filters, dispatchType, menuTitle } = props;

  const classes = useStyles();

  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();

  const [open, setOpen] = React.useState(false);
  const [filtered, setFiltered] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (event) => {
    let filtersTemp = [];
    filters.forEach((row) => {
      if (row.id === event.target.name) {
        row.checked = !row.checked;
        return filtersTemp.push(row);
      }
      filtersTemp.push(row);
    });

    setFiltered(false);
    filtersTemp.forEach((row) => {
      if (row.checked === false) {
        setFiltered(true);
      }
    });

    dispatch({
      type: dispatchType,
      selection: filtersTemp,
    });
  };

  const handleSelectAll = (event) => {
    let filtersTemp = [];
    filters.forEach((row) => {
      row.checked = true;
      filtersTemp.push(row);
    });

    setFiltered(false);

    dispatch({
      type: dispatchType,
      selection: filtersTemp,
    });
  };

  const handleUnSelectAll = (event) => {
    let filtersTemp = [];
    filters.forEach((row) => {
      row.checked = false;
      filtersTemp.push(row);
    });

    setFiltered(true);

    dispatch({
      type: dispatchType,
      selection: filtersTemp,
    });
  };

  return (
    <div>
      <Box sx={{ width: 205, whiteSpace: "normal" }}>
        <MenuItem
          onClick={handleClick}
          sx={{ width: 205, whiteSpace: "normal" }}
        >
          <Grid
            container
            alignItems="flex-start"
            // className={classes.selectAllButton}
            className={classes.header}
            spacing={0}
            style={{ fontSize: 14, whiteSpace: "normal" }}
          >
            <Grid item xs={8}>
              {menuTitle}
              {/* {open ? <ExpandLess /> : <ExpandMore />} */}
            </Grid>
            <Grid item xs={1}>
              {/* {menuTitle} */}
              {open ? <ExpandLess /> : <ExpandMore />}
            </Grid>
            <Grid item xs={1}>
              {filtered && (
                <Filter1Icon
                  fontSize="small"
                  className={classes.filteredIcon}
                />
              )}
            </Grid>
          </Grid>
        </MenuItem>
        {open && (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ fontSize: 4 }}
          >
            <Grid
              container
              // alignItems="center"
              spacing={0}
              // justify="space-around"
              // style={{ fontSize: 4 }}
              style={{ fontSize: 14, whiteSpace: "normal" }}
            >
              <Grid item xs={5}>
                <Button color="secondary" onClick={handleSelectAll}>
                  Select All
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button color="secondary" onClick={handleUnSelectAll}>
                  Unselect All
                </Button>
              </Grid>
            </Grid>
            <FormGroup
              className={classes.colselectColumn2}
              // style={{ fontSize: 4 }}
              style={{ fontSize: 14, whiteSpace: "normal" }}
            >
              <Box sx={{ width: 200, whiteSpace: "normal" }}>
                {filters.map((col, index) => (
                  <FormControlLabel
                    style={{ fontSize: 14, whiteSpace: "normal" }}
                    control={
                      <Checkbox
                        name={col.id}
                        key={`check-rdsegment-col-${index}`}
                        checked={col.checked}
                        onChange={handleChange}
                      />
                    } //onChange={handleChange}
                    // label={col.label}
                    key={`check-rdsegment-col-fgl-${index}`}
                    label={
                      <Typography
                        style={{ fontSize: 14, whiteSpace: "normal" }}
                      >
                        {col.label}
                      </Typography>
                    }
                  />
                ))}
              </Box>
            </FormGroup>
          </Collapse>
        )}
      </Box>
    </div>
  );
};

export default GeneralFilter;
