/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  Popup,
  ZoomControl,
  useMap,
  LayerGroup,
} from "react-leaflet";

// import Control from "react-leaflet-control";
import {
  COLOR_LINES,
  POPUP_GROUPS,
  // SUMMARY_VARIABLES_HPMS,
  SUMMARY_VARIABLES_TOP100,
  commodity_val_consts,
  // yearOptions,
  // geographic_options,
} from "../common/constants";

// import { QueryContext } from "./QueryContext";
import { useGlobalState, useGlobalUpdater } from "../common/GlobalContext";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import BeatLoader from "react-spinners/BeatLoader";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import bbox from "@turf/bbox";

// import MapFilter from "./MapFilter";

import TollPlazasLayer from "./other_layers/TollPlazasLayer";
import PortsLayer from "./other_layers/PortsLayer";
import OtherTerminalsLayer from "./other_layers/OtherTerminalsLayer";
import IntermodalFacilityLayer from "./other_layers/IntermodalFacilityLayer";
import CargoAirportsLayer from "./other_layers/CargoAirportsLayer";
import AutoTerminalsLayer from "./other_layers/AutoTerminalsLayer";
import DistributionCentersLayer from "./other_layers/DistributionCentersLayer";
import TruckWeighInspStationsLayer from "./other_layers/TruckWeighInspStationsLayer";
import AirportsLayer from "./other_layers/AirportsLayer";
import CountyBoundaryLayer from "./other_layers/CountyBoundaryLayer";
import DistrictBoundaryLayer from "./other_layers/DistrictBoundaryLayer";
import TruckParkingLayer from "./other_layers/TruckParkingLayer";
// import Tooltip from "@mui/material/Tooltip";

import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import { TiledMapLayer } from "react-esri-leaflet";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { QuickInfoControl } from "./QuickInfoControl";

import { mapFocusCategories } from "../common/constants";

import CtpLayer from "./other_layers/CtpLayer";
import CrabLayer from "./other_layers/CrabLayer";
import ShaProjectsLayer from "./other_layers/ShaProjectsLayer";
import LandUse2010 from "./other_layers/LandUse2010";

import { renderToStaticMarkup } from "react-dom/server";
import ConstructionIcon from "@mui/icons-material/Construction";
import FlightIcon from "@mui/icons-material/Flight";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AnchorIcon from "@mui/icons-material/Anchor";
import TollIcon from "@mui/icons-material/Toll";
import LocalParkingIcon from "@mui/icons-material/LocalParking";

import { Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "1rem",
    minWidth: "275",
  },
  linearProgress: {
    marginTop: "5px",
  },
  lineStyle: {
    "&:hover": {
      stroke: COLOR_LINES.selected,
      strokeWidth: 6,
    },
  },
  layermapcontrol: {
    // position: relative;
    // padding: 12px;
    // margin-top: 525px;
    // margin-bottom: 75px;
    width: "150px",
  },
  icon: {
    backgroundColor: "#A9A9A9",
    // width: "1.25rem",
    // height: "1.25rem",
    // display: "block",
    // left: "-0.25rem",
    // top: "-0.25rem",
    // position: "relative",
  },
}));

function SetViewOnClick({ bounds }) {
  const map = useMap();
  map.fitBounds(bounds);

  return null;
}

/**
 * Renders the Leaflet map with the HPMS road segments
 *
 * @param {*} props React props
 */
function LeafletMap(props) {
  // console.log("props-check", props);
  // const { geoRoute } = props;

  const { openDrawer } = props;

  // const [query, dispatch] = useContext(QueryContext);
  const query = useGlobalState();
  const dispatch = useGlobalUpdater();
  // console.log("renderTop100Map-check", query.layerSpecs, query.statsLayerDesc);

  const [top100Filters] = useState(query.top100Filters);

  const [info, setInfo] = useState({});
  const [infoDirectional, setInfoDirectional] = useState([]);
  const [checked, setChecked] = useState({
    truck: false,
    directional: false,
  });

  const [map, setMap] = useState(null);

  const lineWeights = { normal: 3, selected: 8, minimized: 1 };

  function setBounds(fc) {
    if (fc.length > 0) {
      let mapBounds_bbox = bbox({ type: "FeatureCollection", features: fc });
      // console.log("fc-check", fc);
      // console.log("mapBounds_bbox-check", mapBounds_bbox);
      setMapBounds([
        [mapBounds_bbox[1], mapBounds_bbox[0]],
        [mapBounds_bbox[3], mapBounds_bbox[2]],
      ]);
      if (map)
        map.fitBounds([
          [mapBounds_bbox[1], mapBounds_bbox[0]],
          [mapBounds_bbox[3], mapBounds_bbox[2]],
        ]);
    }
  }

  function setCommodityValueDir(f_system, aadt_combi, aadt_singl) {
    let commodityvalue = 0;
    if ((f_system = 1)) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.ihcoton *
          commodity_val_consts.ihval +
          (aadt_singl / 2) *
            commodity_val_consts.ihsiton *
            commodity_val_consts.ihval) *
          365) /
        1000000;
      return commodityvalue;
    }
    if ((f_system = 2)) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.fwycoton *
          commodity_val_consts.fwyval +
          (aadt_singl / 2) *
            commodity_val_consts.fwysiton *
            commodity_val_consts.fwyval) *
          365) /
        1000000;
      return commodityvalue;
    }
    if (f_system > 2) {
      commodityvalue =
        (((aadt_combi / 2) *
          commodity_val_consts.pascoton *
          commodity_val_consts.pasval +
          (aadt_singl / 2) *
            commodity_val_consts.passiton *
            commodity_val_consts.pasval) *
          365) /
        1000000;
      return commodityvalue;
    }
  }
  // const [featureCount, setFeatureCount] = useState(0);
  // const [loadingProgress, setLoadingProgress] = useState(0);
  const classes = useStyles();

  // get a ref to the underlying L.geoJSON
  const geoJsonRef = useRef();
  // console.log("fullHPMSdata-check", fullHPMSdata);
  const [top100ApiResponses, setTop100ApiResponses] = useState({
    data: [],
    directionalData: [],
    geometry: {
      type: "FeatureCollection",
      features: [],
    },
  });

  useEffect(() => {
    setTop100ApiResponses({
      data: [],
      directionalData: [],
      geometry: {
        type: "FeatureCollection",
        features: [],
      },
    });

    async function test() {
      let response = undefined;
      let promiseArray = [];
      let urls = [
        `${process.env.REACT_APP_API_ROOT}/roads/findTop100DataByYear?year=${query.yearSelected}`,
        `${process.env.REACT_APP_API_ROOT}/roads/findTop100DirectionalDataByYear?year=${query.yearSelected}`,
        `${process.env.REACT_APP_API_ROOT}/roads/findTop100GeomByYear?year=${query.yearSelected}`,
      ];
      promiseArray = urls.map((r, index) => {
        return axios.get(r);
      });

      response = await axios.all(promiseArray);
      // console.log("response-check", response);

      let f = response[0].data.sort((a, b) => b["dlyrank"] - a["dlyrank"]);
      let fcData = f.map((row) => {
        // row.color = COLOR_LINES.normal;
        // row.weight = lineWeights.normal;
        row.id = row.mergekey;
        row.tttrest = Math.max(
          row.ttr95a,
          row.ttr95p,
          row.ttr95o,
          row.ttr95m,
          row.ttr95w
        );

        return row;
      });
      // console.log("fc-check", fc);
      // if (index === 0) {
      //   console.log("fc-check", fc);
      //   settop100ApiResponses.data(fc);
      //   dispatch({
      //     type: "setFeatures",
      //     selection: fc,
      //   });
      // } else {
      //   settop100ApiResponses.directionalData(fc);
      // }

      let fDirectional = response[1].data.sort(
        (a, b) => b["dlyrank"] - a["dlyrank"]
      );
      let fcDirectional = fDirectional.map((row) => {
        // row.color = COLOR_LINES.normal;
        // row.weight = lineWeights.normal;
        row.id = row.mergekey;
        row.tttrest = Math.max(
          row.ttr95a,
          row.ttr95p,
          row.ttr95o,
          row.ttr95m,
          row.ttr95w
        );

        return row;
      });

      let fTemp = [];
      f = response[2].data;
      let fc = f.map((row) => {
        return setRowData(row);
      });
      fTemp = fTemp.concat(fc);

      // console.log("query.geography-check", query.geography, fcDirectional);
      if (query.geography.region === "top-100-monitored-roads")
        setTop100ApiResponses({
          data: fcData,
          directionalData: fcDirectional,
          geometry: {
            type: "FeatureCollection",
            features: fTemp,
          },
        });

      if (query.geography.region === "top-100-all-vehicle-bottlenecks") {
        fcData = fcData.filter((row) => row.dlyrank > 0 && row.dlyrank <= 100);
        let keepMergekey = [];
        fcData.map((itm) => {
          keepMergekey.push(itm.mergekey);
        });
        let hpmsGeoFiltered = [];
        fTemp.map((itm) => {
          if (keepMergekey.includes(itm.properties.mergekey))
            hpmsGeoFiltered.push(itm);
        });
        let fcDirectionalFiltered = [];
        fcDirectional.map((itm) => {
          if (keepMergekey.includes(itm.mergekey))
            fcDirectionalFiltered.push(itm);
        });
        setTop100ApiResponses({
          data: fcData,
          directionalData: fcDirectionalFiltered,
          geometry: {
            type: "FeatureCollection",
            features: hpmsGeoFiltered,
          },
        });
      }
      if (
        query.geography.region === "directional-top-100-all-vehicle-bottlenecks"
      ) {
        fcDirectional = fcDirectional.filter(
          (row) => row.dlyrank > 0 && row.dlyrank <= 100
        );
        let keepMergekey = [];
        fcDirectional.map((itm) => {
          keepMergekey.push(itm.mergekey);
        });
        let hpmsGeoFiltered = [];
        fTemp.map((itm) => {
          if (keepMergekey.includes(itm.properties.mergekey))
            hpmsGeoFiltered.push(itm);
        });
        let fcDataFiltered = [];
        fcData.map((itm) => {
          if (keepMergekey.includes(itm.mergekey)) fcDataFiltered.push(itm);
        });
        setTop100ApiResponses({
          data: fcDataFiltered,
          directionalData: fcDirectional,
          geometry: {
            type: "FeatureCollection",
            features: hpmsGeoFiltered,
          },
        });
      }

      if (query.geography.region === "top-100-truck-bottlenecks") {
        fcData = fcData.filter(
          (row) => row.trkdlyrank > 0 && row.trkdlyrank <= 100
        );
        let keepMergekey = [];
        fcData.map((itm) => {
          keepMergekey.push(itm.mergekey);
        });
        let hpmsGeoFiltered = [];
        fTemp.map((itm) => {
          if (keepMergekey.includes(itm.properties.mergekey))
            hpmsGeoFiltered.push(itm);
        });
        // let fcDirectionalFiltered = [];
        // fcDirectional.map((itm) => {
        //   if (keepMergekey.includes(itm.properties.mergekey))
        //     fcDirectionalFiltered.push(itm);
        // });
        setTop100ApiResponses({
          data: fcData,
          directionalData: fcDirectional,
          geometry: {
            type: "FeatureCollection",
            features: hpmsGeoFiltered,
          },
        });
      }
      if (query.geography.region === "directional-top-100-truck-bottlenecks") {
        fcDirectional = fcDirectional.filter(
          (row) => row.trkdlyrank > 0 && row.trkdlyrank <= 100
        );
        let keepMergekey = [];
        fcDirectional.map((itm) => {
          keepMergekey.push(itm.mergekey);
        });
        let hpmsGeoFiltered = [];
        fTemp.map((itm) => {
          if (keepMergekey.includes(itm.properties.mergekey))
            hpmsGeoFiltered.push(itm);
        });
        let fcDataFiltered = [];
        fcData.map((itm) => {
          if (keepMergekey.includes(itm.mergekey)) fcDataFiltered.push(itm);
        });
        setTop100ApiResponses({
          data: fcDataFiltered,
          directionalData: fcDirectional,
          geometry: {
            type: "FeatureCollection",
            features: hpmsGeoFiltered,
          },
        });
      }
      // console.log("response-check", response);

      if (query.directionality === "directional")
        dispatch({
          type: "setFeatures",
          selection: fcDirectional,
        });

      if (query.directionality === "crossSectional")
        dispatch({
          type: "setFeatures",
          selection: fcData,
        });
    }
    test();

    function setRowData(obj) {
      // obj.color = COLOR_LINES.normal;
      // obj.weight = lineWeights.normal;
      obj.properties.id = obj.id;

      obj.properties.tttrest = Math.max(
        obj.properties.ttr95a,
        obj.properties.ttr95p,
        obj.properties.ttr95o,
        obj.properties.ttr95m,
        obj.properties.ttr95w
      );
      return obj;
    }
  }, [query.yearSelected, query.geography.region]);
  // console.log("top100ApiResponses-check", top100ApiResponses);
  useEffect(() => {
    if (geoJsonRef.current) {
      geoJsonRef.current.clearLayers(); // remove old data
      geoJsonRef.current.addData(top100ApiResponses.geometry); // might need to be geojson.features
    }

    setBounds(top100ApiResponses.geometry.features);
  }, [top100ApiResponses]);

  // useEffect(() => {
  //   setChecked({ ...checked, directional: false });
  //   if (query.yearSelected != 2018) {
  //     setDirectionalSwitch(true);
  //   } else {
  //     setDirectionalSwitch(false);
  //   }
  // }, [query.yearSelected]);

  useEffect(() => {
    if (query.directionality === "directional")
      dispatch({
        type: "setFeatures",
        selection: top100ApiResponses.directionalData,
      });

    if (query.directionality === "crossSectional")
      dispatch({
        type: "setFeatures",
        selection: top100ApiResponses.data,
      });
  }, [query.directionality]);

  useEffect(() => {
    // console.log("query-check", query);
    if (top100ApiResponses.data.length > 0) {
      // if (query.statsLayerDesc === "Top 100 Bottlenecks") {
      let fc_filter2Data = top100ApiResponses.data;
      if (query.directionality === "directional")
        fc_filter2Data = top100ApiResponses.directionalData;
      top100Filters.forEach((filterRow) => {
        //(query.top100Filters).map(filterRow => {
        if (!filterRow.checked) {
          if (filterRow.id === "1-5")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 1 || row.dlyrank > 5
            ));
          if (filterRow.id === "6-10")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 6 || row.dlyrank > 10
            ));
          if (filterRow.id === "11-25")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 11 || row.dlyrank > 25
            ));
          if (filterRow.id === "26-50")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 26 || row.dlyrank > 50
            ));
          if (filterRow.id === "51-100")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 51 || row.dlyrank > 100
            ));
          if (filterRow.id === "101-150")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 101 || row.dlyrank > 150
            ));
          if (filterRow.id === "101-150")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 101 || row.dlyrank > 150
            ));
          if (filterRow.id === "151-plus")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.dlyrank < 151
            ));
        }
      });

      query.truckTop100Filters.forEach((filterRow) => {
        if (!filterRow.checked) {
          if (filterRow.id === "1-5")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 1 || row.trkdlyrank > 5
            ));
          if (filterRow.id === "6-10")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 6 || row.trkdlyrank > 10
            ));
          if (filterRow.id === "11-25")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 11 || row.trkdlyrank > 25
            ));
          if (filterRow.id === "26-50")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 26 || row.trkdlyrank > 50
            ));
          if (filterRow.id === "51-100")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 51 || row.trkdlyrank > 100
            ));
          if (filterRow.id === "101-150")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 101 || row.trkdlyrank > 150
            ));
          if (filterRow.id === "101-150")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 101 || row.trkdlyrank > 150
            ));
          if (filterRow.id === "151-plus")
            return (fc_filter2Data = fc_filter2Data.filter(
              (row) => row.trkdlyrank < 151
            ));
        }
      });
      let keepMergekey = [];
      if (query.directionality === "crossSectional")
        fc_filter2Data.map((itm) => {
          keepMergekey.push(itm.mergekey);
        });
      if (query.directionality === "directional")
        fc_filter2Data.map((itm) => {
          if (keepMergekey.includes(itm.mergekey)) return;
          keepMergekey.push(itm.mergekey);
        });
      // console.log("keepMergekey-check", top100ApiResponses.geometry.features);

      // console.log("fc_filter2Data-check", fc_filter2Data, keepMergekey);
      let hpmsGeoFiltered = [];
      top100ApiResponses.geometry.features.map((itm) => {
        if (keepMergekey.includes(itm.properties.mergekey))
          hpmsGeoFiltered.push(itm);
      });
      // console.log(
      //   "fc_filter2Data-check",
      //   fc_filter2Data,
      //   keepMergekey,
      //   hpmsGeoFiltered
      // );
      if (geoJsonRef.current) {
        geoJsonRef.current.clearLayers(); // remove old data
        geoJsonRef.current.addData({
          type: "FeatureCollection",
          features: hpmsGeoFiltered,
        }); // might need to be geojson.features
      }
      // console.log("hpmsGeoFiltered-check", hpmsGeoFiltered);
      setBounds(hpmsGeoFiltered);
      // }
    }
  }, [query.top100Filters, query.truckTop100Filters, top100ApiResponses.data]);

  useEffect(() => {
    // console.log("map-check", map);
    // console.log("mapBounds-check", mapBounds);
    // if (map) homeButton.click();
    if (map) map.fitBounds(mapBounds);
  }, [mapBounds]);

  // const getHPMSStyle = (feature) => {
  //   return { color: feature.color, weight: feature.weight };
  // };

  /* Highlights on mouseOver */
  // const handleMouseOver = (feature, e) => {
  function handleMouseOver(feature, e) {
    setInfo(
      top100ApiResponses.data.filter(
        (itm) => itm.mergekey === feature.properties.mergekey
      )[0]
    );
    setInfoDirectional(
      top100ApiResponses.directionalData.filter(
        (itm) => itm.mergekey === feature.properties.mergekey
      )
    );
  }

  /* Reset default style on mouseOut */
  const handleMouseOut = (feature, e) => {
    const layer = e.target;
    // feature.color = hpmsColorContainer.current;
    // feature.weight = hpmsWeightContainer.current;
    // layer.setStyle({
    //   weight: feature.weight,
    //   color: feature.color,
    //   dashArray: "",
    //   fillOpacity: 0.7,
    // });
  };

  /* Tracks user road selection process*/
  const defaultUserSelection = {
    from_route_id: "",
    from_beg_point: Number.Nan,
    from_end_point: Number.NaN,
    to_beg_point: Number.NaN,
    to_end_point: Number.NaN,
  };

  const userSelection = useRef(defaultUserSelection);

  function handleAddSegment() {
    // console.log("popuRef-check", popupRef);
    // let featureProps =
    //   popupRef.current._source.feature.properties;
    let featureProps = popupRef.current._source.feature.properties;

    dispatch({
      type: "setUserSelection",
      selection: {
        route_id: "",
        beg_point: NaN,
        end_point: NaN,
      },
    });

    dispatch({
      type: "setUserSelectionMD100",
      selection: {
        route_id: featureProps.seg_name,
        beg_point: featureProps.seg_from,
        end_point: featureProps.seg_to,
        mergekey: featureProps.mergekey,
      },
    });

    dispatch({
      type: "setUserSelectedSegments",
      selection: query.userSelectedSegments.concat({
        route_id: featureProps.seg_name,
        beg_point: featureProps.seg_from,
        end_point: featureProps.seg_to,
        mergekey: featureProps.mergekey,
      }),
    });

    popupRef.current.remove(); //closes the popup
  }

  /* @see See https://jsfiddle.net/thbh99nu/2/ */
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: handleMouseOver.bind(null, feature),
      mouseout: handleMouseOut.bind(null, feature),
      click: handleClick.bind(null, feature, layer),
    });
  };
  const mapLineCategoriesTop100 = [
    { description: "None", all: "none", truck: "none" },
    { description: "Ranking", all: "dlyrank", truck: "trkdlyrank" },
    { description: "Delay/mile", all: "dlymile", truck: "trkdlymile" },
    { description: "TTI", all: "tti", truck: "trktti" },
    { description: "PTI 95", all: "pti95", truck: "trkpti95" },
    { description: "TTTR - Est.", all: "tttrest", truck: "tttrest" },
    { description: "IRI", all: "iri", truck: "iri" },
    {
      description: "Commodity Value",
      all: "commodity_value",
      truck: "commodity_value",
    },
    {
      description: "Congested CO2 Lbs",
      all: "dayghg",
      truck: "dayghgtrk",
    },
    {
      description: "Normal CO2 Lbs",
      all: "dayghgbase",
      truck: "dayghgba_1",
    },
  ];
  const mapLineCategories = [
    { description: "None", all: "none", truck: "none" },
    { description: "Delay", all: "delay", truck: "trkdelay" },
    { description: "TTI", all: "tti", truck: "trktti" },
    { description: "PTI 95", all: "pti95", truck: "trkpti95" },
    { description: "TTTR - Est.", all: "tttrest", truck: "tttrest" },
    { description: "IRI", all: "iri", truck: "iri" },
    {
      description: "Commodity Value",
      all: "commodity_value",
      truck: "commodity_value",
    },
    {
      description: "Congested CO2 (Tons)",
      all: "ghg",
      truck: "ghgtrk",
    },
    {
      description: "Normal CO2 (Tons)",
      all: "ghgbase",
      truck: "ghgtrkbase",
    },
  ];
  const [selectedMapLineCategory, setSelectedMapLineCategory] =
    useState("none");
  // useMemo(() => {
  //   setSelectedMapLineCategory("none");
  // }, []);
  useMemo(() => {
    // console.log(
    //   "mapFocus-test-check",
    //   query.mapFocus,
    //   mapFocusCategories.filter(
    //     (itm) =>
    //       itm.category === query.geoRoute && itm.description === query.mapFocus
    //   )[0].all
    // );
    setSelectedMapLineCategory(
      mapFocusCategories.filter(
        (itm) =>
          itm.category === query.geoRoute && itm.description === query.mapFocus
      )[0].all
    );
  }, [query.mapFocus]);
  // console.log("query.mapFocus-check", query.mapFocus, selectedMapLineCategory);
  function setMapLineColor(value, maxValue, minValue) {
    // switch (district) {
    //   case "Matara":
    //     return "red";
    //   case "Polonnaruwa":
    //     return "brown";
    //   case "Ampara":
    //     return "purple";
    //   default:
    //     return "white";
    // }
    if (selectedMapLineCategory === "dlyrank") {
      // console.log("selectedMapLineCategory-check", selectedMapLineCategory);
      if (value > 0 && value <= 10) return "red";
      if (value > 10 && value <= 25) return "orange";
      if (value > 25 && value <= 50) return "yellow";
      if (value > 50 && value <= 100) return "blue";
      return "green";
    } else {
      let increm = (maxValue - minValue) * 0.2;
      if (value >= minValue + increm * 4) return "red";
      if (value >= minValue + increm * 3 && value < minValue + increm * 4)
        return "orange";
      if (value >= minValue + increm * 2 && value < minValue + increm * 3)
        return "yellow";
      if (value >= minValue + increm * 1 && value < minValue + increm * 2)
        return "blue";
      return "green";
      // if (value > 0.8 * maxValue && value <= maxValue) return "red";
      // if (value > 0.6 * maxValue && value <= 0.8 * maxValue) return "orange";
      // if (value > 0.4 * maxValue && value <= 0.6 * maxValue) return "yellow";
      // if (value > 0.2 * maxValue && value <= 0.4 * maxValue) return "blue";
      // return "green";
    }
  }
  function setMapLineWeight(value, maxValue, minValue) {
    // switch (district) {
    //   case "Matara":
    //     return "red";
    //   case "Polonnaruwa":
    //     return "brown";
    //   case "Ampara":
    //     return "purple";
    //   default:
    //     return "white";
    // }
    if (selectedMapLineCategory === "dlyrank") {
      // if (value > 0 && value <= 10) return 4;
      // if (value > 10 && value <= 25) return 3.5;
      // if (value > 25 && value <= 50) return 3;
      // if (value > 50 && value <= 100) return 2.5;
      // return 1.5;
      if (value > 0 && value <= 10) return 5;
      if (value > 10 && value <= 25) return 4;
      if (value > 25 && value <= 50) return 3;
      if (value > 50 && value <= 100) return 2;
      return 1;
    } else {
      // let increm = (maxValue - minValue) * 0.2;
      // if (value >= minValue + increm * 4) return 4;
      // if (value >= minValue + increm * 3 && value < minValue + increm * 4)
      //   return 3.5;
      // if (value >= minValue + increm * 2 && value < minValue + increm * 3)
      //   return 3;
      // if (value >= minValue + increm * 1 && value < minValue + increm * 2)
      //   return 2.5;
      // return 1.5;

      let increm = (maxValue - minValue) * 0.2;
      if (value >= minValue + increm * 4) return 5;
      if (value >= minValue + increm * 3 && value < minValue + increm * 4)
        return 4;
      if (value >= minValue + increm * 2 && value < minValue + increm * 3)
        return 3;
      if (value >= minValue + increm * 1 && value < minValue + increm * 2)
        return 2;
      return 1;
    }
  }

  function getTopJsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone.sort((a, b) => b[prop] - a[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getBottomJsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone.sort((a, b) => a[prop] - b[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getBottomJsonNNo0(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.filter((itm) => itm[prop] > 0).slice(0);

    let selectedRow = clone.sort((a, b) => a[prop] - b[prop]).slice(0, n || 1);

    return selectedRow[0][prop];
    // return selectedRow[0];
  }
  function getTopGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => b.properties[prop] - a.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
    // return selectedRow[0];
  }

  function getBottomGeojsonN(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.slice(0);

    let selectedRow = clone
      .sort((a, b) => a.properties[prop] - b.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
  }
  function getBottomGeojsonNNo0(arr, prop, n) {
    // clone before sorting, to preserve the original array
    var clone = arr.filter((itm) => itm.properties[prop] > 0).slice(0);

    let selectedRow = clone
      .sort((a, b) => a.properties[prop] - b.properties[prop])
      .slice(0, n || 1);

    return selectedRow[0].properties[prop];
  }

  const [minMaxValues, setMinMaxValues] = useState({ min: 10000000, max: 0 });
  useEffect(() => {
    // console.log("query.userSelectedRoads-check", query.userSelectedRoads);
    // console.log("checked.directional-check", checked.directional);
    if (selectedMapLineCategory !== "none") {
      if (
        top100ApiResponses.data.length > 0 &&
        query.directionality === "crossSectional"
      ) {
        let tempCategory = selectedMapLineCategory;
        if (query.vehicleType === "truck")
          tempCategory = mapLineCategoriesTop100.filter(
            (itm) => itm.all === selectedMapLineCategory
          )[0].truck;
        let maxValue = getTopJsonN(top100ApiResponses.data, tempCategory, 1);
        // let minValue = getBottomJsonN(top100ApiResponses.data, tempCategory, 1);
        let minValue = getBottomJsonNNo0(
          top100ApiResponses.data,
          tempCategory,
          1
        );
        setMinMaxValues({
          min: minValue,
          max: maxValue,
        });
      }
      if (
        top100ApiResponses.directionalData.length > 0 &&
        query.directionality === "directional"
      ) {
        let tempCategory = selectedMapLineCategory;
        if (query.vehicleType === "truck")
          tempCategory = mapLineCategoriesTop100.filter(
            (itm) => itm.all === selectedMapLineCategory
          )[0].truck;
        let maxValue = getTopJsonN(
          top100ApiResponses.directionalData,
          tempCategory,
          1
        );
        // let minValue = getBottomJsonN(top100ApiResponses.directionalData, tempCategory, 1);
        let minValue = getBottomJsonNNo0(
          top100ApiResponses.directionalData,
          tempCategory,
          1
        );
        setMinMaxValues({
          min: minValue,
          max: maxValue,
        });
      }
    }
  }, [
    query.geoRoute,
    query.vehicleType,
    selectedMapLineCategory,
    query.userSelectedRoads,
    query.directionality,
  ]);
  useEffect(() => {
    if (query.userSelectedRoads.length > 0)
      setBounds(
        top100ApiResponses.geometry.features.filter(
          (f) => query.userSelectedRoads.indexOf(f.id) > -1
        )
      );
    else setBounds(top100ApiResponses.geometry.features);
  }, [query.userSelectedRoads]);

  // const isSelected = query.highlightQuery;
  // let lineWeight = 3;
  // if (hpmsGeo.features.filter((f) => isSelected(f)).length > 0) {
  //   // setBounds(hpmsGeo.features.filter((f) => isSelected(f)));
  //   lineWeight = 1;
  // }

  function setMapLineStyle(feature) {
    // console.log("feature-check", feature);
    // console.log("checked.truck-check", checked.truck);
    // console.log(
    //   "tempCategory-check",
    //   mapLineCategoriesTop100.filter(
    //     (itm) => itm.all === selectedMapLineCategory
    //   ),
    //   selectedMapLineCategory
    // );
    if (
      top100ApiResponses.data.length > 0 ||
      query.geoRoute !== "top100/geojson"
    ) {
      const {
        properties: {
          mergekey,
          id,
          route_id,
          dlyrank,
          trkdlyrank,
          delay,
          trkdelay,
          tti,
          trktti,
          pti95,
          trkpti95,
          tttrest,
          delay1,
          trkdelay1,
          tti1,
          trktti1,
          pti951,
          trkpti951,
          tttrest1,
          delay2,
          trkdelay2,
          tti2,
          trktti2,
          pti952,
          trkpti952,
          tttrest2,
          iri,
          commodity_value,
        },
      } = feature;
      let lineWeight = 2;
      let lineColor = COLOR_LINES.normal;

      // Set lineColor, line weight

      if (selectedMapLineCategory !== "none") {
        if (query.directionality === "crossSectional") {
          if (query.vehicleType === "truck") {
            let tempCategory = mapLineCategoriesTop100.filter(
              (itm) => itm.all === selectedMapLineCategory
            )[0].truck;
            let tempValue = top100ApiResponses.data.filter(
              (itm) => itm.mergekey === mergekey
            )[0][tempCategory];
            // let maxValue = getTopJsonN(top100ApiResponses.data, tempCategory, 1);

            lineColor = setMapLineColor(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );

            lineWeight = setMapLineWeight(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );
          }
          if (query.vehicleType === "all") {
            let tempCategory = selectedMapLineCategory;
            let tempValue = top100ApiResponses.data.filter(
              (itm) => itm.mergekey === mergekey
            )[0][tempCategory];
            // let maxValue = getTopJsonN(top100ApiResponses.data, tempCategory, 1);

            lineColor = setMapLineColor(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );

            lineWeight = setMapLineWeight(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );
          }
        } else {
          // var clone = infoDirectional
          //   .slice(0)
          //   .sort((a, b) => a["dlyrank"] - b["dlyrank"]);
          if (query.vehicleType === "truck") {
            let tempCategory = mapLineCategoriesTop100.filter(
              (itm) => itm.all === selectedMapLineCategory
            )[0].truck;
            // let tempValue = top100ApiResponses.directionalData
            //   .filter((itm) => itm.mergekey === mergekey)
            //   // .slice(0)
            //   .sort((a, b) => b[tempCategory] - a[tempCategory])[0][
            //   tempCategory
            // ];
            let tempValue = 0;

            let tempArr = top100ApiResponses.directionalData.filter(
              (itm) => itm.mergekey === mergekey
            );

            if (selectedMapLineCategory === "dlyrank") {
              tempValue = getBottomJsonNNo0(tempArr, tempCategory, 1);
            } else {
              tempValue = getTopJsonN(tempArr, tempCategory, 1);
            }

            lineColor = setMapLineColor(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );

            lineWeight = setMapLineWeight(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );
          }
          if (query.vehicleType === "all") {
            let tempCategory = selectedMapLineCategory;
            // let tempValue = top100ApiResponses.directionalData
            //   .filter((itm) => itm.mergekey === mergekey)
            //   .slice(0)
            //   .sort((a, b) => b[tempCategory] - a[tempCategory])[0][
            //   tempCategory
            // ];
            let tempArr = top100ApiResponses.directionalData.filter(
              (itm) => itm.mergekey === mergekey
            );
            // let tempValue = getTopJsonN(tempArr, tempCategory, 1);
            let tempValue = 0;

            // let maxValue = getTopJsonN(top100ApiResponses.data, tempCategory, 1);

            if (selectedMapLineCategory === "dlyrank") {
              tempValue = getBottomJsonNNo0(tempArr, tempCategory, 1);
            } else {
              tempValue = getTopJsonN(tempArr, tempCategory, 1);
            }
            lineColor = setMapLineColor(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );

            lineWeight = setMapLineWeight(
              tempValue,
              minMaxValues.max,
              minMaxValues.min
            );
          }
        }
      }
      if (userSelection.current.from_route_id !== "") {
        if (route_id === userSelection.current.from_route_id) lineWeight = 5;
        if (route_id !== userSelection.current.from_route_id) lineWeight = 0.1;
      } else {
        if (query.userSelectedRoads.length > 0) {
          // console.log("query.userSelectedRoads", query.userSelectedRoads);
          if (query.userSelectedRoads.indexOf(mergekey) === -1)
            lineWeight = 0.1;
        }
      }
      return {
        // fillColor: giveColor(electoralDistrict),
        weight: lineWeight,
        // opacity: 1,
        color: lineColor,
        // dashArray: "3",
        // fillOpacity: 0.5,
      };
    }
  }

  const handleClick = (feature, layer, e) => {
    setPopupTitle(
      `Road: ${feature.properties.seg_name} (${feature.properties.seg_from} to ${feature.properties.seg_to})`
    );
  };
  /* have to pass a unique key to Map to re-render leaflet map
   * when the road data is changed. Since the road data
   * changed based on regionId and year, this pass the combination of both
   * as a key. This is a bug in react-leaflet.
   * See https://stackoverflow.com/questions/44155385/rendering-geojson-with-react-leaflet
   */
  const { BaseLayer, Overlay } = LayersControl;
  const [mapBounds, setMapBounds] = useState(props.bounds);
  const [mapZoom, setMapZoom] = useState(props.Zoom);

  function returnTop100Variable(name) {
    for (var i = SUMMARY_VARIABLES_TOP100.length - 1; i >= 0; i--) {
      if (
        name === SUMMARY_VARIABLES_TOP100[i].variable ||
        name.substring(0, name.length - 1) ===
          SUMMARY_VARIABLES_TOP100[i].variable
      ) {
        return SUMMARY_VARIABLES_TOP100[i];
      }
    }
  }

  function PopupData(props) {
    if (query.directionality === "directional" && query.vehicleType === "all") {
      // if (props.type === "top100-directional") {
      // console.log("infoDirectional-check", infoDirectional);
      var clone = infoDirectional
        .slice(0)
        .sort((a, b) => a["dlyrank"] - b["dlyrank"]);

      // let selectedRow = clone.sort((a, b) => b["dlyrank"] - a["dlyrank"]);
      return (
        <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>
          <Grid container justify="center">
            <Grid item xs={8}>
              <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>{"Metric"}</Box>

              <ul>
                {POPUP_GROUPS["top100"].map((value, index) => {
                  const field = returnTop100Variable(value);

                  return <li key={index}>{field.summaryName}:</li>;
                })}
              </ul>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>
                {clone[0]["direction"]}
              </Box>

              <ul>
                {POPUP_GROUPS["top100"].map((value, index) => {
                  const field = returnTop100Variable(value);

                  return (
                    <li
                      key={index}
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {info[value] === null
                        ? ""
                        : clone[0][value].toLocaleString(
                            "en-US",
                            field.formatOptions
                          )}
                    </li>
                  );
                })}
              </ul>
            </Grid>
            {clone.length > 1 && (
              <Grid item xs={2}>
                <Box sx={{ borderBottom: 1 }}>{clone[1]["direction"]}</Box>

                <ul>
                  {POPUP_GROUPS["top100"].map((value, index) => {
                    const field = returnTop100Variable(value);

                    return (
                      <li
                        key={index}
                        style={{ listStyleType: "none", padding: 0, margin: 0 }}
                      >
                        {info[value] === null
                          ? ""
                          : clone[1][value].toLocaleString(
                              "en-US",
                              field.formatOptions
                            )}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            )}
          </Grid>
        </Box>
      );
    }
    if (
      query.directionality === "directional" &&
      query.vehicleType === "truck"
    ) {
      // if (props.type === "top100truck-directional") {
      var clone = infoDirectional
        .slice(0)
        .sort((a, b) => a["trkdlyrank"] - b["trkdlyrank"]);

      // let selectedRow = clone.sort((a, b) => b["dlyrank"] - a["dlyrank"]);
      return (
        <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>
          <Grid container justify="center">
            <Grid item xs={8}>
              <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>{"Metric"}</Box>
              <ul>
                {POPUP_GROUPS["top100truck"].map((value, index) => {
                  const field = returnTop100Variable(value);

                  return <li key={index}>{field.summaryName}:</li>;
                })}
              </ul>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ borderBottom: 1, pr: 1, mr: 1 }}>
                {clone[0]["direction"]}
              </Box>
              <ul>
                {POPUP_GROUPS["top100truck"].map((value, index) => {
                  const field = returnTop100Variable(value);

                  return (
                    <li
                      key={index}
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      {info[value] === null
                        ? ""
                        : clone[0][value].toLocaleString(
                            "en-US",
                            field.formatOptions
                          )}
                    </li>
                  );
                })}
              </ul>
            </Grid>
            {clone.length > 1 && (
              <Grid item xs={2}>
                <Box sx={{ borderBottom: 1 }}>{clone[0]["direction"]}</Box>
                <ul>
                  {POPUP_GROUPS["top100truck"].map((value, index) => {
                    const field = returnTop100Variable(value);

                    return (
                      <li
                        key={index}
                        style={{ listStyleType: "none", padding: 0, margin: 0 }}
                      >
                        {info[value] === null
                          ? ""
                          : clone[1][value].toLocaleString(
                              "en-US",
                              field.formatOptions
                            )}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            )}
          </Grid>
        </Box>
      );
    }
    return (
      <div>
        <ul>
          {POPUP_GROUPS[props.type].map((value, index) => {
            const field = returnTop100Variable(value);

            return (
              <li key={index}>
                {field.summaryName}:
                {info[value] === null
                  ? ""
                  : info[value].toLocaleString("en-US", field.formatOptions)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  let toFrom = "";
  let routeId = "";
  if (typeof info.route_id !== "undefined") {
    toFrom = `${info.beg_point.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })} to ${info.end_point.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })}`;
    routeId = info.route_id;
  } else if (typeof info.seg_name !== "undefined") {
    toFrom = `${info.seg_from} to ${info.seg_to}`;
    routeId = info.seg_name;
  }

  const popupRef = useRef();

  window.popupRef = popupRef;

  const [popupTitle, setPopupTitle] = useState("untitled");

  const layerRef = useRef();

  // Classes used by Leaflet to position controls
  const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
  };

  function MapResetControl({ position, map }) {
    const positionClass =
      (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    const onClick = useCallback(() => {
      // map.setView(center, zoom);
      map.fitBounds(mapBounds);
    }, [map]);

    return (
      <div className={positionClass}>
        <div className="resetmapcontrol">
          <div
            className="leaflet-control leaflet-bar"
            style={{ borderStyle: "none" }}
          >
            <IconButton
              className="home-button"
              color="inherit"
              aria-label="reset map"
              title="Reset Map"
              edge="start"
              // onClick={() => setMapZoom(mapZoom - 1)}
              onClick={onClick}
            >
              <Icon>home</Icon>
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    // console.log("openDrawer-check", props.openDrawer);

    // if (map) map.fitBounds(mapBounds);
    // if (openDrawer === false) console.log("map center:", map.getCenter());
    // if (openDrawer === false) return <SetViewOnClick bounds={mapBounds} />;
    // if (openDrawer === false) map.setView(map.getCenter(), map.getZoom() - 1);

    if (map)
      setTimeout(function () {
        map.invalidateSize();
      }, 200);
  }, [openDrawer]);

  const crabLayerRef = useRef();
  const ctpLayerRef = useRef();
  const ShaProjectsLayerRef = useRef();
  const LandUse2010LayerRef = useRef();

  const layerIcons = {
    ctp: renderToStaticMarkup(
      <ConstructionIcon
        style={{
          fontSize: 15,
          color: "#0047AB",
        }}
      />
    ),
    shaProjects: renderToStaticMarkup(
      <ConstructionIcon
        style={{
          fontSize: 15,
          color: "#FFC300",
        }}
      />
    ),
    airports: renderToStaticMarkup(
      <FlightIcon
        style={{
          fontSize: 16,
          color: "#FFFFFF",
          backgroundColor: "#A9A9A9",
        }}
      />
    ),
    truckWeighInspStations: renderToStaticMarkup(
      <LocalShippingIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#CBC3E3",
        }}
      />
    ),
    distributionCenters: renderToStaticMarkup(
      <BusinessIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#90ee90",
        }}
      />
    ),
    autoTerminals: renderToStaticMarkup(
      <DirectionsCarIcon
        style={{
          fontSize: 20,
          color: "#000000",
          backgroundColor: "#add8e6",
        }}
      />
    ),
    cargoAirports: renderToStaticMarkup(
      <FlightIcon
        style={{
          fontSize: 16,
          color: "#FFFF00",
          backgroundColor: "#000000",
        }}
      />
    ),
    intermodalFacilities: renderToStaticMarkup(
      <Icon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#11b461",
        }}
      />
    ),
    otherTerminals: renderToStaticMarkup(
      <Icon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#FFFF00",
        }}
      />
    ),
    ports: renderToStaticMarkup(
      <AnchorIcon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#0000ff",
        }}
      />
    ),
    tollPlazas: renderToStaticMarkup(
      <TollIcon
        style={{
          fontSize: 16,
          color: "#ffffff",
          backgroundColor: "#ff7800",
        }}
      />
    ),
    fhwaTruckParking: renderToStaticMarkup(
      <LocalParkingIcon
        style={{
          fontSize: 16,
          color: "#000000",
          backgroundColor: "#add8e6",
        }}
      />
    ),
    crabLayer: renderToStaticMarkup(
      <Stack>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "rgb(255,255,0,150)",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          0 to 1 Foot CRAB Inundated
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#008000",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          1 to 2 Foot CRAB Inundated
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#800080",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Greater than 2 Foot CRAB Inundated
        </div>
      </Stack>
    ),
    landUse2010: renderToStaticMarkup(
      <Stack>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFEBAF",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Very Low Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFFF00",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Low Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FFAA00",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Medium Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#732600",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          High Density Residential
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#FF0000",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Commercial
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#A900E6",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Industrial
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#005CE6",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Institutional
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#9C9C9C",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Other Developed Lands
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#D3FFBE",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Agriculture
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#38A800",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Forest
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#9EBBD7",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Water
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#97DBF2",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Wetlands
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#D7C29E",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Barren Land
        </div>
        <div>
          <Icon
            style={{
              fontSize: 16,
              color: "#000000",
              backgroundColor: "#4E4E4E",
              borderRadius: 50,
              transform: [{ scaleX: 2 }],
              marginLeft: 25,
              marginRight: 10,
              opacity: 0.5,
              border: "1px solid",
              borderColor: "primary.main",
            }}
          />
          Transportation
        </div>
      </Stack>
    ),
  };

  const landUse2010Features = [
    {
      Description: "Very Low Density Residential",
      backgroundColor: "#FFEBAF",
      layerRef: useRef(),
    },
    {
      Description: "Low Density Residential",
      backgroundColor: "#FFFF00",
      layerRef: useRef(),
    },
    {
      Description: "Medium Density Residential",
      backgroundColor: "#FFAA00",
      layerRef: useRef(),
    },
    {
      Description: "High Density Residential",
      backgroundColor: "#732600",
      layerRef: useRef(),
    },
    {
      Description: "Commercial",
      backgroundColor: "#FF0000",
      layerRef: useRef(),
    },
    {
      Description: "Industrial",
      backgroundColor: "#A900E6",
      layerRef: useRef(),
    },
    {
      Description: "Institutional",
      backgroundColor: "#005CE6",
      layerRef: useRef(),
    },
    {
      Description: "Other Developed Lands",
      backgroundColor: "#9C9C9C",
      layerRef: useRef(),
    },
    {
      Description: "Agriculture",
      backgroundColor: "#D3FFBE",
      layerRef: useRef(),
    },
    { Description: "Forest", backgroundColor: "#38A800", layerRef: useRef() },
    { Description: "Water", backgroundColor: "#9EBBD7", layerRef: useRef() },
    { Description: "Wetlands", backgroundColor: "#9EBBD7", layerRef: useRef() },
    {
      Description: "Barren Land",
      backgroundColor: "#D7C29E",
      layerRef: useRef(),
    },
    {
      Description: "Transportation",
      backgroundColor: "#4E4E4E",
      layerRef: useRef(),
    },
  ];

  return (
    <MapContainer
      bounds={mapBounds}
      zoom={mapZoom}
      minZoom={8}
      attributionContol={true}
      scrollWheelZoom={true}
      zoomControl={false}
      ref={setMap}
    >
      <LoadingOverlay
        // active={isLoading}
        active={top100ApiResponses.geometry.features.length > 0 ? false : true}
        spinner={
          <div>
            <BounceLoader />
            <LinearProgress
            // variant="determinate"
            // value={loadingProgress}
            // className={classes.linearProgress}
            />
          </div>
        }
        text={
          query.statsLayerDesc
            ? "Loading stats layer..."
            : "Please select a layer from the menu on the left..."
        }
        // styles={{
        //   overlay: (base) => ({
        //     ...base,
        //     height: "150px",
        //   }),
        // }}
      >
        <div>
          <ZoomControl position="topright" />
          <MapResetControl position="topright" map={map} />
        </div>
        <div className="zoommapcontrol">
          <LayersControl
            position="topright"
            className={classes.layermapcontrol}
          >
            <BaseLayer checked name="Streets" key={"mapbox.streets"}>
              <TileLayer
                url={process.env.REACT_APP_MAPBOX_STREETS_V10_URL}
                attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
                id="mapbox.streets"
                access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
              />
            </BaseLayer>
            <BaseLayer name="Satellite">
              <TileLayer
                url={process.env.REACT_APP_MAPBOX_URL}
                attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
                id="mapbox/satellite-v9"
                access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
              />
            </BaseLayer>
            <BaseLayer name="Satellite-Streets">
              <TileLayer
                url={process.env.REACT_APP_MAPBOX_URL}
                attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
                id="mapbox/satellite-streets-v11"
                access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
              />
            </BaseLayer>
            <BaseLayer name="Light">
              <TileLayer
                url={process.env.REACT_APP_MAPBOX_URL}
                attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
                id="mapbox/light-v10"
                access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
              />
            </BaseLayer>
            <BaseLayer name="Dark">
              <TileLayer
                url={process.env.REACT_APP_MAPBOX_URL}
                attribution={process.env.REACT_APP_MAPBOX_ATTRIBUTION}
                id="mapbox/dark-v10"
                access_token={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
              />
            </BaseLayer>
            {top100ApiResponses.geometry.features.length > 0 && (
              <Overlay checked name="Roads" style={{ zIndex: 500 }}>
                <GeoJSON
                  data={top100ApiResponses.geometry}
                  // style={getHPMSStyle}
                  style={setMapLineStyle}
                  onEachFeature={onEachFeature}
                  ref={geoJsonRef}
                  // whenCreated={setGeoJsonLayerRef}
                  className={classes.lineStyle}
                >
                  <Popup ref={popupRef}>
                    <div className="custom-block">
                      <p className="custom-block-title">
                        <strong>Layer ID:</strong> {query.statsLayerDesc}
                      </p>

                      <p className="custom-block-title">{popupTitle}</p>
                      {/* <p className="custom-block-title">
                      {feature.properties.seg_name}
                    </p> */}
                      <div>
                        <p>
                          To add road segment to the data table, click{" "}
                          <strong>Add segment</strong> .
                        </p>
                        <p className="from-to-links">
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={handleAddSegment}
                          >
                            Add segment
                          </button>
                        </p>
                      </div>
                    </div>
                  </Popup>
                </GeoJSON>
              </Overlay>
            )}

            <Overlay
              name="Freight Crashes 2018 - Tiled (ArcGIS Layer)"
              style={{ zIndex: 500 }}
            >
              <TiledMapLayer
                ref={layerRef}
                url="https://tiles.arcgis.com/tiles/njFNhDsUCentVYJW/arcgis/rest/services/Freight_Crashes_2018_Tiled/MapServer"
              />
            </Overlay>

            <Overlay
              name={"FHWA Truck Parking 2019 " + layerIcons.fhwaTruckParking}
              style={{ zIndex: 500 }}
            >
              <TruckParkingLayer layerIcon={layerIcons.fhwaTruckParking} />
            </Overlay>
            <Overlay
              name={"Toll Plazas " + layerIcons.tollPlazas}
              style={{ zIndex: 499 }}
            >
              <TollPlazasLayer layerIcon={layerIcons.tollPlazas} />
            </Overlay>

            <Overlay name={"Ports " + layerIcons.ports} style={{ zIndex: 498 }}>
              <PortsLayer layerIcon={layerIcons.ports} />
            </Overlay>
            <Overlay
              name={"Other Terminals " + layerIcons.otherTerminals}
              style={{ zIndex: 497 }}
            >
              <OtherTerminalsLayer layerIcon={layerIcons.otherTerminals} />
            </Overlay>
            <Overlay
              name={"Intermodal Facilities " + layerIcons.intermodalFacilities}
              style={{ zIndex: 496 }}
            >
              <IntermodalFacilityLayer
                layerIcon={layerIcons.intermodalFacilities}
              />
            </Overlay>
            <Overlay
              name={"Cargo Airports " + layerIcons.cargoAirports}
              style={{ zIndex: 495 }}
            >
              <CargoAirportsLayer layerIcon={layerIcons.cargoAirports} />
            </Overlay>
            <Overlay
              name={"Auto Terminals " + layerIcons.autoTerminals}
              style={{ zIndex: 494 }}
            >
              <AutoTerminalsLayer layerIcon={layerIcons.autoTerminals} />
            </Overlay>
            <Overlay
              // name="Distribution Centers"
              // name={layerIcons.distributionCenters + " Distribution Centers"}
              name={"Distribution Centers " + layerIcons.distributionCenters}
              style={{ zIndex: 493 }}
            >
              <DistributionCentersLayer
                layerIcon={layerIcons.distributionCenters}
              />
            </Overlay>
            <Overlay
              name={
                "Truck Weigh/Inspection Stations " +
                layerIcons.truckWeighInspStations
              }
              style={{ zIndex: 492 }}
            >
              <TruckWeighInspStationsLayer
                layerIcon={layerIcons.truckWeighInspStations}
              />
            </Overlay>
            <Overlay
              // name="Airports"
              name={"Airports " + layerIcons.airports}
              style={{ zIndex: 491 }}
            >
              <AirportsLayer layerIcon={layerIcons.airports} />
            </Overlay>
            <Overlay name="MDOT SHA County Boundaries" style={{ zIndex: 590 }}>
              <CountyBoundaryLayer />
            </Overlay>
            <Overlay
              name="MDOT SHA District Boundaries"
              style={{ zIndex: 590 }}
            >
              <DistrictBoundaryLayer />
            </Overlay>
            <Overlay
              name={
                "Climate Ready Action Boundary (CRAB) Inundated Zones" +
                layerIcons.crabLayer
              }
              style={{ zIndex: 590 }}
            >
              <CrabLayer layerRef={crabLayerRef} mapRef={setMap} />
            </Overlay>
            <Overlay
              // name="Consolidated Transportation Program (CTP) FY2022 - FY2027"
              name={
                "Consolidated Transportation Program (CTP) FY2022 - FY2027 " +
                layerIcons.ctp
              }
              style={{ zIndex: 590 }}
            >
              <CtpLayer layerRef={ctpLayerRef} iconMarkup={layerIcons.ctp} />
            </Overlay>
            <Overlay
              // name="Consolidated Transportation Program (CTP) FY2022 - FY2027"
              name={"MDOT SHA Projects " + layerIcons.shaProjects}
              style={{ zIndex: 590 }}
            >
              <ShaProjectsLayer
                layerRef={ShaProjectsLayerRef}
                iconMarkup={layerIcons.shaProjects}
              />
            </Overlay>
            <Overlay
              // name={"Land Use 2010" + layerIcons.landUse2010}
              name={"Land Use 2010"}
              style={{ zIndex: 590 }}
            >
              <LandUse2010 layerRef={LandUse2010LayerRef} />
            </Overlay>
            {landUse2010Features.map((item, index) => (
              <Overlay
                name={
                  renderToStaticMarkup(
                    <Icon
                      style={{
                        fontSize: 16,
                        color: "#000000",
                        backgroundColor: item.backgroundColor,
                        borderRadius: 50,
                        transform: [{ scaleX: 2 }],
                        // marginLeft: 25,
                        marginRight: 10,
                        opacity: 0.5,
                        border: "1px solid",
                        borderColor: "primary.main",
                      }}
                    />
                  ) +
                  "Land Use 2010 - " +
                  item.Description
                }
                style={{ zIndex: 590 }}
                key={"lu2010_" + index}
              >
                <LandUse2010
                  layerRef={item.layerRef}
                  luDescription={item.Description}
                />
              </Overlay>
            ))}
          </LayersControl>
        </div>
      </LoadingOverlay>
      <QuickInfoControl
        position="bottomleft"
        // geoRoute={query.geoRoute}
        // mapLineCategoriesTop100={mapLineCategoriesTop100}
        // mapLineCategories={mapLineCategories}
        selectedMapLineCategory={selectedMapLineCategory}
        setSelectedMapLineCategory={setSelectedMapLineCategory}
        checked={checked}
        setChecked={setChecked}
        minMaxValues={minMaxValues}
        info={info}
        routeId={routeId}
        toFrom={toFrom}
        PopupData={PopupData}
      />
    </MapContainer>
  );
}

export default LeafletMap;
